import { Button, Modal, Spinner } from "react-bootstrap";
import { WrapperModalV2, WrapperModalV2Content } from "./style";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

export default function ModalV2({ hiddenButton, newShow, setNewShow, ButtonModal, ContentModal, FooterModal, config, newZIndex, callOpen, callClose }){

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [zIndex, setZIndex] = useState(newZIndex || "1055");
    const [isLoading, setIsLoading] = useState(false);
    const { modalOpen } = useSelector(state=>state.notificationState);

    useEffect(()=>{

        if(show){
            if(callOpen) callOpen();
        } else {
            if(callClose){
                callClose();
            }
        }

        if(setNewShow) setNewShow(show);
    }, [show]);

    useEffect(()=>{
        if(typeof newShow === "boolean") setShow(newShow);
    }, [newShow]);

    useEffect(()=>{
        if(newZIndex) setZIndex(newZIndex);
    }, [newZIndex]);

    return (
        <WrapperModalV2>
            {
                ButtonModal ? (
                    <div onClick={handleShow}>{ ButtonModal }</div>
                ) : (
                    hiddenButton ? null : <Button className={config.classCta} onClick={handleShow}>{ config.cta }</Button>
                )
            }
            <WrapperModalV2Content style={{zIndex: zIndex}} size={config.size || "md"} show={!modalOpen && show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>
                        <span>{ config.title }</span>
                        <Button onClick={handleClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { ContentModal }
                </Modal.Body>
                {
                    FooterModal ? (
                        FooterModal
                    ) : (
                        <Modal.Footer>
                            {
                                isLoading ? <Spinner size="sm" animation="border" /> : (
                                    <>
                                        <Button onClick={handleClose} className="close">{ config.textCancel || "Não" }</Button>
                                        <Button onClick={()=>{
                                            setIsLoading(true);
                                            if(config.fnConfirm) config.fnConfirm({
                                                handleClose,
                                                handleShow
                                            })
                                                .then(result=>{
                                                    if(callClose) callClose();
                                                    handleClose();
                                                    return result;
                                                })
                                                .finally(()=>setIsLoading(false));
                                        }} className="confirm">{ config.textConfirm || "Sim" }</Button>
                                    </>
                                )
                            }
                        </Modal.Footer>
                    )
                }
            </WrapperModalV2Content>
        </WrapperModalV2>
    );
}