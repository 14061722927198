import React from "react";
import WrapperPayment from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faDollar } from "@fortawesome/free-solid-svg-icons";
import { faPix } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";
import utils from "../../../../utils";

export default function Payment(){

    const { accountClient } = useSelector(state => state.accountState);

    return (
        <WrapperPayment>
            {
                accountClient.payments.map(payment=>{
                    return ({
                        description: payment.name,
                        icon: utils.getIcon(payment.icon)
                    });
                }).map(f => (
                    <div className="form-payment">
                        {
                            f.icon
                        }
                        <span>{ f.description }</span>
                    </div>
                ))
            }
        </WrapperPayment>
    );
}