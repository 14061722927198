import React, { useEffect, useState } from "react";
import WrapperScheduling, { WrapperModalSchedulingContent, WrapperModalSchedulingFooter, WrapperModalSchedulingCancelContent, WrapperModalSchedulingConfirmContent, WrapperModalSchedulingRescheduleContent } from "./style";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Header from "../../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faCalendar, faClock, faMagnifyingGlass, faPenRuler, faUserTie } from "@fortawesome/free-solid-svg-icons";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Login from "../../Components/Login";
import moment from "moment";
import utils from "../../utils";
import { http } from "../../services";
import ModalV2 from "../../Components/ModalV2";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../Components/Pagination";
import _ from "lodash";

function SchedulingModal({newZIndex, setNewZIndex, scheduling, confirm, cancel, username}){

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { users: professionals } = useSelector(state => state.userState);
    const { user } = useSelector(state => state.accountState);
    const [modalCancelShow, setModalCancelShow] = useState(false);
    const [modalRescheduleShow, setModalRescheduleShow] = useState(false);
    const [isLoadingReschedule, setIsLoadingReschedule] = useState(false);
    
    return (
        <>
            <ModalV2
                newShow={modalRescheduleShow}
                setNewShow={setModalRescheduleShow}
                hiddenButton={true}
                callOpen={()=>setNewZIndex("1")}
                callClose={()=>setNewZIndex("1055")}
                ContentModal={<WrapperModalSchedulingRescheduleContent>
                    <h6>Deseja agendar para outro horário?</h6>
                    <div className="description">
                        <p>Agendamento:</p>
                        <div className="service">
                            <FontAwesomeIcon title="Serviço" icon={faPenRuler} />
                            <span>{scheduling.services[0].name}</span>
                        </div>
                        <div className="professional">
                            <FontAwesomeIcon title="Profissional" icon={faUserTie} />
                            <span>{professionals.find(p => p.id === scheduling.professionalId)?.displayNameProfessional}</span>
                        </div>
                    </div>
                </WrapperModalSchedulingRescheduleContent>}
                config={{
                    fnConfirm: ()=>{
                        setIsLoadingReschedule(true);
                        const promiseServices = http.service.findPublic()
                            .then(result=>{
                                dispatch({
                                    type: "LOAD_SERVICE",
                                    payload: result.data
                                });
                            });
                        const promiseProfessionals = http.user.findProfessionalsPublic()
                            .then(result => dispatch({
                                type: "LOAD_USER",
                                payload: result.data
                            }));
                        return Promise.all([promiseProfessionals, promiseServices])
                            .then(result=>{
                                setModalRescheduleShow(false);
                                dispatch({
                                    type: "INCLUDE_SERVICE_CART",
                                    payload: scheduling.services[0].id
                                });
                                dispatch({
                                    type: "SET_PROFESSIONAL_CART",
                                    payload: scheduling.professionalId
                                });
                                navigate(`/${username}`);
                                return result;
                            })
                            .finally(()=>setIsLoadingReschedule(false));
                    },
                    textConfirm: "Agendar",
                    textCancel: "Não quero",
                    cta: "Cancelar",
                    classCta: "close",
                    title: "Reagendar"
                }}
            />
            <ModalV2
                newShow={modalCancelShow}
                setNewShow={setModalCancelShow}
                newZIndex={newZIndex}
                setNewZIndex={setNewZIndex}
                config={{
                    title: "Seu agendamento"
                }}
                FooterModal={
                    <WrapperModalSchedulingFooter>
                        {
                            !["done", "canceled"].includes(scheduling.status) && user.Account.allowCancelScheduling && moment() <= moment(scheduling.date).subtract(user.Account.advanceForCancelScheduling, "minutes") ? (
                                <ModalV2
                                    callOpen={()=>setNewZIndex("1")}
                                    callClose={()=>setNewZIndex("1055")}
                                    ContentModal={<WrapperModalSchedulingCancelContent>
                                        <h6>Tem certeza?</h6>
                                        <p>Deseja <b>cancelar</b> {scheduling.services[0].name} às { moment(scheduling.date, "YYYY-MM-DD HH:mm:ss").format("HH:mm [de] dddd, DD [de] MMMM?") }</p>
                                    </WrapperModalSchedulingCancelContent>}
                                    config={{
                                        fnConfirm: ()=>{
                                            return cancel(scheduling.id)
                                                .then(result=>{
                                                    if(result.code !== "ERR_BAD_REQUEST"){
                                                        setModalCancelShow(false);
                                                        setModalRescheduleShow(true);
                                                    }
                                                    return result;
                                                });
                                        },
                                        size: "lg",
                                        cta: "Cancelar",
                                        classCta: "close",
                                        title: "Cancelar agendamento"
                                    }}
                                />
                            ) : null
                        }
                        {
                            scheduling.status === "scheduled" ? (
                                <ModalV2
                                    callOpen={()=>setNewZIndex("1")}
                                    callClose={()=>setNewZIndex("1055")}
                                    ContentModal={<WrapperModalSchedulingConfirmContent>
                                        <h6>Tem certeza?</h6>
                                        <p>Deseja <b>confirmar</b> {scheduling.services[0].name} às { moment(scheduling.date, "YYYY-MM-DD HH:mm:ss").format("HH:mm [de] dddd, DD [de] MMMM?") }</p>
                                    </WrapperModalSchedulingConfirmContent>}
                                    config={{
                                        fnConfirm: ()=>confirm(scheduling.id),
                                        size: "lg",
                                        cta: "Confirmar",
                                        classCta: "confirm",
                                        title: "Confirmar agendamento"
                                    }}
                                />
                            ) : null
                        }
                        {
                            scheduling.status === "canceled" && (
                                isLoadingReschedule ? (
                                    <Spinner size="sm" animation="border" />
                                ) : (
                                    <Button className="confirm" onClick={()=>{
                                        setIsLoadingReschedule(true);
                                        const promiseServices = http.service.findPublic()
                                            .then(result=>{
                                                dispatch({
                                                    type: "LOAD_SERVICE",
                                                    payload: result.data
                                                });
                                            });
                                        const promiseProfessionals = http.user.findProfessionalsPublic()
                                            .then(result => dispatch({
                                                type: "LOAD_USER",
                                                payload: result.data
                                            }));
                                        return Promise.all([promiseProfessionals, promiseServices])
                                            .then(result=>{
                                                dispatch({
                                                    type: "INCLUDE_SERVICE_CART",
                                                    payload: scheduling.services[0].id
                                                });
                                                dispatch({
                                                    type: "SET_PROFESSIONAL_CART",
                                                    payload: scheduling.professionalId
                                                });
                                                navigate(`/${username}`);
                                                return result;
                                            })
                                            .finally(()=>setIsLoadingReschedule(false));
                                    }}>Reagendar</Button>
                                )
                            )
                        }
                    </WrapperModalSchedulingFooter>
                }
                ContentModal={
                    <WrapperModalSchedulingContent>
                        <div className="date mb-3">
                            <FontAwesomeIcon icon={faCalendar} />
                            <span>{moment(scheduling.date).format("DD/MM/YYYY[,] dddd")}</span>
                        </div>
                        <div className="time mb-3">
                            <FontAwesomeIcon icon={faClock} />
                            <span>{moment(scheduling.date).format(`HH:mm [às] ${moment(scheduling.date).add(scheduling.services[0].duration, "minutes").format("HH:mm")}`)}</span>
                        </div>
                        <div className="service mb-3">
                            <FontAwesomeIcon icon={faPenRuler} />
                            <span>{scheduling.services[0].name}</span>
                        </div>
                        <div className="status">
                            <FontAwesomeIcon icon={faBookmark} />
                            <span>{({
                                canceled: "Cancelado",
                                scheduled: "Agendado",
                                confirmed: "Confirmado",
                                done: "Realizado"
                            })[scheduling.status]}</span>
                        </div>
                    </WrapperModalSchedulingContent>
                }
                ButtonModal={
                    <div className="scheduling">
                        <div className="column date">
                            <span className="day-of-week"><b>{moment(scheduling.date).format("ddd[.]")}</b></span>
                            <span className="day"><b>{moment(scheduling.date).format("DD")}</b></span>
                            <span className="time">{moment(scheduling.date).format("HH:mm")}</span>
                        </div>
                        <span className="separator"></span>
                        <div className="column description">
                            <span className="name">{scheduling.services[0].name}</span>
                            <span className="status">{
                                ({
                                    scheduled: "Agendado",
                                    confirmed: "Confirmado",
                                    canceled: "Cancelado",
                                    done: "Realizado"
                                })[scheduling.status]
                            }</span>
                        </div>
                        {/* <ModalRemoveService serviceSelected={serviceSelected} /> */}
                    </div>
                }
            />


        </>
    );
}

export default function Scheduling(){
    
    const dispatch = useDispatch();
    const { isLogged, type, username } = useSelector(state => state.accountState);
    const { schedulings, isLoading, filters } = useSelector(state => state.schedulingState);
    const [newZIndex, setNewZIndex] = useState("1055");

    function toFilter(obj){
        let newObj = Object.assign({
            orderByField: filters.orderByField,
            orderByDirection: filters.orderByDirection
        }, obj);

        dispatch({
            type: 'IS_LOADING_SCHEDULING',
            payload: true
        });

        http.scheduling.findAllClient(newObj)
            .then(result=>{
                dispatch({
                    type: "LOAD_SCHEDULING",
                    payload: result.data
                });
            })
            .finally(()=>dispatch({
                type: "IS_LOADING_SCHEDULING",
                payload: false
            }));
    }

    const cancel = id=>{
        return http.scheduling.cancel(id)
            .then(result=>{
                dispatch({
                    type: "UPDATE_SCHEDULING",
                    payload: result.data
                });
                utils.createNotification({
                    type: 'success',
                    title: 'Agendamento cancelado'
                });
                return result;
            })
            .catch(error=>{
                utils.createNotification({
                    type: 'error',
                    title: 'Falha ao cancelar agendamento',
                    message: error.response?.data || error.message,
                    timing: 15000
                });
                return error;
            });
    }

    const confirm = id=>{
        return http.scheduling.confirm(id)
            .then(result=>{
                dispatch({
                    type: "UPDATE_SCHEDULING",
                    payload: result.data
                });
                utils.createNotification({
                    type: 'success',
                    title: 'Agendamento confirmado'
                });
                return result;
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao confirmar agendamento',
                message: error.response?.data || error.message
            }));
    }

    useEffect(()=>{
        if(isLogged){
            dispatch({
                type: "IS_LOADING_SCHEDULING",
                payload: true
            });
            toFilter();
        }
    }, [isLogged]);

    useEffect(()=>{
        http.user.findProfessionalsPublic()
            .then(result=>dispatch({
                type: "LOAD_USER",
                payload: result.data
            }));
    }, []);

    return (
        <WrapperScheduling>
            <Header />
            <Container>
                <Row>
                    <Col>
                        <h1 className="title">Seus agendamentos</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            isLoading ? (
                                <Spinner animation="border" />
                            ) : (
                                schedulings.length ? (
                                    <div className="list-schedulings">
                                        {
                                            Object.entries(schedulings.reduce((acc, s)=>{
                                                let schedulingsPerMonth = Object.assign({}, acc);
                                                let month = moment(s.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM");
                                                if(!schedulingsPerMonth[month]) schedulingsPerMonth[month] = [];
                                                let newService = _.clone(s);
                                                newService.services = newService.ServicesPerSchedulings.map(s => s.Service);
                                                delete newService.ServicesPerSchedulings;
                                                schedulingsPerMonth[month].push(newService);

                                                return schedulingsPerMonth;
                                            }, {})).map(([month, schedulings])=>({
                                                month,
                                                schedulings
                                            })).map(({month, schedulings: s})=>(
                                                <div className="group-scheduling">
                                                    <span className="month">{ moment(month, "YYYY-MM").format("MMMM/YYYY") }</span>
                                                    <div className="schedulings">
                                                        <Row>
                                                            {
                                                                s.map(scheduling=>(
                                                                    <Col lg={6}>
                                                                        <SchedulingModal
                                                                            username={username}
                                                                            confirm={confirm}
                                                                            cancel={cancel}
                                                                            newZIndex={newZIndex}
                                                                            setNewZIndex={setNewZIndex}
                                                                            scheduling={scheduling}
                                                                        />
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                    </div>
                                                    
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="center">
                                        {
                                            isLogged && type === "client" ? (
                                                <div className="list-schedulings">
                                                    <div className="no-data">
                                                        <SearchOffIcon />
                                                        <p>Nenhum agendamento encontrado</p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Login description={"Entre na sua conta para ter acesso aos seus agendamentos"} />
                                            )
                                        }
                                    </div>
                                )
                            )
                        }
                    </Col>
                </Row>
                {
                    filters.totalPages > 1 ? (
                        <Row>
                            <Col>
                                <PaginationComponent
                                    filters={ Object.assign({}, filters, {
                                        activePage: filters.activePage
                                    }) }
                                    toFilter={ toFilter }
                                />
                            </Col>
                        </Row>
                    ) : null
                }
            </Container>
        </WrapperScheduling>
    );
}