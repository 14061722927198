import styled from 'styled-components';

export const WrapperService = styled.div`

    .header {
        margin-bottom: 2rem;
    }

    .title {
        font-size: 1.875rem;
        font-weight: 700;
    }

    .center {
        // display: flex;
        // justify-content: center;
        // align-items: center;

        .list-services {
            // max-width: 40%;
            // width: 100%;

            .card {
                height: 220px !important;
                margin-bottom: 1.5rem;

                .card-header {
                    background-color: #e9c46a;
                    color: white;
                }
            }

            p {
                margin-bottom: 0;
            }

        }
    }

`;

export default WrapperService;