import styled from "styled-components";

export const WrapperPagination = styled.div`

    margin-bottom: 3rem;

    ul.MuiPagination-ul button,
    .MuiPagination-ul .MuiPaginationItem-root {
        color: var(--color-font);
    }

    button:hover {
        cursor: pointer;
    }

    ul.MuiPagination-ul button.Mui-selected,
    ul.MuiPagination-ul button.Mui-selected:active {
        background-color: var(--color-active) !important;
        color: white;
    }

    ul.MuiPagination-ul li:hover {
        background-color: unset;
    }

    ul.MuiPagination-ul li button svg {
        color: var(--color-font);
        margin-bottom: 0px;
    }

    .pagination.navigation {
        display: flex;
        justify-content: center;
    }
`;

export default WrapperPagination;