import styled from "styled-components";
import Select from 'react-select';

export const WrapperSelectWithIcons = styled(Select)`
    .label {
        position: absolute;
        left: 3rem;
    }
`;

export default WrapperSelectWithIcons;