import WrapperAdminUser from "./style";
import Table from "../../../Components/Table";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { http } from "../../../services";
import store from "../../../store";
import utils from "../../../utils";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import Modal from '../../../Components/Modal';

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'email', translate: 'E-mail' },
        { name: 'name', translate: 'Nome' },
        { name: 'active', translate: 'Ativo', type: 'boolean' },
        { name: 'isProfessional', translate: 'Profissional', type: 'boolean' },
        { name: 'displayNameProfessional', translate: 'Nome profissional' }
    ],
    update: {
        cta: "Editar",
        title: 'Editar usuário',
        icon: faPen,
        ctaFn: ({id})=>{
            http.user.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_USER',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do usuário ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Falha ao editar usuário ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    }
}

function UpdateContent(props){

    const dispatch = useDispatch();

    const [name, setName] = useState(props.name);
    const [displayNameProfessional, setDisplayNameProfessional] = useState(props.displayNameProfessional);
    const [isProfessional, setIsProfessional] = useState(props.isProfessional);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, displayNameProfessional, isProfessional
            }
        });
    }, [name, displayNameProfessional, isProfessional]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ name }
                                type="text"
                                placeholder="Nome"
                                onChange={e=>setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDisplayNameProfessional">
                            <Form.Label>Nome profissional</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ displayNameProfessional }
                                type="text"
                                placeholder="Descrição"
                                onChange={e=>setDisplayNameProfessional(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formIsProfessional">
                            <Form.Check // prettier-ignore
                                checked={isProfessional}
                                type={"checkbox"}
                                id={`formIsProfessional`}
                                label={`É profissional?`}
                                onChange={e=>setIsProfessional(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function AdminUser(){

    const dispatch = useDispatch();
    const { users, isLoading } = useSelector(state=>state.userState);
    const { isLogged, type } = useSelector(state=>state.accountState);

    useEffect(()=>{
        if(!(!isLogged || type !== "user")){
            dispatch({
                type: 'IS_LOADING_USER',
                payload: true
            });

            http.user.findAll()
                .then(result=>{
                    dispatch({
                        type: 'LOAD_USER',
                        payload: result.data
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Falha ao carregar usuários',
                    message: error.response.data
                }))
                .finally(()=>dispatch({
                    type: 'IS_LOADING_USER',
                    payload: false
                }));
        }
    }, []);

    return (
        <WrapperAdminUser>
            <Card>
                <Card.Body>
                    {
                        isLoading ? <div><Spinner animation="border" /></div> :
                        <Table
                            data={ users }
                            config={ config }
                            Actions={ TableActions }
                        />
                    }
                </Card.Body>
            </Card>
        </WrapperAdminUser>
    );
}