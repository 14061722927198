import { Tab } from "react-bootstrap";
import styled from "styled-components";

export const WrapperAdminSetting = styled.div`

    .form-check label {
        font-weight: 700;
    }

    .card-footer {

        background-color: unset;

        .btn {
            margin-right: 0.5rem;

            &.cancel {
                background-color: unset;
                color: var(--color-font);
            }

            &:hover:not(.cancel) {
                background-color: var(--color-primary);
            }

            &:hover {
                opacity: 0.85;
            }
        }
    }
`;

export const WrapperAdminSettingCompany = styled(Tab)``;

export const WrapperAdminSettingContact = styled(Tab)``;

export const WrapperAdminSettingScheduling = styled(Tab)`
    background-color: red;
    // .reminder {
    //     .advance {
    //         input.form-control {
    //             width: min-content;
    //             display: none;
    //         }

    //         input, label {
    //             display: inline-block;
    //         }
    //     }
    // }
`;

export const WrapperAdminSettingOfficeHour = styled.div`
    .office-hour {
        label {
            margin: 0;
        }
    }

    .card {
        .card-header {
            background-color: var(--color-main);
            color: white;
            font-weight: 700;
        }

        &:not(:last-child){
            margin-bottom: 1.5rem;
        }
    }
`;

export const WrapperAdminSettingPayment = styled.div``;

export default WrapperAdminSetting;