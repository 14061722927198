import React from "react";
import WrapperLoading from "./style";
import loading from "../../images/loading.gif";

export default function Loading(){
    return (
        <WrapperLoading>
            <img src={ loading } />
        </WrapperLoading>
    );
}