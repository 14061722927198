import styled from 'styled-components';

export const WrapperPayment = styled.div`
    .form-payment {

        & {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #F3F4F6;
            width: min-content;
            padding: 0.5rem 0.85rem;
            border-radius: 5px;
            margin-bottom: 0.75rem;
        }

        svg {
            margin-right: 0.85rem;
        }

        span {
            white-space: nowrap;
        }
    }
`;

export default WrapperPayment;