import styled from 'styled-components';

export const WrapperHour = styled.div`
    .grid-of-hours {

        & {
            max-width: 450px;
        }

        .row-hour {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0.9rem;
            border-radius: 5px;

            &.day-current {
                background-color: #F3F4F6;
            }
        }
    }
`;

export default WrapperHour;