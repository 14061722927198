import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import socketReducer from './socketReducer';
import pageReducer from './pageReducer';
import serviceReducer from './serviceReducer';
import cartReducer from './cartReducer';
import userReducer from './userReducer';
import formReducer from './formReducer';
import contactReducer from './contactReducer';
import intervalReducer from './intervalReducer';
import configReducer from './configReducer';
import paymentReducer from './paymentReducer';
import schedulingReducer from './schedulingReducer';
import clientReducer from './clientReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
    accountState: accountReducer,
    notificationState: notificationReducer,
    pageState: pageReducer,
    socketState: socketReducer,
    serviceState: serviceReducer,
    cartState: cartReducer,
    userState: userReducer,
    formState: formReducer,
    contactState: contactReducer,
    intervalState: intervalReducer,
    configState: configReducer,
    paymentState: paymentReducer,
    schedulingState: schedulingReducer,
    clientState: clientReducer
});