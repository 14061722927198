import React, { useEffect, useState } from "react";
import WrapperCaptureContact from "./style";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

export default function CaptureContact(){

    const dispatch = useDispatch();
    const user = useSelector(state => state.accountState.user);
    const [contact, setContact] = useState(user.contact);

    useEffect(()=>{
        dispatch({
            type: 'SET_USER',
            payload: Object.assign({}, user, {
                contact
            })
        });
    }, [contact]);

    return (
        <WrapperCaptureContact>
            <p className="description"><b>Informe seu número de WhatsApp</b></p>
            <p className="reason">Ele é importante para falarmos com você caso necessário</p>
            <TextField
                value={contact}
                onChange={e => {
                    let r = e.target.value.replace(/\D/g, "");
                    r = r.replace(/^0/, "");
                    if(r.length === 0) {
                        r = r;
                    } else if (r.length > 10) {
                        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
                    } else if (r.length > 6) {
                        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
                    } else if (r.length > 2) {
                        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
                    } else {
                        r = r.replace(/^(\d*)/, "($1");
                    }
                    setContact(r);
                }}
                label="WhatsApp"
                placeholder="(00) 90000-0000"
            />
        </WrapperCaptureContact>
    );
}