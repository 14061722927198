import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const WrapperModalSchedulingRescheduleContent = styled.div`
    h6 {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .description {
        p {
            margin-bottom: 0.5rem;
            font-weight: 600;
        }

        svg {
            color: var(--color-primary);
        }

        .service {
            svg {
                margin-right: 1rem;
            }
        }
        .professional {
            svg {
                margin-right: 1.1rem;
            }
        }
    }
`;

export const WrapperModalSchedulingCancelContent = styled.div`
    h6 {
        font-size: 1.5rem;
        text-align: center;
    }
`;

export const WrapperModalSchedulingConfirmContent = styled.div`

`;

export const WrapperModalSchedulingFooter = styled(Modal.Footer)`
`;

export const WrapperModalSchedulingContent = styled.div`
    svg {
        color: var(--color-primary);
        margin-right: 0.5rem;
    }

    .status {
        svg {
            margin: 0 0.65rem 0 0.2rem;
        }
    }
`;

export const WrapperScheduling = styled.div`
    .header {
        margin-bottom: 2rem;
    }

    .title {
        font-size: 1.875rem;
        font-weight: 700;
    }

    .list-schedulings {

        width: 100%;
        margin-bottom: 2rem;

        .group-scheduling {

            .month {
                margin-bottom: 0.5rem;
                display: block;
            }

            .schedulings {

                .row {

                    & > [class*="col"] {
                        margin-bottom: 1.5rem;

                        .scheduling {

                            &:hover {
                                cursor: pointer;
                            }

                            border-left-width: 4px;
                            border-left-color: var(--color-primary);
                            border-left-style: solid;
                            background-color: white;
                            display: flex;
                            align-items: center;
                            padding: 0.5rem 1.5rem;
                            border-radius: 5px;
                            box-shadow: 0 1px 5px rgba(0,0,0,0.2),0 2px 2px rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12);

                            .column {
                                padding: 0 24px
                            }

                            .column.date {
                                display: flex;
                                flex-direction: column;

                                .day {
                                    font-size: 1.5rem;
                                    line-height: 2.5rem;
                                }

                                .day-of-week {
                                    line-height: 1rem;
                                }

                                .time {
                                    line-height: 1rem;
                                }
                            }

                            .column.description {
                                width: 100%;

                                span {
                                    display: block;
                                }

                                span.status {
                                    font-weight: 500;
                                }
                            }

                            .separator {
                                background-color: #aaa;
                                height: 83px;
                                width: 2px;
                            }
                        }
                    }
                }
            }
        }
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 320px;

        .list-schedulings {
            .no-data {

                display: flex;
                flex-direction: column;
                align-items: center;
                color: #9CA3AF;

                svg {
                    font-size: 6rem;
                }

                p {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 1.125rem;
                }
            }
        }
    }
`;

export default WrapperScheduling;