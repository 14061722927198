const INITIAL_STATE = {
    isLoading: false,
    configs: []
}

const configReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_CONFIG':
            return {
                ...state, 
                configs: payload,
                isLoading: false
            };
        default:
            return state;
    }
}

export default configReducer;