import styled from 'styled-components';

export const WrapperModalContent = styled.div`

    padding: 0.5rem 0 !important;

    p {
        text-align: center;
    }

    .description {
        font-size: 1.125rem;
    }

    .reason {
        margin: 0 40px 16px;
    }

    .reason,
    .reason2 {
        font-size: 0.875rem;
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    label.Mui-focused {
        color: #E9C46A;
    }

    .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #E9C46A;
        }
    }
`;

export default WrapperModalContent;