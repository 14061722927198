import React, { useEffect } from "react";
import WrapperAbout from "./style";
import logo from "../../../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { http } from "../../../../services";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../../../utils";
import config from "../../../../config";

export default function About(props){

    const dispatch = useDispatch();

    const { accountClient: account } = useSelector(state => state.accountState);
    const { contacts } = useSelector(state => state.contactState);

    useEffect(()=>{
        http.contact.findPublic()
            .then(result=>dispatch({
                type: "LOAD_CONTACT",
                payload: result.data
            }));
    }, []);

    return (
        <WrapperAbout>
            <div className="logo">
                <img src={ `${config.host}/static-per-account/${account.id}/logo.png` } />
            </div>
            <p className="description">{ account.description }</p>
            <section>
                <h3 className="title">Contato</h3>
                <div className="contacts">
                    <Row>
                        {
                            contacts.map(c => (
                                <Col>
                                    {
                                        c.type === "whatsapp" ? (
                                            <a target="_blank" href={c.type === "whatsapp" ? `https://wa.me/${c.contact}` : ""}>
                                                <div className="contact">
                                                    <FontAwesomeIcon icon={ faWhatsapp } />
                                                    <span>{ utils.formatContact(c.contact.replace(/^55/, "")) }</span>
                                                </div>
                                            </a>
                                        ) : (
                                            <div className="contact">
                                                <FontAwesomeIcon icon={ 
                                                    ({
                                                        "phone": faPhone,
                                                        "email": faEnvelope
                                                    })[c.type]
                                                } />
                                                <span>{ "phone" === c.type ? utils.formatContact(c.contact.replace(/^55/, "")) : c.contact }</span>
                                            </div>
                                        )
                                    }
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </section>
            <section className="address">
                <h3 className="title">Endereço</h3>
                <p>{props.account.address}, {props.account.number}</p>
                <p>{props.account.district}, {props.account.city} - {props.account.uf}</p>
            </section>
        </WrapperAbout>
    );
}