import styled from 'styled-components';

export const WrapperAbout = styled.div`
    section {
        margin-bottom: 1rem;
    }

    section.address {
        p {
            margin: 0;
        }
    }

    .logo {

        & {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
        }

        img {
            width: 100px;
            border-radius: 5px;
        }
    }

    .title {
        font-size: 1.125rem;
    }

    .contacts {

        a {
            color: rgb(33, 37, 41);
            text-decoration: none;
        }

        & {
            display: flex;
        }

        .row {

            & {
                margin: 0;
            }

            .col {
                &:first-child {
                    padding-left: 0;
                }

                padding: 0 0.45rem 0.45rem;

                @media (max-width: 991px) {
                    & {
                        padding-left: 0;
                    }
                }
            }
        }

        .contact {
            & {
                width: min-content;
                border: 2px solid #E9C46A;
                border-radius: 5px;
                padding: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:hover {
                cursor: pointer;
                background-color: #E9C46A;
                color: white;
            }

            svg {
                margin-right: 0.75rem;
                font-size: 1.25rem;
            }
            
            span {
                white-space: nowrap;
            }
        }
    }
`;

export default WrapperAbout;