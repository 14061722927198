const INITIAL_STATE = {
    isLoading: false,
    clients: [],
    filters: {
        limit: 10,
        totalPages: 1,
        id: "",
        createdAt: "",
        active: ""
    }
}

const clientReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_CLIENT':
            return {
                ...state, 
                clients: payload.rows,
                filters: {
                    activePage: payload.params.activePage,
                    totalPages: Math.ceil(payload.count / payload.params.limit)
                },
                isLoading: false
            };
        case 'INCLUDE_CLIENT':
            return {
                ...state, 
                clients: [...state.clients, payload]
            };
        case 'IS_LOADING_CLIENT':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_CLIENT':
            return {
                ...state,
                clients: state.clients.map(client=>(client.id === payload.id) ? payload : client)
            };
        case 'DELETE_CLIENT':
            return {
                ...state, 
                clients: state.clients.filter(client=>client.id != payload.id)
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default clientReducer;