import styled from "styled-components";

export const WrapperIntervalTime = styled.div`
    display: flex;

    .time {
        &:first-child {
            margin-right: 1rem;
        }
    }
`;

export default WrapperIntervalTime;