import styled from "styled-components";
import { Modal } from 'react-bootstrap';

export const WrapperModalSelectDaySpecificContent = styled(Modal)`
    .modal-dialog {

        display: flex;
        justify-content: center;

        .modal-content {
            width: auto;

            .modal-body {
                padding: 0;
            }
        }
    }

    .css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
    .css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
        background-color: var(--color-primary) !important;
    }
`;

export const WrapperModalInsertObservation = styled.div`

    display: flex;
    flex-direction: column;

    a {
        color: #2196f3 !important;

        &:hover {
            cursor: pointer;
            text-decoration: underline !important;
        }
    }

    .observation {
        color: #9e9e9e;
    }
`;

export const WrapperModalInsertObservationContent = styled(Modal)`
    .modal-header {
        padding: 0.5rem 1rem;
        background-color: var(--color-primary);
        color: white;

        .modal-title {
            font-size: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            button {
                padding: 0;
                border: 0;

                &:hover,
                &:active {
                    background-color: unset !important;
                    opacity: 0.85;
                }
            }
        }
    }

    .modal-body {
        h6 {
            text-align: center;
            font-size: 1.5rem;
        }

        textarea:focus {
            border-color: var(--color-primary);
        }
    }

    .modal-footer {

        padding: 0;

        button {
            background-color: unset;
            border: none;
            text-transform: uppercase;

            &:active {
                background-color: unset;
            }

            &.close {
                color: var(--color-danger);

                &:hover {
                    background-color: #F9ECEC;
                }
            }

            &.confirm {
                color: var(--color-success);

                &:hover {
                    background-color: #EDF4ED;
                }
            }
        }
    }
`;

export const WrapperModalRemoveService = styled.div`
    button {
        padding: 0;
        background-color: unset;
        color: var(--color-primary);
        border: 0;
        font-size: 1.2rem;

        &:hover,
        &:active {
            background-color: unset !important;
            color: var(--color-primary) !important;
            opacity: 0.85;
        }
    }
`;

export const WrapperModalRemoveServiceContent = styled(Modal)`
    .modal-header {
        padding: 0.5rem 1rem;
        background-color: var(--color-primary);
        color: white;

        .modal-title {
            font-size: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            button {
                padding: 0;
                border: 0;

                &:hover,
                &:active {
                    background-color: unset !important;
                    opacity: 0.85;
                }
            }
        }
    }

    .modal-body {
        h6 {
            text-align: center;
            font-size: 1.5rem;
        }
    }

    .modal-footer {
        border-top: 0;
        padding: 0;

        button {
            background-color: unset;
            border: none;

            &:active {
                background-color: unset;
            }

            &.close {
                color: var(--color-danger);
                
                &:hover {
                    background-color: #F9ECEC;
                }
            }

            &.confirm {
                color: var(--color-success);

                &:hover {
                    background-color: #EDF4ED;
                }
            }
        }
    }
`;

export const WrapperModalSelectDaySpecific = styled.div`
    button {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        background-color: unset;
        border: 2px solid black !important;
        margin-bottom: 1.5rem;

        &:hover,
        &:active,
        &:disabled {
            background-color: unset !important;
            border-color: black !important;
        }

        .subtitle, svg {
            color: black;
        }

        .description {
            color: var(--color-primary);
        }
    }
`;

export const WrapperChangeOption = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    .service-name {
        margin-right: 1rem;
    }

    button {
        margin-bottom: 0;
        background-color: unset;
        border: unset;
        color: var(--color-primary);
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;

        span {
            margin-right: 0.5rem;
        }

        svg {
            color: var(--color-primary);
        }

        &:hover,
        &:active {
            background-color: rgb(233, 196, 106, 0.4) !important;
            color: white !important;

            svg {
                color: white;
            }
        }
    }
`;

export const WrapperSchedule = styled.div`
    .confirm-finish-order {
        max-width: 600px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .information {
            padding: 0 0.5rem;

            hr:last-child {
                width: 0;
                margin-bottom: 0;
            }

            .payments {
                .icons-and-description {

                    display: flex;

                    .icons {
                        svg {
                            margin-right: 0.25rem;
                        }
                    }
                }
            }
        }

        .total {
            display: flex;
            justify-content: space-between;
        }

        button {
            padding: 1rem;
            width: 100%;
            border: 0;
            text-transform: uppercase;

            &:hover {
                background-color: var(--color-primary);
                opacity: 0.85;
            }
        }
    }

    .message-order-empty {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            background-color: unset;
            border: 0;
            border-radius: 9px;
            text-transform: uppercase;
            padding: 0.5rem 0.7rem;

            &:hover {
                background-color: #E8E8E8;
            }
        }
    }

    .services-selected {

        width: 100%;
        margin-bottom: 2rem;

        .service {
            background-color: white;
            display: flex;
            align-items: center;
            padding: 1.5rem;
            border-radius: 5px;
            box-shadow: 0 1px 5px rgba(0,0,0,0.2),0 2px 2px rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12);

            .column {
                padding: 0 24px
            }

            .column.date {
                display: flex;
                flex-direction: column;

                .day {
                    font-size: 1.5rem;
                }
            }

            .column.description {
                width: 100%;

                span {
                    display: block;
                }

                span.price {
                    color: #9e9e9e;
                }
            }

            .separator {
                background-color: #aaa;
                height: 83px;
                width: 2px;
            }
        }
    }

    .select-professional {

        & > .title {
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 2rem;
        }

        .professionals {

            .card {
                box-shadow: 0 1px 5px rgba(0,0,0,0.2),0 2px 2px rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12);
            
                .card-body {
                    svg {
                        font-size: 2rem;
                    }
                    .center {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 1rem;
                    }
                    .title {
                        text-align: center;
                    }
                }

                .card-footer {
                    display: flex;
                    justify-content: center;
                    background-color: white;

                    button {
                        width: 100%;
                        color: #264653;
                        border: 0;
                        background-color: unset;
                        text-transform: uppercase;

                        svg {
                            margin-right: 1rem;
                        }

                        &:hover {
                            background-color: rgb(233, 196, 106, 0.4);
                        }
                    }
                }
            }
        }
    }

    .list-services {

        .card {
            height: 280px !important;
            margin-bottom: 1.5rem;

            .card-header {
                background-color: #e9c46a;
                color: white;
            }

            .card-footer {

                display: flex;
                justify-content: center;
                background-color: white;

                button {
                    width: 100%;
                    color: #264653;
                    border: 0;
                    background-color: unset;
                    text-transform: uppercase;

                    svg {
                        margin-right: 1rem;
                    }

                    &:hover {
                        background-color: rgb(233, 196, 106, 0.4);
                    }
                }
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    .select-time {
        .title {
            font-size: 1.15rem;
            font-weight: 700;
            text-align: center;
        }

        .day {

            padding-top: 1rem;

            &:not(:last-child) {
                .times {
                    border-bottom: 1px dashed #aaa;
                }
            }

            .times {

                .time {
                    background-color: #d6d6d6;
                    border: 1px solid #aaa;
                    padding: 0.5rem 2rem;
                    margin-right: 0.85rem;
                    margin-bottom: 1rem;

                    svg {
                        color: #aaa;
                        margin-right: 0.5rem;
                    }

                    span {
                        color: #040404;
                        font-size: 14px;
                        font-weight: 500;
                    }

                    &:hover {
                        background-color: var(--color-primary);

                        border-color: var(--color-primary);
                        
                        span, svg {
                            color: white;
                        }
                    }
                }
            }
        }
    }
`;

export default WrapperSchedule;