import React from "react";
import moment from "moment";
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import WrapperIntervalTime from "./style";

const getRoundTimeBegin = begin => {
    if(!begin) return begin;
    let newTime = moment(begin, "HH:mm").add(1, 'hour').startOf('hour').format("HH:mm");
    if(newTime === "00:00") newTime = moment(newTime, "HH:mm").subtract(1, "minute").format("HH:mm");
    return newTime;
}

const getRoundTimeEnd = end => {
    if(!end) return end;
    let newTime = moment(end, "HH:mm").startOf('hour').format("HH:mm");
    if(newTime === end) newTime = moment(newTime, "HH:mm").subtract(1, "hour").format("HH:mm");
    return newTime;
}

const onChangeBegin = (newBegin, end, setBegin, setEnd)=>{
    if(newBegin === "") return setBegin(newBegin);
    if(moment(newBegin, "HH:mm").format("HH:mm") >= "23:59") return;
    setBegin(newBegin);
    
    if(!end){
        return setEnd(getRoundTimeBegin(newBegin));
    }

    // if(moment(newBegin, "HH:mm") >= moment(sundayOfficeEnd, "HH:mm")){
    //     return setEnd(getRoundTimeBegin(newBegin));
    // }
}

const onChangeEnd = (newEnd, begin, setBegin, setEnd)=>{
    if(moment(newEnd, "HH:mm").format("HH:mm") < "00:01") return;

    setEnd(newEnd);
    
    if(!begin){
        return setBegin(getRoundTimeEnd(newEnd));
    }

    // if(moment(newEnd, "HH:mm") <= moment(begin, "HH:mm")){
    //     return setBegin(getRoundTimeEnd(newEnd));
    // }
}

export default function IntervalTime({officeBegin, setOfficeBegin, officeEnd, setOfficeEnd}){
    return (
        <WrapperIntervalTime>
            <div className="time begin">
                <Form.Label>Início</Form.Label>
                <Form.Control value={officeBegin} onChange={e=>onChangeBegin(e.target.value, officeEnd, setOfficeBegin, setOfficeEnd)} className="shadow-none" type="time" />
            </div>
            <div className="time end">
                <Form.Label>Fim</Form.Label>
                <Form.Control value={officeEnd} onChange={e=>onChangeEnd(e.target.value, officeBegin, setOfficeBegin, setOfficeEnd)} className="shadow-none" type="time" />
            </div>
        </WrapperIntervalTime>
    );
}