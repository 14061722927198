import styled from 'styled-components';

export const WrapperModalLogin = styled.div`
`;

export const WrapperModalLoginContent = styled.div`

    .modal-header {
        display: flex;
        justify-content: space-between;
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    label.Mui-focused {
        color: #E9C46A;
    }

    .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #E9C46A;
        }
    }

    .btn:disabled {
        background-color: #E9C46A;
    }
`;

export default WrapperModalLogin;