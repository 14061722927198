const INITIAL_STATE = {
    isLoading: false,
    contacts: []
}

const contactReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_CONTACT':
            return {
                ...state, 
                contacts: payload,
                isLoading: false
            };
        case 'INCLUDE_CONTACT':
            return {
                ...state, 
                contacts: [...state.contacts, payload]
            };
        case 'IS_LOADING_CONTACT':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_CONTACT':
            return {
                ...state,
                contacts: state.contacts.map(contact=>(contact.id === payload.id) ? payload : contact)
            };
        case 'DELETE_CONTACT':
            return {
                ...state, 
                contacts: state.contacts.filter(contact=>contact.id != payload.id)
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default contactReducer;