import axios from "axios";
import config from './../../config';
import store from './../../store';

export default {
    scheduling: {
        findAll({ dateInitial, dateFinish, id, limit, status, clientId, professionalId, activePage }){
            return axios.get(`${config.host}/scheduling`, {
                params: {
                    dateInitial, dateFinish, limit, id, status, clientId, professionalId, activePage
                },
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        markAsDone(id){
            return axios.put(`${config.host}/scheduling/${id}/mark-as-done`, {}, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        suggestedDates({professionalId, daySelected, serviceId, clientId}){
            return axios.get(`${config.host}/${store.getState().accountState.username}/scheduling/suggested-dates?daySelected=${daySelected || ""}&professionalId=${professionalId}&serviceId=${serviceId}&clientId=${clientId}`);
        },
        create(data){
            return axios.post(`${config.host}/${store.getState().accountState.username}/scheduling`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        createAdmin(data){
            return axios.post(`${config.host}/scheduling`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAllClient(filters){
            return axios.get(`${config.host}/${store.getState().accountState.username}/scheduling`, {
                headers: {
                    auth: store.getState().accountState.token
                },
                params: filters
            });
        },
        confirm(id){
            return axios.put(`${config.host}/${store.getState().accountState.username}/scheduling/${id}/confirm`, {}, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        cancel(id){
            return axios.put(`${config.host}/${store.getState().accountState.username}/scheduling/${id}/cancel`, {}, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        cancelAdmin(id){
            return axios.put(`${config.host}/scheduling/${id}/cancel`, {}, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    client: {
        update(id, data){
            return axios.put(`${config.host}/client/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(filters){
            return axios.get(`${config.host}/client`, {
                headers: {
                    auth: store.getState().accountState.token
                },
                params: filters
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/client/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        setData({contact, name, password, dateOfBirth, email, gender}){
            return axios.put(`${config.host}/${store.getState().accountState.username}/client`, { contact, name, password, dateOfBirth, email, gender }, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        logout(){
            return axios.post(`${config.host}/${store.getState().accountState.username}/client/logout`, {}, {
                headers: {
                    auth: store.getState().accountState.token,
                    refresh_token: store.getState().accountState.refreshToken
                }
            });
        },
        refresh(data = null){
            return axios.post(`${config.host}/${store.getState().accountState.username}/client/refresh`, data, {
                headers: {
                    refresh_token: store.getState().accountState.refreshToken
                }
            })
                .then(result=>{
                    store.dispatch({
                        type: 'REFRESH_TOKEN',
                        payload: {
                            type: result.data.type,
                            client: result.data.client,
                            token: result.data.token,
                            refreshToken: result.data.refreshToken
                        }
                    });
                })
                .catch(err=>{
                    if(err.response.status === 401){
                        store.dispatch({
                            type: 'LOGOUT'
                        });
                    }
                    
                    return err;
                });
        },
        create(data){
            return axios.post(`${config.host}/${store.getState().accountState.username}/client`, data);
        },
        createAdmin(data){
            return axios.post(`${config.host}/client`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        hasRegister(contact){
            return axios.get(`${config.host}/${store.getState().accountState.username}/client/has-register`, {
                params: {
                    contact
                }
            });
        },
        createOTP(clientId){
            return axios.post(`${config.host}/${store.getState().accountState.username}/client/create-otp`, {
                clientId
            });
        },
        authOTP({clientId, code}){
            return axios.post(`${config.host}/${store.getState().accountState.username}/client/auth-otp`, {
                clientId
            }, {
                headers: {
                    code
                }
            });
        }
        // verifyContact(contact){
        //     return axios.get(`${config.host}/${store.getState().accountState.username}/client/verify-contact`, {
        //         params: {
        //             contact
        //         }
        //     });
        // }
    },
    interval: {
        findAll(){
            return axios.get(`${config.host}/interval`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        create(data){
            return axios.post(`${config.host}/interval`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/interval/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/interval/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    payment: {
        findPublic(){
            return axios.get(`${config.host}/${store.getState().accountState.username || store.getState().accountState.user?.Account?.username}/payment`);
        },
        findAll(){
            return axios.get(`${config.host}/payment`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        create(data){
            return axios.post(`${config.host}/payment`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/payment/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/payment/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    config: {
        findAll(){
            return axios.get(`${config.host}/config`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
    },
    service: {
        findPublic(){
            return axios.get(`${config.host}/${store.getState().accountState.username}/service`);
        },
        findAll(){
            return axios.get(`${config.host}/service`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        create(data){
            return axios.post(`${config.host}/service`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/service/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/service/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    user: {
        findAll({ id, name, isProfessional } = {}){
            return axios.get(`${config.host}/user`, {
                params: { id, name, isProfessional },
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/user/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findProfessionalsPublic(){
            return axios.get(`${config.host}/${store.getState().accountState.username || store.getState().accountState.user?.Account?.username}/user/professionals`);
        },
        auth(data){
            return axios.post(`${config.host}/user/auth`, data);
        },
        logout(){
            return axios.post(`${config.host}/user/logout`, {}, {
                headers: {
                    auth: store.getState().accountState.token,
                    refresh_token: store.getState().accountState.refreshToken
                }
            });
        },
        refresh(data = null){
            return axios.post(`${config.host}/user/refresh`, data, {
                headers: {
                    refresh_token: store.getState().accountState.refreshToken
                }
            })
                .then(result=>{
                    store.dispatch({
                        type: 'REFRESH_TOKEN',
                        payload: {
                            type: result.data.type,
                            user: result.data.user,
                            token: result.data.token,
                            refreshToken: result.data.refreshToken
                        }
                    });
                })
                .catch(err=>{
                    if(err.response.status === 401){
                        store.dispatch({
                            type: 'LOGOUT'
                        });
                    }
                    
                    return err;
                });
        }
    },
    contact: {
        findPublic(){
            return axios.get(`${config.host}/${store.getState().accountState.username || store.getState().accountState.user?.Account?.username}/contact`);
        },
        create(data){
            return axios.post(`${config.host}/contact`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/contact/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(){
            return axios.get(`${config.host}/contact`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/contact/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    notification: {
        routeBase: "notification",
        sendText(text, botContact, leadContact, channel = "whatsapprpa", timing = 0){
            return axios.post(`${config.host}/${this.routeBase}/send-text`, { text, timing, channel, botContact, leadContact }, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    account: {
        findPublic(username){
            return axios.get(`${config.host}/${username}/account`);
        },
        update(data){
            return axios.put(`${config.host}/account`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    ibge: {
        allStates(){
            return axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`);
        }
    },
    correios: {
        getCepInfo(cep){
            return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        }
    }
}