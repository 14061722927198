import React, { useEffect, useState } from "react";
import WrapperCaptureName from "./style";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

export default function CaptureName(){

    const dispatch = useDispatch();
    const user = useSelector(state => state.accountState.user);
    const [name, setName] = useState(user.name);

    useEffect(()=>{
        dispatch({
            type: 'SET_USER',
            payload: Object.assign({}, user, {
                name
            })
        });
    }, [name]);

    return (
        <WrapperCaptureName>
            <p className="description"><b>Informações pessoais</b></p>
            <TextField
                value={ user.contact }
                label="Telefone *"
                placeholder="(00) 90000-0000"
                disabled
            />
            <TextField
                value={ name }
                onChange={e => setName(e.target.value)}
                label="Seu nome *"
                placeholder="Informe seu nome e sobrenome"
            />
        </WrapperCaptureName>
    );
}