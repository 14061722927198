const INITIAL_STATE = {
    isLoading: false,
    modalOpen: false,
    message: "",
    contact: ""
}

const notificationReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'SET_MESSAGE_NOTIFICATION':
            return { ...state,
                message: payload
            };
        case 'SET_CONTACT_NOTIFICATION':
            return { ...state,
                contact: payload
            };
        case 'SET_MODAL_OPEN_NOTIFICATION':
            return { ...state,
                modalOpen: payload
            };
        case 'IS_LOADING_NOTIFICATION':
            return {
                ...state,
                isLoading: payload
            };
        case 'CLEAR_NOTIFICATION':
            return state;
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default notificationReducer;