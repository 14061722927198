import styled from "styled-components";

export const WrapperLogin = styled.div`
    height: 100vh;
    background-color: var(--color-bg) !important;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none;
    }

    .container {
        max-width: 450px;
    }

    button {
        width: 100%;
        text-transform: uppercase;
        margin-top: 20px;
        background-color: var(--color-primary);
        --bs-btn-disabled-bg: var(--color-primary);
        border: none;

        &:hover,
        &:active {
            background-color: var(--color-primary) !important;
            opacity: 0.85;
        }
    }

    img {
        width: 100%;
        margin-bottom: 20px;
    }

    h1 {
        margin-bottom: 40px;
        text-align: center;
    }

    label {
        color: #24231E;
    }

    form {
        background-color: var(--color-light);
        padding: 3em;
        border-radius: 18px;
    }

    .register {
        display: flex;
        justify-content: center;
    }
`;

export default WrapperLogin;