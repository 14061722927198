import styled from 'styled-components';
import { Table } from 'react-bootstrap';

export const WrapperTable = styled(Table)`

    --bs-table-bg: unset;

    thead {
        background-color: var(--color-main);

        &>:not(caption)>*>* {
            color: white;
        }

        thead tr th {
            span {
                color: white !important;
                font-family: QuicksandBold;
                font-weight: 700 !important;
            }

            .content {
                &:hover {
                    cursor: pointer;
                }

                &:not(.active) {
                    &:hover {
                        opacity: 0.8;
                    }

                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .news-data-table {
        text-align: center;
        vertical-align: middle;
    }

    .news-data-table p {
        display: inline;
        vertical-align: middle;
        margin: 0;
        color: var(--color-primary) !important;
        font-weight: 800 !important;
    }

    .news-data-table .center-elements {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .news-data-table svg {
        margin-right: 0.4rem;
    }

    .news-data-table svg:hover {
        cursor: pointer;
    }

    thead th svg {
        border: none;
        font-size: 16px;
    }

    thead .order-column-arrow {
        margin-right: 0.5rem;
    }

    #modal svg {
        border: none;
        font-size: 1.2em;
    }

    tbody td svg {
        font-size: 1.5em;
        color: var(--color-primary);
    }
    
    tbody {
        border: none !important;

        td {
            border-color: var(--color-line);
            vertical-align: middle;
            font-size: 16px;
        }
    }
    
    tr:last-child th:last-child {
        border-top-right-radius: 10px;
    }

    tr:first-child th:first-child {
        border-top-left-radius: 10px;
    }
`;

export default WrapperTable;