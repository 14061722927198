import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import WrapperAdminSetting, { WrapperAdminSettingCompany, WrapperAdminSettingContact, WrapperAdminSettingOfficeHour, WrapperAdminSettingPayment, WrapperAdminSettingScheduling } from "./style";
import Modal from "../../../Components/Modal";
import { http } from "../../../services";
import utils from "../../../utils";
import store from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../Components/Table";
import { faMagnifyingGlass, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import _, { result } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntervalTime from "../../../Components/IntervalTime";
import { useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import SelectWithIcons from "../../../Components/SelectWithIcons";

const config = {
    contact: {
        columns: [
            { name: 'id', translate: 'Id' },
            { name: 'contact', translate: 'Contato' },
            { name: 'type', translate: 'Tipo', type: 'contact' }
        ],
        include: {
            cta: "Incluir",
            title: 'Incluir contato',
            ctaFn: ()=>{
                http.contact.create(Object.assign({}, store.getState().formState.form, {
                    contact: `${["whatsapp", "phone"].includes(store.getState().formState.form.type) ? "55" : ""}${store.getState().formState.form.contact}`
                }))
                    .then(result=>{
                        store.dispatch({
                            type: 'INCLUDE_CONTACT',
                            payload: result.data
                        });
                        utils.createNotification({
                            type: 'success',
                            title: 'Contato criado com sucesso'
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: 'Erro ao criar contato',
                        message: error.response.data
                    }))
                    .finally(()=>store.dispatch({
                        type: 'CLEAR_FORM'
                    }));
            }
        },
        update: {
            cta: "Editar",
            title: 'Editar contato',
            icon: faPen,
            ctaFn: ({id})=>{
                http.contact.update(id, Object.assign({}, store.getState().formState.form, {
                    contact: `${["whatsapp", "phone"].includes(store.getState().formState.form.type) ? "55" : ""}${store.getState().formState.form.contact}`
                }))
                    .then(result=>{
                        store.dispatch({
                            type: 'UPDATE_CONTACT',
                            payload: result.data
                        });
                        utils.createNotification({
                            type: 'success',
                            title: `As informações do contato ${id} foram alteradas!`
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: `Falha ao editar contato ${id}`,
                        message: error.response.data
                    }))
                    .finally(()=>store.dispatch({
                        type: 'CLEAR_FORM'
                    }));
            }
        },
        remove: {
            cta: "Deletar",
            title: 'Deletar contato',
            ctaFn: ({ id })=>{
                http.contact.destroy(id)
                    .then(()=>{
                        store.dispatch({
                            type: 'DELETE_CONTACT',
                            payload: {
                                id
                            }
                        });
                        utils.createNotification({
                            type: 'success',
                            title: 'Contato deletado',
                            message: `O contato ${id} foi deletado!`
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: `Falha ao deletar contato ${id}`,
                        message: error.response.data
                    }));
            },
            icon: faTrash,
            style: {
                backgroundColor: 'var(--color-danger)'
            }
        }
    },
    payment: {
        columns: [
            { name: 'id', translate: 'Id' },
            { name: 'name', translate: 'Nome' },
            { name: 'active', translate: 'Ativo', type: 'boolean' },
            { name: 'icon', translate: 'Ícone', type: "icon" }
        ],
        include: {
            cta: "Incluir",
            title: 'Incluir pagamento',
            ctaFn: ()=>{
                http.payment.create(store.getState().formState.form)
                    .then(result=>{
                        store.dispatch({
                            type: 'INCLUDE_PAYMENT',
                            payload: result.data
                        });
                        utils.createNotification({
                            type: 'success',
                            title: 'Pagamento criado com sucesso'
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: 'Falha ao criar pagamento',
                        message: error.response.data
                    }))
                    .finally(()=>store.dispatch({
                        type: 'CLEAR_FORM'
                    }));
            }
        },
        update: {
            cta: "Editar",
            title: 'Editar pagamento',
            icon: faPen,
            ctaFn: ({id})=>{
                http.payment.update(id, store.getState().formState.form)
                    .then(result=>{
                        store.dispatch({
                            type: 'UPDATE_PAYMENT',
                            payload: result.data
                        });
                        utils.createNotification({
                            type: 'success',
                            title: `As informações do pagamento ${id} foram alteradas!`
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: `Falha ao editar pagamento ${id}`,
                        message: error.response.data
                    }))
                    .finally(()=>store.dispatch({
                        type: 'CLEAR_FORM'
                    }));
            }
        },
        remove: {
            cta: "Deletar",
            title: 'Deletar pagamento',
            ctaFn: ({ id })=>{
                http.payment.destroy(id)
                    .then(()=>{
                        store.dispatch({
                            type: 'DELETE_PAYMENT',
                            payload: {
                                id
                            }
                        });
                        utils.createNotification({
                            type: 'success',
                            title: 'Pagamento deletado',
                            message: `O pagamento ${id} foi deletado!`
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: `Falha ao deletar pagamento ${id}`,
                        message: error.response.data
                    }));
            },
            icon: faTrash,
            style: {
                backgroundColor: 'var(--color-danger)'
            }
        }
    },
    interval: {
        columns: [
            { name: 'id', translate: 'Id' },
            { name: 'day', translate: 'Dia da semana', type: 'day_of_week' },
            { name: 'begin', translate: 'Início', type: 'timeHHmm' },
            { name: 'end', translate: 'Fim', type: 'timeHHmm' }
        ],
        include: {
            cta: "Incluir",
            title: 'Incluir intervalo',
            styledCustomContent: css`
                .modal-body {
                    .title {
                        margin: 0;
                    }
                }
            `,
            ctaFn: ()=>{
                http.interval.create(store.getState().formState.form)
                    .then(result=>{
                        store.dispatch({
                            type: 'INCLUDE_INTERVAL',
                            payload: result.data
                        });
                        utils.createNotification({
                            type: 'success',
                            title: 'Intervalo criado com sucesso'
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: 'Falha ao criar intervalo',
                        message: error.response.data
                    }))
                    .finally(()=>store.dispatch({
                        type: 'CLEAR_FORM'
                    }));
            }
        },
        remove: {
            cta: "Deletar",
            title: 'Deletar intervalo',
            ctaFn: ({ id })=>{
                http.interval.destroy(id)
                    .then(()=>{
                        store.dispatch({
                            type: 'DELETE_INTERVAL',
                            payload: {
                                id
                            }
                        });
                        utils.createNotification({
                            type: 'success',
                            title: 'Intervalo deletado',
                            message: `O intervalo ${id} foi deletado!`
                        });
                    })
                    .catch(error=>utils.createNotification({
                        type: 'error',
                        title: `Falha ao deletar intervalo ${id}`,
                        message: error.response.data
                    }));
            },
            icon: faTrash,
            style: {
                backgroundColor: 'var(--color-danger)'
            }
        }
    }
}

function ContactRemoveContent({ id }){
    return (
        <p>Deseja deletar o contato {id}?</p>
    );
}

function ContactUpdateContent(props){

    const dispatch = useDispatch();
    const [contact, setContact] = useState(props.contact.replace(/^55/, ""));
    const [type, setType] = useState(props.type);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                contact: ["whatsapp", "phone"].includes(type) ? contact.replace(/\D/g, "") : contact,
                type
            }
        });
    }, [contact, type]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Tipo</Form.Label>
                            <Form.Select value={type} className="shadow-none" onChange={e=>setType(e.target.value)}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="phone">Telefone</option>
                                <option value="email">E-mail</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Contato</Form.Label>
                            <Form.Control
                                value={contact}
                                className="shadow-none"
                                type="text"
                                placeholder="Digite o seu contato"
                                onChange={e=>setContact(["whatsapp", "phone"].includes(type) ? utils.formatContact(e.target.value) : e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function ContactIncludeContent(){

    const dispatch = useDispatch();
    const [contact, setContact] = useState("");
    const [type, setType] = useState("whatsapp");

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                contact: ["whatsapp", "phone"].includes(type) ? contact.replace(/\D/g, "") : contact,
                type
            }
        });
    }, [contact, type]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Tipo</Form.Label>
                            <Form.Select value={type} className="shadow-none" onChange={e=>setType(e.target.value)}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="phone">Telefone</option>
                                <option value="email">E-mail</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Contato</Form.Label>
                            <Form.Control
                                value={contact}
                                className="shadow-none"
                                type="text"
                                placeholder="Digite o seu contato"
                                onChange={e=>setContact(["whatsapp", "phone"].includes(type) ? utils.formatContact(e.target.value) : e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function IntervalIncludeContent(){

    const dispatch = useDispatch();
    const [day, setDay] = useState(0);
    const [officeBegin, setOfficeBegin] = useState("");
    const [officeEnd, setOfficeEnd] = useState("");

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                day, begin: officeBegin, end: officeEnd
            }
        });
    }, [day, officeBegin, officeEnd]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Dia da semana</Form.Label>
                            <Form.Select value={day} className="shadow-none" onChange={e=>setDay(e.target.value)}>
                                <option value={0}>Domingo</option>
                                <option value={1}>Segunda-feira</option>
                                <option value={2}>Terça-feira</option>
                                <option value={3}>Quarta-feira</option>
                                <option value={4}>Quinta-feira</option>
                                <option value={5}>Sexta-feira</option>
                                <option value={6}>Sábado</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formName">
                            <IntervalTime
                                officeBegin={officeBegin}
                                setOfficeBegin={setOfficeBegin}
                                officeEnd={officeEnd}
                                setOfficeEnd={setOfficeEnd}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function PaymentIncludeContent(){

    const dispatch = useDispatch();
    const { configs } = useSelector(state => state.configState);
    const [name, setName] = useState("");
    const [icon, setIcon] = useState("");

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, icon
            }
        });
    }, [name, icon]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                value={name}
                                className="shadow-none"
                                type="text"
                                placeholder="Digite o nome do pagamento"
                                onChange={e=>setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Ícone</Form.Label>
                            <SelectWithIcons
                                placeholder={"Selecione o ícone"}
                                options={configs.icons}
                                onChange={e=>setIcon(e.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.contact.update }
                row={ props.row }
                content={ <ContactUpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.contact.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <ContactRemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

function IntervalRemoveContent({ id }){
    return (
        <p>Deseja deletar o intervalo {id}?</p>
    );
}

function PaymentUpdateContent(props){

    const dispatch = useDispatch();
    const [name, setName] = useState(props.name);
    const [icon, setIcon] = useState(props.icon);
    const { configs } = useSelector(state => state.configState);
    const [active, setActive] = useState(props.active);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, icon, active
            }
        });
    }, [name, icon, active]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                value={name}
                                className="shadow-none"
                                type="text"
                                placeholder="Digite o nome do pagamento"
                                onChange={e=>setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formIcon">
                            <Form.Label>Ícone</Form.Label>
                            <SelectWithIcons
                                value={configs.icons.find(i=>i.value===icon)}
                                placeholder={"Selecione o ícone"}
                                options={configs.icons}
                                onChange={e=>setIcon(e.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formActive">
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                label={`Ativo`}
                                checked={active}
                                onChange={e=>setActive(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function PaymentRemoveContent({ id }){
    return (
        <p>Deseja deletar o pagamento {id}?</p>
    );
}

function IntervalTableActions(props){

    return (
        <>
            <Modal
                config={ Object.assign({}, config.interval.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <IntervalRemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

function PaymentTableActions(props){

    return (
        <>
            <Modal
                config={ config.payment.update }
                row={ props.row }
                content={ <PaymentUpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.payment.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <PaymentRemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

function Contact(){

    const dispatch = useDispatch();
    const { contacts, isLoading } = useSelector(state=>state.contactState);
    const { isLogged, type } = useSelector(state=>state.accountState);

    useEffect(()=>{
        if(!(!isLogged || type !== "user")){
            http.contact.findAll()
                .then(result=>dispatch({
                    type: "LOAD_CONTACT",
                    payload: result.data
                }))
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Falha ao buscar contatos',
                    message: error.response.data
                }));
        }
    }, []);

    return (
        <Row>
            <Col>
                <Modal
                    config={ config.contact.include }
                    content={ <ContactIncludeContent /> }
                />
                {
                    isLoading ? <div><Spinner animation="border" /></div> :
                    <Table
                        data={ contacts }
                        config={ config.contact }
                        Actions={ TableActions }
                    />
                }
            </Col>
        </Row>
    );
}

export default function AdminSetting(){

    const dispatch = useDispatch();
    const { breaks, isLoading: isLoadingInterval } = useSelector(state => state.intervalState);
    const { payments, isLoading: isLoadingPayment } = useSelector(state => state.paymentState);
    const [searchParams, setSearchParams] = useSearchParams();
    const account = useSelector(state => state.accountState);
    const [name, setName] = useState(account.user.Account?.name || "");
    const [username, setUsername] = useState(account.user.Account?.username || "");
    const [description, setDescription] = useState(account.user.Account?.description || "");
    const [numberWhatsAppForNotification, setNumberWhatsAppForNotification] = useState(account.user.Account?.numberWhatsAppForNotification || "");
    const [cep, setCep] = useState(account.user.Account?.cep || "");
    const [address, setAddress] = useState(account.user.Account?.address || "");
    const [number, setNumber] = useState(account.user.Account?.number || "");
    const [district, setDistrict] = useState(account.user.Account?.district || "");
    const [complement, setComplement] = useState(account.user.Account?.complement || "");
    const [city, setCity] = useState(account.user.Account?.city || "");
    const [states, setStates] = useState([]);
    const [uf, setUf] = useState(states.find(s=>s.name===account.user.Account?.state)?.uf || "");
    const [sundayOfficeBegin, setSundayOfficeBegin] = useState(account.user.Account?.sundayOfficeBegin || "");
    const [sundayOfficeEnd, setSundayOfficeEnd] = useState(account.user.Account?.sundayOfficeEnd || "");
    const [mondayOfficeBegin, setMondayOfficeBegin] = useState(account.user.Account?.mondayOfficeBegin || "");
    const [mondayOfficeEnd, setMondayOfficeEnd] = useState(account.user.Account?.mondayOfficeEnd || "");
    const [tuesdayOfficeBegin, setTuesdayOfficeBegin] = useState(account.user.Account?.tuesdayOfficeBegin || "");
    const [tuesdayOfficeEnd, setTuesdayOfficeEnd] = useState(account.user.Account?.tuesdayOfficeEnd || "");
    const [wednesdayOfficeBegin, setWednesdayOfficeBegin] = useState(account.user.Account?.wednesdayOfficeBegin || "");
    const [wednesdayOfficeEnd, setWednesdayOfficeEnd] = useState(account.user.Account?.wednesdayOfficeEnd || "");
    const [thursdayOfficeBegin, setThursdayOfficeBegin] = useState(account.user.Account?.thursdayOfficeBegin || "");
    const [thursdayOfficeEnd, setThursdayOfficeEnd] = useState(account.user.Account?.thursdayOfficeEnd || "");
    const [fridayOfficeBegin, setFridayOfficeBegin] = useState(account.user.Account?.fridayOfficeBegin || "");
    const [fridayOfficeEnd, setFridayOfficeEnd] = useState(account.user.Account?.fridayOfficeEnd || "");
    const [saturdayOfficeBegin, setSaturdayOfficeBegin] = useState(account.user.Account?.saturdayOfficeBegin || "");
    const [saturdayOfficeEnd, setSaturdayOfficeEnd] = useState(account.user.Account?.saturdayOfficeEnd || "");
    const [reminderActive, setReminderActive] = useState(account.user.Account?.reminderActive);
    const [advanceReminderScheduling, setAdvanceReminderScheduling] = useState(account.user.Account?.advanceReminderScheduling || "");
    const [advanceForScheduling, setAdvanceForScheduling] = useState(account.user.Account?.advanceForScheduling || "");
    const [allowCancelScheduling, setAllowCancelScheduling] = useState(account.user.Account?.allowCancelScheduling);
    const [advanceForCancelScheduling, setAdvanceForCancelScheduling] = useState(account.user.Account?.advanceForCancelScheduling || "");
    const [periodLimitForScheduling, setPeriodLimitForScheduling] = useState(account.user.Account?.periodLimitForScheduling || "");
    const [isLoadingCep, setIsLoadingCep] = useState(false);
    const [thereAreChanges, setThereAreChanges] = useState(false);
    const [defaultConfig, setDefaultConfig] = useState({
        name,
        username,
        description,
        numberWhatsAppForNotification,
        cep,
        address,
        number,
        district,
        complement,
        city,
        state: account.user.Account?.state,
        sundayOfficeBegin,
        sundayOfficeEnd,
        mondayOfficeBegin,
        mondayOfficeEnd,
        tuesdayOfficeBegin,
        tuesdayOfficeEnd,
        wednesdayOfficeBegin,
        wednesdayOfficeEnd,
        thursdayOfficeBegin,
        thursdayOfficeEnd,
        fridayOfficeBegin,
        fridayOfficeEnd,
        saturdayOfficeBegin,
        saturdayOfficeEnd,
        reminderActive,
        advanceReminderScheduling,
        advanceForScheduling,
        allowCancelScheduling,
        advanceForCancelScheduling,
        periodLimitForScheduling
    });
    const [currentConfig, setCurrentConfig] = useState({
        name,
        username,
        description,
        numberWhatsAppForNotification,
        cep,
        address,
        number,
        district,
        complement,
        city,
        state: account.user.Account?.state,
        sundayOfficeBegin,
        sundayOfficeEnd,
        mondayOfficeBegin,
        mondayOfficeEnd,
        tuesdayOfficeBegin,
        tuesdayOfficeEnd,
        wednesdayOfficeBegin,
        wednesdayOfficeEnd,
        thursdayOfficeBegin,
        thursdayOfficeEnd,
        fridayOfficeBegin,
        fridayOfficeEnd,
        saturdayOfficeBegin,
        saturdayOfficeEnd,
        reminderActive,
        advanceReminderScheduling,
        advanceForScheduling,
        allowCancelScheduling,
        advanceForCancelScheduling,
        periodLimitForScheduling
    });

    const toFillInAddress = ()=>{
        setIsLoadingCep(true);

        http.correios.getCepInfo(cep)
            .then(result=>{
                setUf(result.data.uf);
                setAddress(result.data.logradouro);
                setCity(result.data.localidade);
                setComplement(result.data.complemento);
                setDistrict(result.data.bairro);
            })
            .finally(()=>setIsLoadingCep(false));
    }

    const cancel = ()=>{
        setName(defaultConfig.name || "");
        setUsername(defaultConfig.username || "");
        setDescription(defaultConfig.description || "");
        setNumberWhatsAppForNotification(defaultConfig.numberWhatsAppForNotification || "");
        setCep(defaultConfig.cep || "");
        setAddress(defaultConfig.address || "");
        setNumber(defaultConfig.number || "");
        setDistrict(defaultConfig.district || "");
        setComplement(defaultConfig.complement || "");
        setCity(defaultConfig.city || "");
        setUf(states.find(s=>s.name===defaultConfig.state)?.uf || "");
        setSundayOfficeBegin(defaultConfig.sundayOfficeBegin || "");
        setSundayOfficeEnd(defaultConfig.sundayOfficeEnd || "");
        setMondayOfficeBegin(defaultConfig.mondayOfficeBegin || "");
        setMondayOfficeEnd(defaultConfig.mondayOfficeEnd || "");
        setTuesdayOfficeBegin(defaultConfig.tuesdayOfficeBegin || "");
        setTuesdayOfficeEnd(defaultConfig.tuesdayOfficeEnd || "");
        setWednesdayOfficeBegin(defaultConfig.wednesdayOfficeBegin || "");
        setWednesdayOfficeEnd(defaultConfig.wednesdayOfficeEnd || "");
        setThursdayOfficeBegin(defaultConfig.thursdayOfficeBegin || "");
        setThursdayOfficeEnd(defaultConfig.thursdayOfficeEnd || "");
        setFridayOfficeBegin(defaultConfig.fridayOfficeBegin || "");
        setFridayOfficeEnd(defaultConfig.fridayOfficeEnd || "");
        setSaturdayOfficeBegin(defaultConfig.saturdayOfficeBegin || "");
        setSaturdayOfficeEnd(defaultConfig.saturdayOfficeEnd || "");
        setReminderActive(defaultConfig.reminderActive);
        setAdvanceReminderScheduling(defaultConfig.advanceReminderScheduling || "");
        setAdvanceForScheduling(defaultConfig.advanceForScheduling || "");
        setAllowCancelScheduling(defaultConfig.allowCancelScheduling);
        setAdvanceForCancelScheduling(defaultConfig.advanceForCancelScheduling || "");
        setPeriodLimitForScheduling(defaultConfig.periodLimitForScheduling || "");
    }

    const save = ()=>{

        dispatch({
            type: "IS_LOADING",
            payload: true
        });

        http.account.update(Object.assign({}, currentConfig, {
            uf
        }))
            .then(result => {
                setCep(defaultConfig.cep);
                setAddress(defaultConfig.address);
                setDescription(defaultConfig.description);
                setNumber(defaultConfig.number);
                setDistrict(defaultConfig.district);
                setComplement(defaultConfig.complement);
                setCity(defaultConfig.city);
                setUf(states.find(s=>s.name===defaultConfig.state)?.uf);
                setSundayOfficeBegin(defaultConfig.sundayOfficeBegin);
                setSundayOfficeEnd(defaultConfig.sundayOfficeEnd);
                setMondayOfficeBegin(defaultConfig.mondayOfficeBegin);
                setMondayOfficeEnd(defaultConfig.mondayOfficeEnd);
                setTuesdayOfficeBegin(defaultConfig.tuesdayOfficeBegin);
                setTuesdayOfficeEnd(defaultConfig.tuesdayOfficeEnd);
                setWednesdayOfficeBegin(defaultConfig.wednesdayOfficeBegin);
                setWednesdayOfficeEnd(defaultConfig.wednesdayOfficeEnd);
                setThursdayOfficeBegin(defaultConfig.thursdayOfficeBegin);
                setThursdayOfficeEnd(defaultConfig.thursdayOfficeEnd);
                setFridayOfficeBegin(defaultConfig.fridayOfficeBegin);
                setFridayOfficeEnd(defaultConfig.fridayOfficeEnd);
                setSaturdayOfficeBegin(defaultConfig.saturdayOfficeBegin);
                setSaturdayOfficeEnd(defaultConfig.saturdayOfficeEnd);
                setReminderActive(defaultConfig.reminderActive);
                setAdvanceReminderScheduling(defaultConfig.advanceReminderScheduling);
                setAdvanceForScheduling(defaultConfig.advanceForScheduling);
                setAllowCancelScheduling(defaultConfig.allowCancelScheduling);
                setAdvanceForCancelScheduling(defaultConfig.advanceForCancelScheduling);
                setPeriodLimitForScheduling(defaultConfig.periodLimitForScheduling);
                setDefaultConfig({
                    name: result.data.name,
                    username: result.data.username,
                    description: result.data.description,
                    numberWhatsAppForNotification: result.data.numberWhatsAppForNotification,
                    cep: result.data.cep,
                    address: result.data.address,
                    number: result.data.number,
                    district: result.data.district,
                    complement: result.data.complement,
                    city: result.data.city,
                    state: result.data.state,
                    sundayOfficeBegin: result.data.sundayOfficeBegin,
                    sundayOfficeEnd: result.data.sundayOfficeEnd,
                    mondayOfficeBegin: result.data.mondayOfficeBegin,
                    mondayOfficeEnd: result.data.mondayOfficeEnd,
                    tuesdayOfficeBegin: result.data.tuesdayOfficeBegin,
                    tuesdayOfficeEnd: result.data.tuesdayOfficeEnd,
                    wednesdayOfficeBegin: result.data.wednesdayOfficeBegin,
                    wednesdayOfficeEnd: result.data.wednesdayOfficeEnd,
                    thursdayOfficeBegin: result.data.thursdayOfficeBegin,
                    thursdayOfficeEnd: result.data.thursdayOfficeEnd,
                    fridayOfficeBegin: result.data.fridayOfficeBegin,
                    fridayOfficeEnd: result.data.fridayOfficeEnd,
                    saturdayOfficeBegin: result.data.saturdayOfficeBegin,
                    saturdayOfficeEnd: result.data.saturdayOfficeEnd,
                    reminderActive: result.data.reminderActive,
                    advanceReminderScheduling: result.data.advanceReminderScheduling,
                    advanceForScheduling: result.data.advanceForScheduling,
                    allowCancelScheduling: result.data.allowCancelScheduling,
                    advanceForCancelScheduling: result.data.advanceForCancelScheduling,
                    periodLimitForScheduling: result.data.periodLimitForScheduling
                });
                dispatch({
                    type: "UPDATE_ACCOUNT",
                    payload: Object.assign({}, account.user, {
                        Account: result.data
                    })
                });
                utils.createNotification({
                    type: 'success',
                    title: 'As informações da sua conta foram atualizadas!'
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao atualizar informações da sua conta',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: "IS_LOADING",
                payload: false
            }));
    }

    useEffect(()=>{
        setThereAreChanges(_.isEqual(defaultConfig, currentConfig));
    }, [_.isEqual(defaultConfig, currentConfig)]);
    
    useEffect(()=>{
        setCurrentConfig({
            name,
            username,
            description,
            numberWhatsAppForNotification,
            cep,
            address,
            number,
            district,
            complement,
            city,
            state: states.find(s=>s.uf===uf)?.name || "",
            sundayOfficeBegin,
            sundayOfficeEnd,
            mondayOfficeBegin,
            mondayOfficeEnd,
            tuesdayOfficeBegin,
            tuesdayOfficeEnd,
            wednesdayOfficeBegin,
            wednesdayOfficeEnd,
            thursdayOfficeBegin,
            thursdayOfficeEnd,
            fridayOfficeBegin,
            fridayOfficeEnd,
            saturdayOfficeBegin,
            saturdayOfficeEnd,
            reminderActive,
            advanceReminderScheduling,
            advanceForScheduling,
            allowCancelScheduling,
            advanceForCancelScheduling,
            periodLimitForScheduling
        });
    }, [name, username, description, numberWhatsAppForNotification, cep, address, 
        number, district, complement, city, uf, sundayOfficeBegin, sundayOfficeEnd,
        mondayOfficeBegin, mondayOfficeEnd, tuesdayOfficeBegin, tuesdayOfficeEnd,
        wednesdayOfficeBegin, wednesdayOfficeEnd, thursdayOfficeBegin, thursdayOfficeEnd,
        fridayOfficeBegin, fridayOfficeEnd, saturdayOfficeBegin, saturdayOfficeEnd,
        reminderActive, advanceReminderScheduling, advanceForScheduling,
        allowCancelScheduling, advanceForCancelScheduling, periodLimitForScheduling
    ]);

    useEffect(()=>{
        setUf(states.find(s=>s.name===defaultConfig.state)?.uf || "");
    }, [states]);

    useEffect(()=>{
        http.ibge.allStates()
            .then(result=>{
                setStates(result.data.map(s=>({
                    name: s.nome,
                    uf: s.sigla
                })));
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao carregar estados do IBGE',
                message: error.response.data
            }));

        http.interval.findAll()
            .then(result=>{
                dispatch({
                    type: "LOAD_INTERVAL",
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao carregar intervalos',
                message: error.response.data
            }));

        http.payment.findAll()
            .then(result=>{
                dispatch({
                    type: "LOAD_PAYMENT",
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao carregar pagamentos',
                message: error.response.data
            }));
    }, []);

    return (
        <WrapperAdminSetting>
            <Card>
                <Card.Body>
                    <Form>
                        <Tabs
                            defaultActiveKey={searchParams.get("tab") || "company"}
                            onSelect={key=>setSearchParams({
                                tab: key
                            })}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <WrapperAdminSettingCompany eventKey="company" title="Empresa">
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formCompanyName">
                                            <Form.Label><b>Empresa</b></Form.Label>
                                            <Form.Control value={name} onChange={e=>setName(e.target.value)} type="text" className="shadow-none" placeholder="Digite o nome da sua empresa" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formUsername">
                                            <Form.Label><b>Nome de usuário</b></Form.Label>
                                            <Form.Control value={username} onChange={e=>setUsername(e.target.value)} type="text" className="shadow-none" placeholder="Digite o nome que as pessoas encontrarão o seu perfil" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formUsername">
                                            <Form.Label><b>Descrição</b></Form.Label>
                                            <Form.Control as={"textarea"} value={description} onChange={e=>setDescription(e.target.value)} type="text" className="shadow-none" placeholder="Digite a descrição da sua empresa" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formNumberWhatsAppForNotification">
                                            <Form.Label><b>WhatsApp de notificações</b></Form.Label>
                                            <Form.Control value={numberWhatsAppForNotification} onChange={e=>setNumberWhatsAppForNotification(e.target.value)} type="text" className="shadow-none" placeholder="Digite o WhatsApp de notificações" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3" controlId="formCep">
                                            <Form.Label><b>Cep</b></Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    value={cep}
                                                    onChange={e=>setCep(e.target.value)}
                                                    type="text"
                                                    className="shadow-none"
                                                    placeholder="Digite o cep da sua empresa"
                                                />
                                                <Button onClick={toFillInAddress} disabled={isLoadingCep}>
                                                    {
                                                        isLoadingCep ? <Spinner size="sm" animation="border" /> : <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                    }
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formAddress">
                                            <Form.Label><b>Rua</b></Form.Label>
                                            <Form.Control value={address} onChange={e=>setAddress(e.target.value)} type="text" className="shadow-none" placeholder="Digite a rua" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3" controlId="formNumber">
                                            <Form.Label><b>Número</b></Form.Label>
                                            <Form.Control value={number} onChange={e=>setNumber(e.target.value)} type="text" className="shadow-none" placeholder="Seu número" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formDistrict">
                                            <Form.Label><b>Bairro</b></Form.Label>
                                            <Form.Control value={district} onChange={e=>setDistrict(e.target.value)} type="text" className="shadow-none" placeholder="Digite o seu bairro" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formComplement">
                                            <Form.Label><b>Complemento</b></Form.Label>
                                            <Form.Control value={complement} onChange={e=>setComplement(e.target.value)} type="text" className="shadow-none" placeholder="Digite o seu complemento" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formCity">
                                            <Form.Label><b>Cidade</b></Form.Label>
                                            <Form.Control value={city} onChange={e=>setCity(e.target.value)} type="text" className="shadow-none" placeholder="Digite a sua cidade" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formComplement">
                                            <Form.Label><b>Estado</b></Form.Label>
                                            <Form.Select value={uf} onChange={e=>setUf(e.target.value)} className="shadow-none">
                                                <option value="">Selecione</option>
                                                {
                                                    states.map(s=><option value={s.uf}>{s.name}</option>)
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </WrapperAdminSettingCompany>
                            <WrapperAdminSettingContact eventKey="contact" title="Contatos">
                                <Contact />
                            </WrapperAdminSettingContact>
                            <WrapperAdminSettingScheduling eventKey="scheduling" title="Agendamento">
                                <Row>
                                    <Col>
                                        {/* <Form.Group className="mb-3 reminder" controlId="reminder">
                                            <Form.Check
                                                checked={reminderActive}
                                                onChange={e=>setReminderActive(e.target.checked)}
                                                type={"checkbox"}
                                                label={`Ativar lembrete`}
                                            />
                                            <div className="advance">
                                                <Form.Label><b>Tempo de antecedência do lembrete {"(em minutos)"}</b></Form.Label>
                                                <Row>
                                                    <Col md={2}>
                                                        <Form.Control value={advanceReminderScheduling} onChange={e=>setAdvanceReminderScheduling(e.target.advanceReminderScheduling)} className="shadow-none" type="number" />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form.Group> */}
                                        <Form.Group className="mb-3 reminder" controlId="reminder">
                                            <Form.Label><b>Tempo de antecedência para o agendamento {"(em minutos)"}</b></Form.Label>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Control value={advanceForScheduling} onChange={e=>setAdvanceForScheduling(e.target.value)} className="shadow-none" type="number" />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className="mb-3 reminder" controlId="reminder">
                                            <Form.Check
                                                checked={allowCancelScheduling}
                                                onChange={e=>setAllowCancelScheduling(e.target.checked)}
                                                type={"checkbox"}
                                                label={`Permitir o cliente cancelar`}
                                            />
                                            <div className="advance">
                                                <Form.Label><b>Tempo de antecedência para cancelamento {"(em minutos)"}</b></Form.Label>
                                                <Row>
                                                    <Col md={2}>
                                                        <Form.Control value={advanceForCancelScheduling} onChange={e=>setAdvanceForCancelScheduling(e.target.advanceForCancelScheduling)} className="shadow-none" type="number" />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3 reminder" controlId="reminder">
                                            <Form.Label><b>Período máximo para agendar {"(em minutos)"}</b></Form.Label>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Control value={periodLimitForScheduling} onChange={e=>setPeriodLimitForScheduling(e.target.value)} className="shadow-none" type="number" />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </WrapperAdminSettingScheduling>
                            <Tab eventKey="officeHour" title="Horário de funcionamento">
                                <WrapperAdminSettingOfficeHour>
                                    <Card>
                                        <Card.Header>Expediente</Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col md={4} lg={3}>
                                                            <Form.Group className="mb-3 office-hour sunday" controlId="officeHourSunday">
                                                                <Form.Label><b>Domingo</b></Form.Label>
                                                                <IntervalTime
                                                                    officeBegin={sundayOfficeBegin}
                                                                    setOfficeBegin={setSundayOfficeBegin}
                                                                    officeEnd={sundayOfficeEnd}
                                                                    setOfficeEnd={setSundayOfficeEnd}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} lg={3}>
                                                            <Form.Group className="mb-3 office-hour monday" controlId="officeHourMonday">
                                                                <Form.Label><b>Segunda-feira</b></Form.Label>
                                                                <IntervalTime
                                                                    officeBegin={mondayOfficeBegin}
                                                                    setOfficeBegin={setMondayOfficeBegin}
                                                                    officeEnd={mondayOfficeEnd}
                                                                    setOfficeEnd={setMondayOfficeEnd}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} lg={3}>
                                                            <Form.Group className="mb-3 office-hour tuesday" controlId="officeHourTuesday">
                                                                <Form.Label><b>Terça-feira</b></Form.Label>
                                                                <IntervalTime
                                                                    officeBegin={tuesdayOfficeBegin}
                                                                    setOfficeBegin={setTuesdayOfficeBegin}
                                                                    officeEnd={tuesdayOfficeEnd}
                                                                    setOfficeEnd={setTuesdayOfficeEnd}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} lg={3}>
                                                            <Form.Group className="mb-3 office-hour wednesday" controlId="officeHourWednesday">
                                                                <Form.Label><b>Quarta-feira</b></Form.Label>
                                                                <IntervalTime
                                                                    officeBegin={wednesdayOfficeBegin}
                                                                    setOfficeBegin={setWednesdayOfficeBegin}
                                                                    officeEnd={wednesdayOfficeEnd}
                                                                    setOfficeEnd={setWednesdayOfficeEnd}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} lg={3}>
                                                            <Form.Group className="mb-3 office-hour thursday" controlId="officeHourThursday">
                                                                <Form.Label><b>Quinta-feira</b></Form.Label>
                                                                <IntervalTime
                                                                    officeBegin={thursdayOfficeBegin}
                                                                    setOfficeBegin={setThursdayOfficeBegin}
                                                                    officeEnd={thursdayOfficeEnd}
                                                                    setOfficeEnd={setThursdayOfficeEnd}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} lg={3}>
                                                            <Form.Group className="mb-3 office-hour friday" controlId="officeHourFriday">
                                                                <Form.Label><b>Sexta-feira</b></Form.Label>
                                                                <IntervalTime
                                                                    officeBegin={fridayOfficeBegin}
                                                                    setOfficeBegin={setFridayOfficeBegin}
                                                                    officeEnd={fridayOfficeEnd}
                                                                    setOfficeEnd={setFridayOfficeEnd}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} lg={3}>
                                                            <Form.Group className="mb-3 office-hour saturday" controlId="officeHourSaturday">
                                                                <Form.Label><b>Sábado</b></Form.Label>
                                                                <IntervalTime
                                                                    officeBegin={saturdayOfficeBegin}
                                                                    setOfficeBegin={setSaturdayOfficeBegin}
                                                                    officeEnd={saturdayOfficeEnd}
                                                                    setOfficeEnd={setSaturdayOfficeEnd}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>Intervalo</Card.Header>
                                        <Card.Body>
                                            <Modal
                                                config={ config.interval.include }
                                                content={ <IntervalIncludeContent /> }
                                            />
                                            {
                                                isLoadingInterval ? <div><Spinner animation="border" /></div> :
                                                <Table
                                                    data={ breaks }
                                                    config={ config.interval }
                                                    Actions={ IntervalTableActions }
                                                />
                                            }
                                        </Card.Body>
                                    </Card>
                                </WrapperAdminSettingOfficeHour>
                            </Tab>
                            <Tab eventKey="payment" title="Pagamento">
                                <WrapperAdminSettingPayment>
                                    <Row>
                                        <Col>
                                            <Modal
                                                config={ config.payment.include }
                                                content={ <PaymentIncludeContent /> }
                                            />
                                            {
                                                isLoadingPayment ? <div><Spinner animation="border" /></div> :
                                                <Table
                                                    data={ payments }
                                                    config={ config.payment }
                                                    Actions={ PaymentTableActions }
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </WrapperAdminSettingPayment>
                            </Tab>
                        </Tabs>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={save} disabled={thereAreChanges || !states.length}>Salvar</Button>
                    <Button
                        className="cancel"
                        onClick={cancel}
                        disabled={thereAreChanges || !states.length}
                    >Cancelar</Button>
                </Card.Footer>
            </Card>
        </WrapperAdminSetting>
    );
}