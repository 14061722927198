import React, { useState, useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    useParams,
    Form
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import LayoutDefault from '../Components/LayoutDefault';
import Home from "./Home";
import Scheduling from './Scheduling';
import Service from './Service';
import AdminAppointmentCalendar from './Admin/AdminAppointmentCalendar';
import AdminService from './Admin/AdminService';
import AdminSetting from './Admin/AdminSetting';
import Loading from './Loading';
import styled from 'styled-components';
import { jwtDecode } from "jwt-decode";
import moment from 'moment';
import { http } from '../services';
import store from '../store';
import utils from '../utils';
import LayoutAdmin from '../Components/LayoutAdmin';
import Login from './Login';
import config from '../config';
import AdminClient from './Admin/AdminClient';
import AdminSchedule from './Admin/AdminSchedule';
import AdminUser from './Admin/AdminUser';

const WrapperPreConfig = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

function PreConfig({setShowPreConfig}){

    const dispatch = useDispatch();
    const { token, user } = useSelector(state => state.accountState);

    useEffect(()=>{
        let promises = [];

        promises.push(new Promise((resolve)=>{
            http.config.findAll()
                .then(result=>{
                    dispatch({
                        type: "LOAD_CONFIG",
                        payload: result.data
                    });
                })
                .finally(()=>resolve(true));
        }));

        promises.push(new Promise((resolve)=>{
            if(token){
                let diff = utils.timeLeft(token);
                if(diff <= 60){
                    if(store.getState().accountState.type === "client"){
                        http.client.refresh()
                            .finally(()=>resolve(true));
                    } else {
                        http.user.refresh()
                            .finally(()=>resolve(true));
                    }
                } else {
                    resolve(true)
                }
            } else {
                resolve(true);
            }
        }));

        let username = null;
        const needLogged = Object.entries(config.pages).map(([key, arr]) => arr).reduce((acc, current)=>{
            return [...acc, ...current];
        }, [{url: "/login"}]).find(p => {
            const regex = new RegExp(`^${p.url}$`.replace(/(\:.+)\/?/g, ".+"));
            return regex.test(window.location.pathname);
        });
        
        if(!needLogged){
            username = window.location.pathname.match(/\/([a-z,A-Z,0-9,_,-]+)\/?/)[1];
        }

        if(username){
            promises.push(new Promise((resolve)=>{
                http.account.findPublic(username)
                    .then(result=>{
                        document.title = result.data.name;
                        let link = document.querySelector("link[rel~='icon']");
                        if (!link) {
                            link = document.createElement('link');
                            link.rel = 'icon';
                            document.head.appendChild(link);
                        }
                        link.href = `${config.host}/static-per-account/${result.data.id}/favicon.ico`;
                        dispatch({
                            type: "SET_ACCOUNT_CLIENT",
                            payload: result.data
                        })
                    })
                    .finally(()=>resolve(true));
            }));
        } else {
            if(user?.Account){
                promises.push(new Promise(resolve=>{
                    dispatch({
                        type: "SET_USERNAME",
                        payload: user.Account.username
                    });
                    setTimeout(()=>{
                        resolve(true);
                    }, 100);
                }));
            }
        }

        Promise.all(promises).finally(()=>setShowPreConfig(false));
    }, []);

    return (
        <WrapperPreConfig>
            <Loading />
        </WrapperPreConfig>
    );
}

export default function Pages(){

    const loading = useSelector(state=>state.pageState.loading);
    const [showPreConfig, setShowPreConfig] = useState(true);

    return (
        showPreConfig ? (
            <PreConfig showPreConfig={showPreConfig} setShowPreConfig={setShowPreConfig} />
        ) : (
            !loading ? <BrowserRouter>
                <Routes>
                    {/* <Route path="/" element={<Loading />} />
                    <Route path="/login" element={<Login />} /> */}
                    <Route path="/:username" element={<LayoutDefault><Home /></LayoutDefault>} />
                    <Route path="/:username/agendamentos" element={<LayoutDefault><Scheduling /></LayoutDefault>} />
                    <Route path="/:username/servicos" element={<LayoutDefault><Service /></LayoutDefault>} />
                    <Route path="/login" element={<LayoutDefault><Login /></LayoutDefault>} />
                    <Route path="admin" element={<LayoutDefault><LayoutAdmin /></LayoutDefault>}>
                        <Route path="agenda/:clientId" element={<AdminSchedule />} />
                        <Route path="agenda" element={<AdminAppointmentCalendar />} />
                        <Route path="clientes" element={<AdminClient />} />
                        <Route path="servicos" element={<AdminService />} />
                        <Route path="usuarios" element={<AdminUser />} />
                        <Route path="configuracao" element={<AdminSetting />} />
                    </Route>
                </Routes>
            </BrowserRouter> : <Loading />
        )
    );
}