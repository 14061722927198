import styled from "styled-components";

export const WrapperLogin = styled.div`
    background-color: white;
    padding: 1.5rem;
    border: 1px solid rgba(229,231,235,1);
    border-radius: 5px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 280px;

    h3 {
        font-size: 1.125rem;
        text-align: center;
    }

    p {
        text-align: center;
    }
`;

export default WrapperLogin;