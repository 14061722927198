import React, { useEffect, useState } from "react";
import WrapperCaptureEmailBirthGender from "./style";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import moment from "moment";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function CaptureEmailBirthGender({name, setName, email, setEmail, dateOfBirth, setDateOfBirth, gender, setGender}){

    const dispatch = useDispatch();
    const user = useSelector(state => state.accountState.user);

    return (
        <WrapperCaptureEmailBirthGender>
            <p className="description"><b>Editar informações</b></p>
            <TextField
                disabled
                value={(()=>{
                    let r = user.contact.replace(/^55/, "").replace(/\D/g, "");
                    r = r.replace(/^0/, "");
                    if(r.length === 0) {
                        r = r;
                    } else if (r.length > 10) {
                        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
                    } else if (r.length > 6) {
                        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
                    } else if (r.length > 2) {
                        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
                    } else {
                        r = r.replace(/^(\d*)/, "($1");
                    }

                    return r;
                })()}
                onChange={e => {
                    let r = e.target.value.replace(/\D/g, "");
                    r = r.replace(/^0/, "");
                    if(r.length === 0) {
                        r = r;
                    } else if (r.length > 10) {
                        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
                    } else if (r.length > 6) {
                        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
                    } else if (r.length > 2) {
                        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
                    } else {
                        r = r.replace(/^(\d*)/, "($1");
                    }
                    dispatch({
                        type: 'SET_USER',
                        payload: Object.assign({}, user, {
                            contact: r
                        })
                    });
                }}
                label="Telefone"
                placeholder="(00) 90000-0000"
            />
            <TextField
                value={name}
                onChange={e => {
                    setName(e.target.value);
                }}
                label="Seu nome *"
                placeholder="Informe seu nome e sobrenome"
            />
            <TextField
                className="input-email"
                value={email}
                onChange={e => {
                    setEmail(e.target.value);
                }}
                label="E-mail"
                placeholder="Informe o seu e-mail"
            />
            <LocalizationProvider
                adapterLocale="pt-br"
                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                dateAdapter={AdapterDayjs}
            >
                <DemoContainer components={['DatePicker']}>
                    <DatePicker timezone="America/Sao_Paulo" format="DD/MM/YYYY" value={dateOfBirth} onChange={v=>{
                        setDateOfBirth(v);
                    }} label="Data de nascimento" />
                </DemoContainer>
            </LocalizationProvider>
            <FormControl className="gender" fullWidth>
                <InputLabel id="demo-simple-select-label">Gênero</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={gender}
                    label="Gênero"
                    onChange={e => setGender(e.target.value)}
                >
                    <MenuItem value="Prefiro não informar">Prefiro não informar</MenuItem>
                    <MenuItem value="Feminino">Feminino</MenuItem>
                    <MenuItem value={"Masculino"}>Masculino</MenuItem>
                    <MenuItem value={"Não binário"}>Não binário</MenuItem>
                    <MenuItem value={"Outro"}>Outro</MenuItem>
                </Select>
            </FormControl>
        </WrapperCaptureEmailBirthGender>
    );
}