import styled from 'styled-components';

export const WrapperHome = styled.div`
    .list-services {
        @media (min-width: 640px) {
            margin-top: 7rem;
        }
    }

`;

export default WrapperHome;