import React, { useEffect, useState } from "react";
import WrapperCapturePassword from "./style";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

export default function CapturePassword({ password, setPassword, repeatPassword, setRepeatPassword }){

    const dispatch = useDispatch();
    const user = useSelector(state => state.accountState.user);
    const [name, setName] = useState(user.name);

    useEffect(()=>{
        dispatch({
            type: 'SET_USER',
            payload: Object.assign({}, user, {
                name
            })
        });
    }, [name]);

    return (
        <WrapperCapturePassword>
            <p className="description"><b>Faltam algumas informações</b></p>
            <p className="reason">Você só precisa preencher estes dados uma vez</p>
            <TextField
                value={ user.contact }
                label="Telefone *"
                placeholder="(00) 90000-0000"
                disabled
            />
            <TextField
                value={ name }
                onChange={e => setName(e.target.value)}
                label="Seu nome *"
                placeholder="Informe seu nome e sobrenome"
            />
            <p className="reason2">Crie uma senha. Será usada para garantir que só você terá acesso a suas informações e benefícios</p>
            <TextField
                type="password"
                value={ password }
                onChange={e => setPassword(e.target.value)}
                label="Senha *"
                placeholder="Informe sua senha"
            />
            <TextField
                type="password"
                value={ repeatPassword }
                onChange={e => setRepeatPassword(e.target.value)}
                label="Confirmar Senha *"
                placeholder="Confirme sua senha"
            />
        </WrapperCapturePassword>
    );
}