let INITIAL_STATE = {
    username: null,
    type: null,
    isLogged: false,
    features: null,
    nextSendOTPAllowed: null,
    permissionsAccount: null,
    user: {
        name: "",
        contact: "",
        email: "",
        dateOfBirth: "",
        gender: ""
    },
    socket: null,
    token: null,
    refreshToken: null,
    fnSetInterval: null,
    isOnline: false
}

const accountReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOGIN':
            return { ...state,
                type: payload.type,
                isLogged: true,
                username: (payload.client || payload.user).Account.username,
                user: payload.client || payload.user,
                token: payload.token,
                refreshToken: payload.refreshToken
            };
        case 'SET_NEXT_SEND_OTP_ALLOWED':
            return { ...state,
                nextSendOTPAllowed: payload
            };
        case 'SET_USERNAME':
            return { ...state,
                username: payload
            };
        case 'SET_USER':
            return { ...state,
                user: payload
            };
        case 'SET_INTERVAL_REFRESH_TOKEN':
            return { ...state,
                setInterval: payload.fnSetInterval
            };
        case 'REFRESH_TOKEN':
            const reduxLocalstorageSimpleAccountState = JSON.parse(localStorage.getItem('redux_localstorage_simple_accountState'));
            reduxLocalstorageSimpleAccountState.refreshToken = payload.refreshToken;
            reduxLocalstorageSimpleAccountState.token = payload.token;
            reduxLocalstorageSimpleAccountState.type = payload.type;
            //console.log('reduxLocalstorageSimpleAccountState', JSON.stringify(reduxLocalstorageSimpleAccountState));
            localStorage.setItem('redux_localstorage_simple_accountState', JSON.stringify(reduxLocalstorageSimpleAccountState));
            return { ...state,
                type: payload.type,
                token: payload.token,
                refreshToken: payload.refreshToken
            };
        case 'SET_ACCOUNT_CLIENT':
            return { ...state,
                accountClient: payload,
                username: payload.username
            };
        case 'UPDATE_ACCOUNT':
            return { ...state,
                user: payload
            };
        case 'SET_SOCKET':
            return { ...state,
                socket: payload
            };
        case 'LOGOUT_CLIENT':
            return { ...state,
                type: null,
                user: {
                    name: "",
                    contact: "",
                    email: "",
                    dateOfBirth: "",
                    gender: ""
                },
                isLogged: false,
                token: null,
                refreshToken: null
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default accountReducer;