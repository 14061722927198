import React, { useEffect, useState } from "react";
import WrapperModalEditProfile, { WrapperModalEditProfileContent } from "./style";
import { Button, Modal, Spinner } from "react-bootstrap";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import WrapperModalHeaderButton from "../../styles/modalHeaderButton";
import CapturePassword from "./CapturePassword";
import CaptureEmailBirthGender from "./CaptureEmailBirthGender";
import WrapperButtonSubmit from "../../styles/buttonSubmit";
import "./style.css";
import { http } from "../../services";
import utils from "../../utils";
import moment from "moment-timezone";
import dayjs from "dayjs";

export default function ModalEditProfile(){

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const openModalEditProfile = useSelector(state => state.pageState.openModalEditProfile);
    const { user } = useSelector(state => state.accountState);
    const [passCurrent, setPassCurrent] = useState(user?.hasPassword ? 1 : 0);
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [name, setName] = useState(user?.name || "");
    const [email, setEmail] = useState(user?.email || "");
    const [dateOfBirth, setDateOfBirth] = useState(user?.dateOfBirth ? moment(user?.dateOfBirth, "YYYY-MM-DD") : null);
    const [gender, setGender] = useState(user?.gender || "");
    const [contact, setContact] = useState(user?.contact || "");

    const handleClose = () => dispatch({
        type: "SET_OPEN_MODAL_EDIT_PROFILE",
        payload: false
    });

    useEffect(()=>{
        setName(user?.name);
        setEmail(user?.email);
        setDateOfBirth(user?.dateOfBirth ? moment(user?.dateOfBirth, "YYYY-MM-DD") : null);
        setGender(user?.gender);
        setContact(user?.contact);
    }, [user]);

    useEffect(()=>{
        setPassCurrent(user?.hasPassword ? 1 : 0);
    }, [openModalEditProfile]);

    return (
        <WrapperModalEditProfile>
            <Modal onHide={handleClose} show={openModalEditProfile} className="modal-edit-profile" centered>
                <WrapperModalEditProfileContent>
                    <Modal.Header>
                        <WrapperModalHeaderButton onClick={handleClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </WrapperModalHeaderButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            ({
                                0: <>
                                    <CapturePassword
                                        password={password}
                                        setPassword={setPassword}
                                        repeatPassword={repeatPassword}
                                        setRepeatPassword={setRepeatPassword}
                                        email={email}
                                        setEmail={setEmail}
                                    />
                                    <WrapperButtonSubmit disabled={isLoading || !password} onClick={()=>{
                                        if(password !== repeatPassword) return utils.createNotification({
                                            type: 'error',
                                            title: 'Falha ao criar senha',
                                            message: "Senha incorreta!",
                                            timing: 15000
                                        });
                                        setIsLoading(true);
                                        http.client.setData({password})
                                            .then(result=>{
                                                dispatch({
                                                    type: 'SET_USER',
                                                    payload: result.data.data
                                                });
                                                setPassCurrent(`${Number(passCurrent) + 1}`);
                                            })
                                            .catch(error=>utils.createNotification({
                                                type: 'error',
                                                title: 'Falha ao criar senha',
                                                message: error.response.data,
                                                timing: 15000
                                            }))
                                            .finally(()=>setIsLoading(false));
                                    }} className="submit">{ isLoading ? <Spinner size="sm" border="animation" /> : "Confirmar" }</WrapperButtonSubmit>
                                </>,
                                1: <>
                                    <CaptureEmailBirthGender
                                        name={name}
                                        setName={setName}
                                        dateOfBirth={dateOfBirth}
                                        setDateOfBirth={setDateOfBirth}
                                        email={email}
                                        setEmail={setEmail}
                                        gender={gender}
                                        setGender={setGender}
                                    />
                                    <WrapperButtonSubmit disabled={isLoading} onClick={()=>{
                                        setIsLoading(true);
                                        http.client.setData({contact, name, email, dateOfBirth: dateOfBirth ? moment((dateOfBirth.$d ? dateOfBirth.$d : dateOfBirth)).format("YYYY-MM-DD") : null, gender})
                                            .then(result=>{
                                                if(result.data.hasOTP){
                                                    alert("hasOTP");
                                                }
                                                setPassCurrent(user?.hasPassword ? 1 : 0);
                                                dispatch({
                                                    type: 'SET_USER',
                                                    payload: result.data.data
                                                });
                                                dispatch({
                                                    type: "SET_OPEN_MODAL_EDIT_PROFILE",
                                                    payload: false
                                                });
                                            })
                                            .catch(error=>utils.createNotification({
                                                type: 'error',
                                                title: 'Falha ao atualizar informações',
                                                message: error.response.data,
                                                timing: 15000
                                            }))
                                            .finally(()=>setIsLoading(false));
                                    }} className="submit">{ isLoading ? <Spinner size="sm" border="animation" /> : "Atualizar cadastro" }</WrapperButtonSubmit>
                                </>
                            })[passCurrent]
                        }
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </WrapperModalEditProfileContent>
            </Modal>
        </WrapperModalEditProfile>
    );
}