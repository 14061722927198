import styled from 'styled-components';

export const WrapperModalMoreInformations = styled.div`
    & {
        display: inline-block;
    }

    .more-information {
        font-weight: 500;

        &:hover {
            cursor: pointer;
        }
    }
`;

export const WrapperModalMoreInformationsContent = styled.div`
    height: 100%;

    .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: var(--color-primary);
    }

    .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
        background-color: var(--color-primary);
    }

    @media (max-width: 575px) {
        .modal-more-informations .modal-dialog {
            display: none;
        }
    }
`;

export default WrapperModalMoreInformations;