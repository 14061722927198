import { NotificationManager } from 'react-notifications';
import moment from 'moment-timezone';
import { validate as uuidValidate } from 'uuid';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faDollar } from '@fortawesome/free-solid-svg-icons';
import { faPix } from '@fortawesome/free-brands-svg-icons';

export default {
  getTimeBeginAndTimeEndOfAccount(account){
    let times = [
      "sundayOffice", "mondayOffice", "tuesdayOffice", "wednesdayOffice",
      "thursdayOffice", "fridayOffice", "saturdayOffice"
    ];

    return times.reduce((acc, current)=>{
      if(!(account[`${current}Begin`] || account[`${current}End`])){
        return Object.assign({}, acc, {
          nonWorkingDays: [...acc.nonWorkingDays, this.dayOfWeekNameToNumber(current.replace("Office", ""))]
        });
      }

      return {
        timeBegin: !account[`${current}Begin`] || acc["timeBegin"] && acc["timeBegin"] < account[`${current}Begin`] ? acc["timeBegin"] : account[`${current}Begin`],
        timeEnd: !account[`${current}End`] || acc["timeEnd"] && acc["timeEnd"] > account[`${current}End`] ? acc["timeEnd"] : account[`${current}End`],
        nonWorkingDays: acc.nonWorkingDays
      }
    }, {
      timeBegin: null,
      timeEnd: null,
      nonWorkingDays: []
    });
  },
  translateSchedulingStatus(status){
    return ({
      canceled: "Cancelado",
      scheduled: "Agendado",
      confirmed: "Confirmado",
      done: "Realizado"
    })[status];
  },
  getIcon(icon, title){
    return ({
      "fontawesome:faDollar": <FontAwesomeIcon title={title} icon={faDollar} />,
      "fontawesome:faPix": <FontAwesomeIcon title={title} icon={faPix} />,
      "fontawesome:faCreditCard": <FontAwesomeIcon title={title} icon={faCreditCard} />
    }[icon]);
  },
  timeLeft(token){
    let exp = (jwtDecode(token)).exp;
    let now = Math.ceil(moment.now() / 1000);
    let diff = exp - now;

    return diff;
  },
  formatTypeCep(type){
    return {
      city: 'Cidade',
      district: 'Bairro'
    }[type] || type;
  },
  formatPrice(price){
    let newPrice = `R$${Number(price).toFixed(2).toString().replace('.', ',')}`;
    return newPrice;
  },
  formatContact(contact){
    let r = contact.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if(r.length === 0) {
      r = r;
    } else if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 6) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }

    return r;
  },
  dayOfWeekNameToNumber(name){
    return ({
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6
    })[name]
  },
  formatDuration(milliseconds){
    const duration = moment.duration(milliseconds);

    return duration._data.hours ? `${duration._data.hours}h${duration._data.minutes ? duration._data.minutes : ""}` : `${duration._data.minutes} min`;
  },
  formatDayOfWeek(day){
    return ({
      0: "Domingo",
      1: "Segunda-feira",
      2: "Terça-feira",
      3: "Quarta-feira",
      4: "Quinta-feira",
      5: "Sexta-feira",
      6: "Sábado"
    }[day]);
  },
  getMimeTypeFromArrayBuffer(arrayBuffer) {
    
    const uint8arr = new Uint8Array(arrayBuffer)
    
    const len = 4
    
    if (uint8arr.length >= len) {
      
      let signatureArr = new Array(len)
      for (let i = 0; i < len; i++)
        signatureArr[i] = (new Uint8Array(arrayBuffer))[i].toString(16)
      const signature = signatureArr.join('').toUpperCase()
  
      switch (signature) {
        case '1A45DFA3':
          return 'audio/webm';
        case '89504E47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case '25504446':
          return 'application/pdf';
        case 'FFD8FFDB':
        case 'FFD8FFE0':
          return 'image/jpeg';
        case '504B0304':
          return 'application/zip';
        default:
          return null
      }
    }
    return null
  },
  isUUID(data){
    return uuidValidate(data);
  },
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  onlyNumbers(str) {
    return str.replace(/\D/g, '');
  },
  isEmail(str){
    const exp = /\S+@\S+\.\S+/;
    return exp.test(str);
  },
  isDate(date){
    if(typeof date != 'string') return false;
    let datePart = date.substring(0, 10);
    let datePartArr = datePart.split('-');
    if(datePartArr.length != 3) return false;
    if(datePartArr.find(d=>isNaN(Number(d)))) return false;
    return true;
  },
  formatTypeOfAccount(type){
    return {
      support: "Suporte",
      admin: "Admin"
    }[type] || type;
  },
  convertNumberToLetter(number){
    return ({
      0: "a",
      1: "b",
      2: "c",
      3: "d",
      4: "e",
      5: "f",
      6: "g",
      7: "h",
      8: "i",
      9: "j",
      10: "k",
      11: "l",
      12: "m",
      13: "n",
      14: "o",
      15: "p",
      16: "q",
      17: "r",
      18: "s",
      19: "t",
      20: "u",
      21: "v",
      22: "x",
      23: "w",
      24: "y",
      25: "z"
    })[number] || number;
  },
  formatDateToListLeads(timestamp, displayHoursCaseToday = false){
    switch(moment.unix(timestamp).format("DD/MM/YYYY")){
      case moment().format("DD/MM/YYYY"):
        return displayHoursCaseToday ? moment.unix(timestamp).format("HH:mm") : "Hoje";
      case moment().subtract(1, "days").format("DD/MM/YYYY"):
        return "Ontem"
      default:
        return timestamp;
    }
  },
  formatBoolean(value){
    return value ? 'Sim' : 'Não';
  },
  formatPrice(price){
    let newPrice = `R$${Number(price).toFixed(2).toString().replace('.', ',')}`;
    return newPrice;
  },
  formatDate(date){
    return date ? moment.tz(date, "America/Sao_Paulo").format('DD/MM/YYYY') : "";
  },
  formatDateTime(date){
    return date ? moment.tz(date, "America/Sao_Paulo").format('DD/MM/YYYY HH:mm') : "";
  },
  getType(data){
    if(this.isUUID(data)) return 'uuid';
    if(this.isEmail(data)) return 'email';
    if(this.isDate(data)) return 'date';
    if(typeof data === 'boolean') return 'boolean';
    return 'string';
  },
  formatData(type, data){
    if(type === 'date') return this.formatDate(data);
    if(type === 'boolean') return data ? 'Sim' : 'Não';
    if(type === 'string') return this.capitalize(data);
    return data;
  },
  formatObj(obj){
    return Object
      .entries(obj)
      .reduce((acc, current)=>{
        let newObj = { ...acc };
        newObj[current[0]] = this.formatData(this.getType(current[1]), current[1]);
        return newObj;
      }, {});
  },
  formatNotification(text){
    return text.substring(0, 35);
  },
  translate(text){
    return ({
      sunday: 'domingo',
      monday: 'segunda-feira',
      tuesday: 'terça-feira',
      wednesday: 'quarta-feira',
      thursday: 'quinta-feira',
      friday: 'sexta-feira',
      saturday: 'sábado',
      email: 'e-mail',
      integer: 'inteiro',
      number: 'número',
      whatsapp: 'WhatsApp',
      web: 'Web'
    }[text] || text);
  },
  createNotification({type, title, message, timing, callback}){
    switch(type) {
      case 'info':
        NotificationManager.info(message, title, timing || 5000);
        break;
      case 'success':
        NotificationManager.success(message, title, timing || 5000);
        break;
      case 'warning':
        NotificationManager.warning(message, title, timing || 5000);
        break;
      case 'error':
        NotificationManager.error(message, title, timing || 5000, callback);
        break;
    }
  },
  queryCreate({filters, activePage}){
    let query = Object.entries(filters).reduce((acc, current)=>{
      let value = current[0] === 'activePage' && activePage ? activePage : current[1];
      if(!isNaN(value)) value = `${value}`;
      let addStr = value.length > 0 && current[0] != 'totalPages' ? `&${current[0]}=${value}` : '';
      return `${acc}${addStr}`;
    }, '');
    return query;
  }
}