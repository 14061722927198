import React, { useEffect, useState } from "react";
import WrapperAdminService from "./style";
import { Card, Col, Container, Form, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../../utils";
import { http } from "../../../services";
import store from "../../../store";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import Table from "../../../Components/Table";
import Modal from '../../../Components/Modal';
import { css } from "styled-components";
import IntervalTime from "../../../Components/IntervalTime";

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'name', translate: 'Nome' },
        { name: 'description', translate: 'Descrição' },
        { name: 'active', translate: 'Ativo', type: 'boolean' },
        { name: 'price', translate: 'Valor', type: 'currency' },
        { name: 'showPrice', translate: 'Mostrar valor', type: 'boolean' },
        { name: 'duration', translate: 'Duração', type: 'duration' }
    ],
    include: {
        cta: "Incluir",
        title: 'Incluir serviço',
        ctaFn: ()=>{
            http.service.create(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_SERVICE',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Serviço criado com sucesso'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao criar serviço',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar serviço',
        size: "lg",
        styledCustomContent: css`
            .modal-body {
                max-height: 69.5vh;
                overflow-y: auto;
            }
        `,
        icon: faPen,
        ctaFn: ({id})=>{
            http.service.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_SERVICE',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do serviço ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Falha ao editar serviço ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Deletar",
        title: 'Deletar serviço',
        ctaFn: ({ id })=>{
            http.service.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_SERVICE',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Serviço deletado',
                        message: `O serviço ${id} foi deletado!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Falha ao deletar serviço ${id}`,
                    message: error.response.data
                }));
        },
        icon: faTrash,
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function IncludeContent(){

    const dispatch = useDispatch();
    const [active, setActive] = useState(true);
    const [showPrice, setShowPrice] = useState(true);
    const [duration, setDuration] = useState(1);
    const [price, setPrice] = useState("0");

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                showPrice,
                active,
                duration,
                price: `${price}`.replace(",", ".")
            }
        });
    }, [showPrice, active, duration, price]);

    return (
        <Form>
            <Container fluid>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                type="text"
                                placeholder="Nome"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        name: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                as="textarea"
                                rows={3}
                                type="text"
                                placeholder="Descrição"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        description: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formActive">
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id={`formActive`}
                                label={`Ativo`}
                                checked={active}
                                onChange={e=>setActive(e.target.checked)}
                            />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formPrice">
                                    <Form.Label>Duração {"(min)"}</Form.Label>
                                    <Form.Control
                                        value={duration}
                                        className="shadow-none"
                                        type="number"
                                        placeholder="Duração"
                                        onChange={e=>{
                                            if(isNaN(e.target.value)) return;
                                            if(e.target.value <= 0) return;
                                            if(!Number.isInteger(Number(e.target.value))) return;

                                            setDuration(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formPrice">
                                    <Form.Label>Valor {"(R$)"}</Form.Label>
                                    <Form.Control
                                        className="shadow-none"
                                        type="text"
                                        placeholder="Valor"
                                        value={ price }
                                        onChange={e=>{
                                            if(!e.target.value && e.target.value !== "0") return setPrice(0);
                                            if(e.target.value < 0) return;
                                            if(e.target.value.indexOf(".") >= 0) return;
                                            if(e.target.value.length > 1 && e.target.value[0] === "0" && e.target.value[1] !== ",") return setPrice(e.target.value.substring(1));
                                            if(e.target.value.match(/,/g)?.length > 1) return;

                                            setPrice(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="formShowPrice">
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id={`formShowPrice`}
                                checked={showPrice}
                                label={`Mostrar valor`}
                                onChange={e=>setShowPrice(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function UpdateContent(props){

    const dispatch = useDispatch();

    const [name, setName] = useState(props.name);
    const [description, setDescription] = useState(props.description);
    const [active, setActive] = useState(props.active);
    const [duration, setDuration] = useState(props.duration);
    const [price, setPrice] = useState(props.price);
    const [showPrice, setShowPrice] = useState(props.showPrice);
    const [definitionOfTimes, setDefinitionOfTimes] = useState(props.definitionOfTimes);
    const [sundayOfficeBegin, setSundayOfficeBegin] = useState(props.sundayOfficeBegin);
    const [sundayOfficeEnd, setSundayOfficeEnd] = useState(props.sundayOfficeEnd);
    const [mondayOfficeBegin, setMondayOfficeBegin] = useState(props.mondayOfficeBegin);
    const [mondayOfficeEnd, setMondayOfficeEnd] = useState(props.mondayOfficeEnd);
    const [tuesdayOfficeBegin, setTuesdayOfficeBegin] = useState(props.tuesdayOfficeBegin);
    const [tuesdayOfficeEnd, setTuesdayOfficeEnd] = useState(props.tuesdayOfficeEnd);
    const [wednesdayOfficeBegin, setWednesdayOfficeBegin] = useState(props.wednesdayOfficeBegin);
    const [wednesdayOfficeEnd, setWednesdayOfficeEnd] = useState(props.wednesdayOfficeEnd);
    const [thursdayOfficeBegin, setThursdayOfficeBegin] = useState(props.thursdayOfficeBegin);
    const [thursdayOfficeEnd, setThursdayOfficeEnd] = useState(props.thursdayOfficeEnd);
    const [fridayOfficeBegin, setFridayOfficeBegin] = useState(props.fridayOfficeBegin);
    const [fridayOfficeEnd, setFridayOfficeEnd] = useState(props.fridayOfficeEnd);
    const [saturdayOfficeBegin, setSaturdayOfficeBegin] = useState(props.saturdayOfficeBegin);
    const [saturdayOfficeEnd, setSaturdayOfficeEnd] = useState(props.saturdayOfficeEnd);
    const [vacanciesByTime, setVacanciesByTime] = useState(props.vacanciesByTime);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, description, active, duration, price: `${price}`.replace(",", "."),
                showPrice, definitionOfTimes, sundayOfficeBegin,sundayOfficeEnd,
                mondayOfficeBegin, mondayOfficeEnd, tuesdayOfficeBegin, tuesdayOfficeEnd,
                wednesdayOfficeBegin, wednesdayOfficeEnd, thursdayOfficeBegin, thursdayOfficeEnd,
                fridayOfficeBegin, fridayOfficeEnd, saturdayOfficeBegin, saturdayOfficeEnd, vacanciesByTime
            }
        });
    }, [name, description, active, duration, price, showPrice, definitionOfTimes, sundayOfficeBegin, sundayOfficeEnd,
        mondayOfficeBegin, mondayOfficeEnd, tuesdayOfficeBegin, tuesdayOfficeEnd,
        wednesdayOfficeBegin, wednesdayOfficeEnd, thursdayOfficeBegin, thursdayOfficeEnd,
        fridayOfficeBegin, fridayOfficeEnd, saturdayOfficeBegin, saturdayOfficeEnd, vacanciesByTime]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Tabs
                            defaultActiveKey="settings"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="settings" title="Configurações">
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        className="shadow-none"
                                        value={ name }
                                        type="text"
                                        placeholder="Nome"
                                        onChange={e=>setName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formDescription">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        className="shadow-none"
                                        value={ description }
                                        as="textarea"
                                        rows={3}
                                        type="text"
                                        placeholder="Descrição"
                                        onChange={e=>setDescription(e.target.value)}
                                    />
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formPrice">
                                    <Form.Label>Definição dos horários</Form.Label>
                                    <Form.Select value={definitionOfTimes} onChange={e=>setDefinitionOfTimes(e.target.value)} className="shadow-none">
                                        <option value="OfficeHoursOfCompany">Horário de funcionamento da empresa</option>
                                        <option value="SpecificTimes">Horários específicos</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="formActive">
                                    <Form.Check // prettier-ignore
                                        checked={active}
                                        type={"checkbox"}
                                        id={`formActive`}
                                        label={`Ativo`}
                                        onChange={e=>setActive(e.target.checked)}
                                    />
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formPrice">
                                            <Form.Label>Duração {"(min)"}</Form.Label>
                                            <Form.Control
                                                value={duration}
                                                className="shadow-none"
                                                type="number"
                                                placeholder="Duração"
                                                onChange={e=>{
                                                    if(isNaN(e.target.value)) return;
                                                    if(e.target.value <= 0) return;
                                                    if(!Number.isInteger(Number(e.target.value))) return;

                                                    setDuration(Number(e.target.value));
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formPrice">
                                            <Form.Label>Valor {"(R$)"}</Form.Label>
                                            <Form.Control
                                                value={price}
                                                className="shadow-none"
                                                type="text"
                                                placeholder="Valor"
                                                onChange={e=>{
                                                    if(!e.target.value && e.target.value !== "0") return setPrice(0);
                                                    if(e.target.value < 0) return;
                                                    if(e.target.value.indexOf(".") >= 0) return;
                                                    if(e.target.value.length > 1 && e.target.value[0] === "0" && e.target.value[1] !== ",") return setPrice(e.target.value.substring(1));
                                                    if(e.target.value.match(/,/g)?.length > 1) return;

                                                    setPrice(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" controlId="formShowPrice">
                                    <Form.Check // prettier-ignore
                                        checked={ showPrice }
                                        type={"checkbox"}
                                        id={`formShowPrice`}
                                        label={`Mostrar valor`}
                                        onChange={e=>setShowPrice(e.target.checked)}
                                    />
                                </Form.Group>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formPrice">
                                            <Form.Label>Vagas</Form.Label>
                                            <Form.Control
                                                value={vacanciesByTime}
                                                className="shadow-none"
                                                type="number"
                                                placeholder="Vagas"
                                                onChange={e=>{
                                                    if(isNaN(e.target.value)) return;
                                                    if(e.target.value <= 0) return;
                                                    if(!Number.isInteger(e.target.value)) return;

                                                    setVacanciesByTime(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Tab>
                            {/* <Tab eventKey="hour-specific" title="Horários específicos" disabled={definitionOfTimes !== "SpecificTimes"}>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col md={6} lg={4}>
                                                <Form.Group className="mb-3 office-hour sunday" controlId="officeHourSunday">
                                                    <Form.Label><b>Domingo</b></Form.Label>
                                                    <IntervalTime
                                                        officeBegin={sundayOfficeBegin}
                                                        setOfficeBegin={setSundayOfficeBegin}
                                                        officeEnd={sundayOfficeEnd}
                                                        setOfficeEnd={setSundayOfficeEnd}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={4}>
                                                <Form.Group className="mb-3 office-hour monday" controlId="officeHourMonday">
                                                    <Form.Label><b>Segunda-feira</b></Form.Label>
                                                    <IntervalTime
                                                        officeBegin={mondayOfficeBegin}
                                                        setOfficeBegin={setMondayOfficeBegin}
                                                        officeEnd={mondayOfficeEnd}
                                                        setOfficeEnd={setMondayOfficeEnd}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={4}>
                                                <Form.Group className="mb-3 office-hour tuesday" controlId="officeHourTuesday">
                                                    <Form.Label><b>Terça-feira</b></Form.Label>
                                                    <IntervalTime
                                                        officeBegin={tuesdayOfficeBegin}
                                                        setOfficeBegin={setTuesdayOfficeBegin}
                                                        officeEnd={tuesdayOfficeEnd}
                                                        setOfficeEnd={setTuesdayOfficeEnd}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={4}>
                                                <Form.Group className="mb-3 office-hour wednesday" controlId="officeHourWednesday">
                                                    <Form.Label><b>Quarta-feira</b></Form.Label>
                                                    <IntervalTime
                                                        officeBegin={wednesdayOfficeBegin}
                                                        setOfficeBegin={setWednesdayOfficeBegin}
                                                        officeEnd={wednesdayOfficeEnd}
                                                        setOfficeEnd={setWednesdayOfficeEnd}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={4}>
                                                <Form.Group className="mb-3 office-hour thursday" controlId="officeHourThursday">
                                                    <Form.Label><b>Quinta-feira</b></Form.Label>
                                                    <IntervalTime
                                                        officeBegin={thursdayOfficeBegin}
                                                        setOfficeBegin={setThursdayOfficeBegin}
                                                        officeEnd={thursdayOfficeEnd}
                                                        setOfficeEnd={setThursdayOfficeEnd}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={4}>
                                                <Form.Group className="mb-3 office-hour friday" controlId="officeHourFriday">
                                                    <Form.Label><b>Sexta-feira</b></Form.Label>
                                                    <IntervalTime
                                                        officeBegin={fridayOfficeBegin}
                                                        setOfficeBegin={setFridayOfficeBegin}
                                                        officeEnd={fridayOfficeEnd}
                                                        setOfficeEnd={setFridayOfficeEnd}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={4}>
                                                <Form.Group className="mb-3 office-hour saturday" controlId="officeHourSaturday">
                                                    <Form.Label><b>Sábado</b></Form.Label>
                                                    <IntervalTime
                                                        officeBegin={saturdayOfficeBegin}
                                                        setOfficeBegin={setSaturdayOfficeBegin}
                                                        officeEnd={saturdayOfficeEnd}
                                                        setOfficeEnd={setSaturdayOfficeEnd}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab> */}
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja deletar o serviço {id}?</p>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <RemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function AdminService(){

    const dispatch = useDispatch();
    const { isLogged, type } = useSelector(state=>state.accountState);
    const { services, isLoading } = useSelector(state=>state.serviceState);

    useEffect(()=>{
        if(!(!isLogged || type !== "user")){
            dispatch({
                type: 'IS_LOADING_SERVICE',
                payload: true
            });
    
            http.service.findAll()
                .then(result=>{
                    dispatch({
                        type: 'LOAD_SERVICE',
                        payload: result.data
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Falha ao carregar serviços',
                    message: error.response.data
                }))
                .finally(()=>dispatch({
                    type: 'IS_LOADING_SERVICE',
                    payload: false
                }));
        }
    }, []);

    return (
        <WrapperAdminService>
            <Card>
                <Card.Body>
                    <Modal
                        config={ config.include }
                        content={ <IncludeContent
                        /> }
                    />
                    {
                        isLoading ? <div><Spinner animation="border" /></div> :
                        <Table
                            data={ services }
                            config={ config }
                            Actions={ TableActions }
                        />
                    }
                </Card.Body>
            </Card>
        </WrapperAdminService>
    );
}