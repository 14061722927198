import WrapperHeader, { WrapperHeaderResponsive } from "./style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faChevronDown, faHome, faLocationDot, faPercent, faScissors, faUser } from "@fortawesome/free-solid-svg-icons";
import ModalMoreInformations from "./ModalMoreInformations";
import { Button, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import http from "../../services/http";
import config from "../../config";
import utils from "../../utils";
import moment from "moment";

export default function Header(){

    const { current } = useSelector(state=>state.pageState);
    const [openProfile, setOpenProfile] = useState(false);
    const { username, isLogged, type, accountClient } = useSelector(state=>state.accountState);
    const [disabledBtnLogout, setDisabledBtnLogout] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [messageHour, setMessageHour] = useState("");
    const dispatch = useDispatch();

    const logout = ()=>{
        setDisabledBtnLogout(true);
        http.client.logout()
            .finally(()=>{
                dispatch({
                    type: "LOGOUT_CLIENT"
                });
                setDisabledBtnLogout(false);
            });
    }

    useEffect(()=>{
        let begin = accountClient[`${moment().locale("en").format("dddd").toLowerCase()}OfficeBegin`];
        let end = accountClient[`${moment().locale("en").format("dddd").toLowerCase()}OfficeEnd`];

        if(begin){
            if(moment().format("HH:mm:ss") >= begin && moment().format("HH:mm:ss") < end){
                setIsOpen(true);
                return setMessageHour(`Aberto até às ${moment(end, "HH:mm:ss").format("HH:mm").replace(":00", ":").replace(":", "h")}`);
            }

            if(moment().format("HH:mm:ss") < begin){
                return setMessageHour(`Abrimos às ${moment(begin, "HH:mm:ss").format("HH:mm").replace(":00", ":").replace(":", "h")}`);
            }
        }

        begin = accountClient[`${moment().add(1, "days").locale("en").format("dddd").toLowerCase()}OfficeBegin`];
        end = accountClient[`${moment().add(1, "days").locale("en").format("dddd").toLowerCase()}OfficeEnd`];

        if(begin){
            return setMessageHour(`Abrimos amanhã às ${moment(begin, "HH:mm:ss").format("HH:mm").replace(":00", ":").replace(":", "h")}`);
        }

        for(let d = 2; d < 7; d++){
            let day = moment().add(d, "days").locale("en").format("dddd");
            begin = accountClient[`${day.toLowerCase()}OfficeBegin`];
            
            if(begin){
                setMessageHour(`Abrimos ${utils.formatDayOfWeek(moment().add(d, "days").locale("en").format("d")).toLowerCase()} às ${moment(begin, "HH:mm:ss").format("HH:mm").replace(":00", ":").replace(":", "h")}`);
                break;
            }
        }
    }, []);

    return (
        <>
            <WrapperHeader styles={accountClient.styles} className="header">
                <div className="actions-top">
                    <div className="content-center">
                        <Link to={ `/${username}` }>
                            <Button className={current === `/${username}` ? "active" : ""}>
                                <FontAwesomeIcon icon={ faHome } />
                                <span>Início</span>
                            </Button>
                        </Link>
                        <Link>
                            <Button disabled className={current === `/${username}/promocoes` ? "active" : ""}>
                                <FontAwesomeIcon icon={ faPercent } />
                                <span>Promoções</span>
                            </Button>
                        </Link>
                        <Link to={`/${username}/agendamentos`}>
                            <Button className={current === `/${username}/agendamentos` ? "active" : ""}>
                                <FontAwesomeIcon icon={ faCalendarDays } />
                                <span>Agendamentos</span>
                            </Button>
                        </Link>
                        {
                            isLogged && type === "client" ? (
                                <Button onBlur={()=>setOpenProfile(false)} onClick={()=>setOpenProfile(!openProfile)} className={`profile${openProfile ? " open" : ""}`}>
                                    <FontAwesomeIcon icon={ faUser } />
                                    <span>Minha conta</span>
                                    <FontAwesomeIcon className="tab-icon" icon={ faChevronDown } />
                                    {
                                        openProfile ? (
                                            <ListGroup className="menu-my-account">
                                                <ListGroup.Item onClick={()=>dispatch({
                                                    type: "SET_OPEN_MODAL_EDIT_PROFILE",
                                                    payload: true
                                                })}>Editar perfil</ListGroup.Item>
                                                {/* <ListGroup.Item>Trocar senha</ListGroup.Item> */}
                                                <ListGroup.Item disabled={disabledBtnLogout} onClick={logout}>Sair</ListGroup.Item>
                                            </ListGroup>
                                        ) : null
                                    }
                                </Button>
                            ) : (
                                <Button onClick={()=>dispatch({
                                    type: "SET_OPEN_MODAL_LOGIN",
                                    payload: true
                                })}>
                                    <FontAwesomeIcon icon={ faUser } />
                                    <span>Entrar/Cadastrar</span>
                                </Button>
                            )
                        }
                    </div>
                </div>
                <div className={current !== `/${username}` ? "hidden" : ""}>
                    <div className="only-bg"></div>
                    <div className="banner">
                        <img src={ `${config.host}/static-per-account/${accountClient.id}/banner.png` } />
                    </div>
                    <div className="information">
                        <div className="logo">
                            <img src={ `${config.host}/static-per-account/${accountClient.id}/logo.png` } />
                        </div>
                        <h1>{ accountClient?.name }</h1>
                        <div className="row-address-hours">
                            <div className="address">
                                <FontAwesomeIcon
                                    icon={ faLocationDot }
                                />
                                <span>{[accountClient?.city, accountClient?.uf].join(" - ")}</span>
                                <span className="point">.</span>
                                <ModalMoreInformations />
                            </div>
                            <span className="point">.</span>
                            <div className="status d-flex flex-row-reverse">
                                <p className={`hours ${isOpen ? "open" : "close"}`}><b>{ messageHour }</b></p>
                                {
                                    (()=>{
                                        if(!isOpen){
                                            return (
                                                <>
                                                    {
                                                        messageHour ? <span className="point">.</span> : null
                                                    }
                                                    <p className="is-open"><b>Fechado</b></p>
                                                </>
                                            );
                                        }
                                    })()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperHeader>
        </>
    );
}