import { Navbar } from "react-bootstrap";
import styled from "styled-components";

export const WrapperFilter = styled(Navbar)`

    text-transform: uppercase;

    label {
        color: var(--color-font);
    }

    button svg {
        color: var(--color-light);
    }

    #filter-btn button {
        background-color: var(--color-primary) !important;
        width: 100%;
        border: none;
    }

    @media screen and (min-width: 768px) {
        #filter-btn .form-label {
            width: 100%;
            margin-bottom: 22px;
        }
        #filter-btn button {
            width: auto;
        }
    }
`;

export default WrapperFilter;