import styled from 'styled-components';
import WrapperModalContent from '../../../styles/modalContent';

export const WrapperCaptureName = styled(WrapperModalContent)`
    p {
        text-align: center;
        font-size: 1.125rem;
        margin-bottom: 1.75rem;
    }
`;

export default WrapperCaptureName;