import styled from 'styled-components';

export const WrapperFooter = styled.div`
    background-color: #264653;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2rem;
    padding: 0.75rem 0;
    margin-bottom: 43px;

    text-align: center;

    .copyright {
        display: block;
        margin-bottom: 0.85rem;

        @media (min-width: 800px) {
            display: inline;
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: none;
        color: white;
        display: block;

        @media (min-width: 800px) {
            display: inline;
        }
    }

    @media (min-width: 1200px) {
        flex-direction: row;
        padding: 1.5rem 0;
    }

    @media (min-width: 1025px) {
        margin-bottom: 0;
    }
`;

export default WrapperFooter;