import React, { useEffect, useState } from 'react';
import utils from './../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRotateLeft, faArrowUp, faEnvelope, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import WrapperTable from './style';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';

const formatValues = (type, value)=>({
    duration: utils.formatDuration(value * 60000),
    status_scheduling: utils.translateSchedulingStatus(value),
    contact: ({
        email: <FontAwesomeIcon title='E-mail' icon={faEnvelope} />,
        phone: <FontAwesomeIcon title='Telefone' icon={faPhone} />,
        whatsapp: <FontAwesomeIcon title='WhatsApp' icon={faWhatsapp} />
    }[value]),
    hour: `${value}h`,
    currency: utils.formatPrice(value),
    date: utils.formatDate(value),
    datetime: utils.formatDateTime(value),
    boolean: utils.formatBoolean(value),
    type_cep: utils.formatTypeCep(value),
    day_of_week: utils.formatDayOfWeek(value),
    icon: utils.getIcon(value),
    timeHHmm: moment(value, "HH:mm:ss").format("HH:mm")
})[type] || value;

export default function TableC({ data, newsData, Actions, config, Footer }){

    return (
        data.length || newsData?.length ? (
        <WrapperTable id='table' responsive>
            <thead>
                <tr>
                    {
                        config.columns.map(column=><th>
                            <span className='name'>
                                {
                                    column.translate
                                }
                            </span>
                        </th>)
                    }
                    {
                        Actions ? <th>
                            <span className='name'>
                                Ações
                            </span>
                        </th> : false
                    }
                </tr>
            </thead>
            <tbody>
                {
                    data.map(row=>(<tr>
                            {
                                config.columns.map(column=>{
                                    let value = formatValues(column.type, row[column.name]);
                                    if(column.format?.length) value = column.format.reduce((acc, current)=>{
                                        return utils[current](acc);
                                    }, value);
                                    return <td>{ value }</td>;
                                })
                            }
                            {Actions ? <td className='action'>
                                <Actions
                                    row={ row }
                                />
                            </td> : false}
                        </tr>))
                }
                {
                    newsData?.length ? <tr className='news-data-table'>
                        <td colSpan={config.columns.length + (Actions ? 1 : 0)}>
                            <div className='center-elements'>
                                <FontAwesomeIcon
                                    onClick={config.loadNewsData.ctaFn}
                                    icon={faArrowRotateLeft}
                                />
                                <p>{ `${newsData?.length} ${newsData?.length === 1 ? config.loadNewsData?.ctaSingle.toLowerCase() : config.loadNewsData?.ctaMultiple.toLowerCase()}` }</p>
                            </div>
                        </td>
                    </tr> : null
                }
            </tbody>
            {
                Footer ? <tfoot>
                    {
                        Footer
                    }
                </tfoot> : false
            }
        </WrapperTable>) : <p style={{color: 'var(--color-font)'}}>Não há dados para exibir.</p>
    );
}