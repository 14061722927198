import React, { useState } from "react";
import { Button, Modal/*, Tab, Tabs*/ } from "react-bootstrap";
import { Tabs, Tab, Box } from '@mui/material';
import TabContext from "@mui/lab/TabContext"; 
import TabList from "@mui/lab/TabList"; 
import TabPanel from "@mui/lab/TabPanel"; 
import WrapperModalMoreInformations, { WrapperModalMoreInformationsContent } from "./style";
import Payment from "./Payment";
import Hour from "./Hour";
import About from "./About";
import "./style.css";
import WrapperModalHeaderButton from "../../../styles/modalHeaderButton";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

export default function ModalMoreInformations(){

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { accountClient } = useSelector(state => state.accountState);
    const [activeKey, setActiveKey] = useState("about");

    return (
        <WrapperModalMoreInformations>
            <span className="more-information" onClick={handleShow}>Mais informações</span>

            <Modal size="lg" className="modal-more-informations" show={show} onHide={handleClose} centered>
                <WrapperModalMoreInformationsContent>
                    <Modal.Header>
                        <Modal.Title>{ accountClient?.name }</Modal.Title>
                        <WrapperModalHeaderButton onClick={handleClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </WrapperModalHeaderButton>
                    </Modal.Header>
                    <Box sx={{ width: "100%", typography: "body1" }}> 
                        <TabContext value={activeKey}> 
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}> 
                                <TabList variant="fullWidth" onChange={(e, key)=>setActiveKey(key)}> 
                                    <Tab label="Sobre" value="about" /> 
                                    <Tab label="Horário" value="hour" /> 
                                    <Tab label="Pagamento" value="payment" /> 
                                </TabList> 
                            </Box> 
                            <TabPanel value="about">
                                <About account={accountClient} />
                            </TabPanel> 
                            <TabPanel value="hour">
                                <Hour account={accountClient} />
                            </TabPanel> 
                            <TabPanel value="payment">
                                <Payment account={accountClient} />    
                            </TabPanel> 
                        </TabContext> 
                    </Box>
                    {/* <Tabs
                        defaultActiveKey="about"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="about" title="Sobre">
                            <Modal.Body>
                                <About account={accountClient} />
                            </Modal.Body>
                        </Tab>
                        <Tab eventKey="hour" title="Horário">
                            <Modal.Body>
                                <Hour account={accountClient} />
                            </Modal.Body>
                        </Tab>
                        <Tab eventKey="payment" title="Pagamento">
                            <Modal.Body>
                                <Payment account={accountClient} />
                            </Modal.Body>
                        </Tab>
                    </Tabs> */}
                </WrapperModalMoreInformationsContent>
            </Modal>
        </WrapperModalMoreInformations>
    );
}