import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import WrapperFilter from './style';

export default function Filter({content}){

    return (
        <WrapperFilter className='filter' expand="lg">
            <Navbar.Brand style={{marginRight: '50px'}}>
                <FontAwesomeIcon icon={faFilter} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Container>
                    {
                        content
                    }
                </Container>
            </Navbar.Collapse>
        </WrapperFilter>
    );
}