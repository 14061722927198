import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const WrapperModalV2 = styled.div`
    // button {
    //     padding: 0;
    //     background-color: unset;
    //     color: var(--color-primary);
    //     border: 0;
    //     font-size: 1.2rem;

    //     &:hover,
    //     &:active {
    //         background-color: unset !important;
    //         color: var(--color-primary) !important;
    //         opacity: 0.85;
    //     }
    // }
`;

export const WrapperModalV2Content = styled(Modal)`
    .modal-header {
        padding: 0.5rem 1rem;
        background-color: var(--color-primary);
        color: white;

        .modal-title {
            font-size: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            button {
                padding: 0;
                border: 0;

                &:hover,
                &:active {
                    background-color: unset !important;
                    opacity: 0.85;
                }
            }
        }
    }

    .modal-body {
        h6 {
            text-align: center;
            font-size: 1.5rem;
        }
    }

    .modal-footer {
        border-top: 0;
        padding: 0;

        .spinner-border {
            margin: 1rem;
        }

        button {
            background-color: unset;
            border: none;

            &:active {
                background-color: unset;
            }

            &.close {
                color: var(--color-danger);
                
                &:hover {
                    background-color: #F9ECEC;
                }
            }

            &.confirm {
                color: var(--color-success);

                &:hover {
                    background-color: #EDF4ED;
                }
            }
        }
    }
`;

export default WrapperModalV2;