import React, { useEffect } from "react";
import moment from "moment";
import WrapperHour from "./style";
import { useSelector } from "react-redux";
import utils from "../../../../utils";

export default function Hour(){

    const { accountClient: account } = useSelector(state => state.accountState);

    const hours = [
        {
            position: 1,
            day: "Segunda-feira",
            officeHour: account?.mondayOfficeBegin && account?.mondayOfficeBegin ? `${moment(account?.mondayOfficeBegin, "HH:mm:ss").format("HH:mm")} às ${moment(account?.mondayOfficeEnd, "HH:mm:ss").format("HH:mm")}` : "Fechado"
        },
        {
            position: 2,
            day: "Terça-feira",
            officeHour: account?.tuesdayOfficeBegin && account?.tuesdayOfficeBegin ? `${moment(account?.tuesdayOfficeBegin, "HH:mm:ss").format("HH:mm")} às ${moment(account?.tuesdayOfficeEnd, "HH:mm:ss").format("HH:mm")}` : "Fechado"
        },
        {
            position: 3,
            day: "Quarta-feira",
            officeHour: account?.wednesdayOfficeBegin && account?.wednesdayOfficeBegin ? `${moment(account?.wednesdayOfficeBegin, "HH:mm:ss").format("HH:mm")} às ${moment(account?.wednesdayOfficeEnd, "HH:mm:ss").format("HH:mm")}` : "Fechado"
        },
        {
            position: 4,
            day: "Quinta-feira",
            officeHour: account?.thursdayOfficeBegin && account?.thursdayOfficeBegin ? `${moment(account?.thursdayOfficeBegin, "HH:mm:ss").format("HH:mm")} às ${moment(account?.thursdayOfficeEnd, "HH:mm:ss").format("HH:mm")}` : "Fechado"
        },
        {
            position: 5,
            day: "Sexta-feira",
            officeHour: account?.fridayOfficeBegin && account?.fridayOfficeBegin ? `${moment(account?.fridayOfficeBegin, "HH:mm:ss").format("HH:mm")} às ${moment(account?.fridayOfficeEnd, "HH:mm:ss").format("HH:mm")}` : "Fechado"
        },
        {
            position: 6,
            day: "Sábado",
            officeHour: account?.saturdayOfficeBegin && account?.saturdayOfficeBegin ? `${moment(account?.saturdayOfficeBegin, "HH:mm:ss").format("HH:mm")} às ${moment(account?.saturdayOfficeEnd, "HH:mm:ss").format("HH:mm")}` : "Fechado"
        },
        {
            position: 0,
            day: "Domingo",
            officeHour: account?.sundayOfficeBegin && account?.sundayOfficeBegin ? `${moment(account?.sundayOfficeBegin, "HH:mm:ss").format("HH:mm")} às ${moment(account?.sundayOfficeEnd, "HH:mm:ss").format("HH:mm")}` : "Fechado"
        }
    ];

    return (
        <WrapperHour>
            <div className="grid-of-hours">
                {
                    hours.map(h => (
                        <div className={`row-hour${moment().day() === h.position ? " day-current" : ""}`}>
                            <span>{ h.day }</span>
                            <span><b>{ h.officeHour }</b></span>
                        </div>
                    ))
                }
            </div>
        </WrapperHour>
    );
}