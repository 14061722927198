import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const WrapperModal = styled.div`
    display: inline-block;
    margin-right: 10px;

    button {
        &:active,
        &:hover {
            background-color: var(--color-primary) !important;
            opacity: 0.85 !important;
        }

        &.cta {
            background-color: var(--color-primary);
            border: none;
            margin-bottom: 10px;
        }
    }

    svg:hover {
        cursor: pointer;
    }

    svg,
    .table-actions .svg-inline--fa {
        font-size: 1.35em !important;
        color: var(--color-primary);
    }

`;

export const WrapperModalContent = styled(Modal)`
    .modal-header,
    .modal-body,
    .modal-footer  {
        background-color: var(--color-main) !important;
    }

    .modal-content .modal-body label,
    .modal-title,
    .modal-content .modal-body p {
        color: white !important;
    }

    .modal-footer .btn-primary:first-child,
    .modal-footer .btn-primary:first-child:active {
        background-color: unset;
        border: none;
    }

    .btn-close {
        color: white !important;
        filter: invert(99%) sepia(72%) saturate(3073%) hue-rotate(177deg) brightness(122%) contrast(94%);
    }
  
    button.cta {
        background-color: var(--color-primary);
        border: none;
    }

    button.cta:hover {
        background-color: var(--color-primary);
        opacity: 0.85;
    }
  
    button.cta:active {
        background-color: var(--color-primary) !important;
        opacity: 0.85 !important;
    }

    .modal-content ol {
        color: white;
    }

    svg,
    .table-actions .svg-inline--fa {
        font-size: 1.35em !important;
        color: var(--color-primary);
    }

    ${(props) => props.styledCustom}
`;

export default WrapperModal;