import styled from 'styled-components';

export const WrapperLayoutDefault = styled.div`
    .modal-header {
        button {
            padding: 0;
            border: 0;
            border-radius: 36px;
            font-size: 1rem;
            background-color: rgba(229,231,235,1);

            svg {
                padding: 0.4rem;
                display: flex;
                width: 14px;
                font-size: 14px;

                path {
                    color: #394252;
                }
            }

            &:hover,
            &:active {
                background-color: white;
            }

            &[disabled] {
                opacity: 0;
            }
        }
    }
`;

export default WrapperLayoutDefault;