import moment from "moment";
import _ from "lodash";

const INITIAL_STATE = {
    isLoading: false,
    schedulings: [],
    filters: {
        limit: 10,
        totalPages: 1,
        id: "",
        professionalId: "",
        serviceId: "",
        date: "",
        status: ""
    }
}

const schedulingReducer = (state = INITIAL_STATE, { type, payload }) => {

    let newSchedulings = _.clone(state.schedulings);
    
    switch(type){
        case 'LOAD_SCHEDULING':
            return {
                ...state, 
                schedulings: payload.schedulings,
                filters: {
                    activePage: payload.activePage,
                    totalPages: Math.ceil(payload.count / payload.limit),
                    ...payload.filters
                },
                isLoading: false
            };
        // case 'INCLUDE_SERVICE':
        //     return {
        //         ...state, 
        //         services: [...state.services, payload]
        //     };
        case 'IS_LOADING_SCHEDULING':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_SCHEDULING':
            //month = moment(payload.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM");

            return {
                ...state,
                schedulings: newSchedulings.map(s => s.id === payload.id ? Object.assign({}, s, payload) : s)
                // schedulings: newSchedulings.map(groupScheduling => {
                //     if(groupScheduling.month === month){
                //         let newGroupScheduling = _.clone(groupScheduling);
                //         newGroupScheduling.schedulings = newGroupScheduling.schedulings.map(s=>s.id===payload.id ? Object.assign({}, s, payload) : s);
    
                //         return newGroupScheduling;
                //     }
    
                //     return groupScheduling;
                // })
            };
        // case 'DELETE_SERVICE':
        //     return {
        //         ...state, 
        //         services: state.services.filter(service=>service.id != payload.id)
        //     };
        case 'LOGOUT_CLIENT':
            return { ...state,
                schedulings: [],
                filters: INITIAL_STATE.filters
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default schedulingReducer;