import styled from 'styled-components';

export const WrapperHeader = styled.div`

    & {
        margin-bottom: 1rem;

        @media (min-width: 640px) {
            margin-bottom: 5rem;
        }
    }

    .hidden {
        display: none;
    }

    .actions-top {
        justify-content: center;
        background-color: rgba(255,255,255,1);
        display: flex;
        justify-content: center;

        z-index: 1;
        width: 100%;
        position: fixed;
        bottom: 0;

        .content-center {
            width: 80%;
            display: flex;
            justify-content: space-evenly;

            a {
                text-decoration: none;
            }
        }

        button.profile {
            position: relative;

            .menu-my-account {
                width: 125px;

                @media (min-width: 1025px) {
                    width: 100%;
                }
            }

            .list-group {
                z-index: 1;
                position: absolute;
                left: 0;
                bottom: 2.7rem;
                @media (min-width: 1025px) {
                    top: 2.5rem;
                    width: 100%;
                }

                .list-group-item {
                    font-weight: 400;
                    text-align: start;

                    &:hover {
                        background-color: #F5F5F5;
                    }
                }
            }
        }

        button {
            color: rgb(156,163,175);
            display: flex;
            flex-direction: column;
            align-items: center;
            height: min-content;

            span {
                font-size: .625rem;
            }

            .tab-icon {
                display: none;
            }
            
            font-weight: 700;
            background-color: unset;
            border: 0;

            &:hover,
            &.open {
                border-color: white;
            }

            &:active {
                color: rgb(156,163,175);
                background-color: unset;
            }

            &.active {
                color: var(--color-primary);
            }

            @media (min-width: 1025px) {
                display: block;
                color: white;
                border: 1px solid var(--color-main);

                span {
                    font-size: 16px;
                }

                svg:first-child {
                    margin-right: 1rem;
                }

                &.active {
                    background-color: white;
                    color: var(--color-main);

                    &:active {
                        color: var(--color-main);
                    }
                }

                &:active {
                    color: white;
                }

                .tab-icon {
                    margin-left: 1rem;
                    display: inline-block;
                }
            }
        }

        @media (min-width: 1025px) {
            display: flex;
            position: static;
            background-color: var(--color-main);
            padding: 1rem 0;
        }
    }

    .only-bg {
        background-color: #264653;
        height: 8rem;
        width: 100%;
        position: absolute;
        top: 70px;
        z-index: -1;
        display: none;

        @media (min-width: 1025px) {
            display: block;
        }
    }

    .banner {
        img {
            width: 100%;

            @media (min-width: 1025px) {
                border: 3px solid white;
                border-radius: 18px;
                height: 21rem;
                width: 95%;
            }
        }

        @media (min-width: 1025px) {
            display: flex;
            justify-content: center;
        }
    }

    .information {

        & {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 1rem;
            padding: 3rem 1rem 1rem;
            position: relative;
            bottom: 0.85rem;

            @media (min-width: 640px) {
                background-color: #F4F5F7;
                padding-top: 1rem;
                border-radius: 0;
                bottom: 0;
            }
        }

        .logo {
            overflow: hidden;
            border: 4px solid white;
            border-radius: 80px;
            position: absolute;
            max-width: 100px;
            bottom: ${(props) => props.styles.logo.bottom};
            right: calc(50% - 50px); //50px é a metado da tamanho da imagem

            img {
                width: 100%;
            }

            @media (min-width: 640px) {
                left: 15px;
                border-radius: 5px;
                bottom: -4rem;
                max-width: 140px;
            }

            @media (min-width: 1025px) {
                left: 80px;
            }
        }

        p {
            margin: 0;
        }

        h1 {
            font-size: 1.25rem;
            margin: 0;

            @media (min-width: 640px) {
                position: absolute;
                left: 11.5rem;
                top: 2rem;
                font-size: 1.875rem;
                font-weight: 700;
            }

            @media (min-width: 1025px) {
                left: 15rem;
            }
        }

        .row-address-hours {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & > .point {
                display: none;

                @media (min-width: 640px) {
                    display: block !important;
                }
            }

            .point {
                margin: 0 0.5rem;
                position: relative;
                bottom: 0.2rem;
                font-weight: 1000;
            }

            .address {

                & {
                    margin: 0.5rem 0;
                }

                svg {
                    margin-right: 0.5rem;
                }

            }

            .hours {
                &.open {
                    color: rgb(16 185 129);
                }

                &.close {
                    color: var(--color-danger);
                }
            }

            .is-open {
                color: var(--color-danger);
            }

            @media (min-width: 640px) {
                flex-direction: row-reverse;

                position: absolute;
                left: 11.5rem;
                top: 4rem;
            }

            @media (min-width: 1025px) {
                left: 15rem;
            }
        }
    }
`;

export const WrapperHeaderResponsive = styled.div`

    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0 transparent,0 0 transparent,0 0 transparent,0 0 transparent,0 -4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);

    @media (min-width: 1025px) {
        display: none;
    }
`;

export default WrapperHeader;