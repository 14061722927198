import { useState, useEffect } from "react";
import store from "../store";
import utils from "../utils";

const useIsValidJWT = () => {
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if(localStorage.getItem("fnIsValidJWT")){
            clearInterval(localStorage.getItem("fnIsValidJWT"));
            localStorage.removeItem("fnIsValidJWT");
        }
        
        if(store.getState().accountState.token){
            setIsValid(utils.timeLeft(store.getState().accountState.token) > 60);
            localStorage.setItem("fnIsValidJWT", setInterval(()=>{
                setIsValid(utils.timeLeft(store.getState().accountState.token) > 60);
            }, 1000));
        }
    }, [store.getState().accountState.isLogged]);

    return [isValid];
};

export default useIsValidJWT;