import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const WrapperModalDoneAppointment = styled(Modal)`
    .btn {
        border: 0;

        &:not(.btn-secondary) {
            &:hover {
                background-color: var(--color-primary);
                opacity: 0.85;
            }
        }

        &.btn-secondary {
            background-color: unset;
            color: var(--color-font);

            &:active {
                background-color: unset;
                color: var(--color-font);
            }
        }
    }
`;

export const WrapperModalConfirmCancelationAppointment = styled(Modal)`
    .btn {
        border: 0;

        &:not(.btn-secondary) {
            &:hover {
                background-color: var(--color-primary);
                opacity: 0.85;
            }
        }

        &.btn-secondary {
            background-color: unset;
            color: var(--color-font);

            &:active {
                background-color: unset;
                color: var(--color-font);
            }
        }
    }
`;

export const WrapperModalDisplayInformations = styled(Modal)`
    .btn {
        border: 0;

        &:not(.btn-secondary) {
            &:hover {
                background-color: var(--color-primary);
                opacity: 0.85;
            }
        }

        &.btn-secondary {
            background-color: unset;
            color: var(--color-font);

            &:active {
                background-color: unset;
                color: var(--color-font);
            }
        }
    }
`;

export const WrapperAdminAppointmentCalendar = styled.div`

    .btn-schedule {

        background-color: var(--color-primary);

        &:active {
            background-color: var(--color-primary);
        }

        &:hover {
            opacity: 0.85;
        }

        svg {
            margin-right: 0.85rem;
        }
    }

    .table-actions {
        button {
            background-color: unset;
            padding: 0;

            &:hover {
                opacity: 0.85;
            }

            &:active {
                background-color: unset;
            }

            svg {
                font-size: 1.35em;
            }
        }
    }
`;

export default WrapperAdminAppointmentCalendar;