import React, { useState, useEffect } from "react";
import WrapperLayoutAdmin from "./style";
import Logo from './../../images/logo2.png';
import LogoResponsive from './../../images/logo-icone.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCircleUser,
    faRightFromBracket,
    faBars,
    faBuilding,
    faGear
} from '@fortawesome/free-solid-svg-icons';
import { Navbar, Image, Nav, Button } from 'react-bootstrap';
import config from './../../config';
//import NotificationBell from '../NotificationBell';
import store from './../../store';
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { http, websocket } from "../../services";
import { useLocation } from "react-router-dom";
import host from '../../config';

//import { io } from "socket.io-client";
//import { websocket, http } from './../../services';

import {
    Link,
    Outlet,
    useNavigate,
    Navigate
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import utils from "../../utils";

const logout = ()=>{
    //store.dispatch({type: 'IS_LOADING', payload: true});
    http
        .user
        .logout()
        .finally(()=>{
            store.dispatch({type: 'LOGOUT'});
            store.dispatch({type: 'IS_LOADING', payload: false});
        });
}

function HeaderResponsive(){
    const root = window.location.pathname.split('/')[1];
    const user = useSelector(state=>state.accountState.user);
    const dispatch = useDispatch();
    
    return (
        <Navbar id="header-responsive" expand="xxl">
            <Navbar.Brand style={{margin: '0 50px'}}>
                <Image src={ Logo } style={{width: '60px'}} fluid />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav
                className="mr-auto my-2 my-lg-0"
                navbarScroll
                >
                    {
                        config.pages[root]
                        ?.filter(page=>{
                            if(page.screen === "send" && !store.getState().accountState.permissionsAccount?.products?.includes("massShooting")){
                                return false;
                            }
                            return !config.excludePages[root].includes(page.screen);
                        })
                        .map(page=>(
                            <Navbar>
                                <Link to={ page.url }>{ page.title }</Link>
                            </Navbar>
                        ))
                    }
                    <Navbar>
                        <Link to={ `/${user?.type === 'psicólogo' ? 'psicologo' : user?.type}/minha-conta` }>Minha conta</Link>
                    </Navbar>
                    <Navbar>
                        <Button onClick={logout} variant='link'>Sair</Button>
                    </Navbar>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default function Layout(){

    const [active, setActive] = useState(false);
    const { isLogged, refreshToken, user, type } = useSelector(state=>state.accountState);
    const navigate = useNavigate();
    //const user = useSelector(state=>state.accountState.user);
    const { usersOnline } = useSelector(state=>state.userState);
    const location = useLocation();

    useEffect(()=>{
        if(!isLogged || type !== "user") navigate('/login');
    }, [isLogged, type]);

    useEffect(()=>{
        dispatch({
            type: "SET_PAGE",
            payload: location?.pathname
        });
        dispatch({
            type: "SET_CONTEXT",
            payload: location?.pathname.split("/")[1]
        });
    }, [location?.pathname]);

    useEffect(()=>{
        const obj = usersOnline.find(u=>u.id === user.id);
        dispatch({
            type: "SET_IS_ONLINE",
            payload: Boolean(obj)
        });
    }, [usersOnline]);

    const root = window.location.pathname.split('/')[1];

    const dispatch = useDispatch();

    //const isOpen = useSelector(state=>state.notificationState.open);
    
    useEffect(()=>{
        
        // if(isLogged){
        //     const interval = setInterval(() => {
        //         let exp = (jwtDecode(store.getState().accountState.token)).exp;
        //         let now = Math.ceil(moment.now() / 1000);
        //         let diff = exp - now;
        //         if(diff <= 60){
        //             http.user.refresh();
        //         }

        //     }, 1000);
        //     return () => clearInterval(interval);
        // }

    }, [isLogged, refreshToken]);

    useEffect(()=>{

        // http.configUtils.findAll()
        //     .then(result=>{
        //         dispatch({
        //             type: 'LOAD_CONFIG_UTILS',
        //             payload: result.data
        //         });
        //     })
        //     .catch(error=>utils.createNotification({
        //         type: 'error',
        //         title: 'Erro ao carregar atributos',
        //         message: error.response.data
        //     }))
        //     .finally(()=>dispatch({
        //         type: 'IS_LOADING_ATTRIBUTE',
        //         payload: false
        //     }));
    }, []);

    return (
        <WrapperLayoutAdmin className={ `container-layout ${window.location.pathname.split('/')[2]}` + (active ? " active" : "") }>
            {/* <div className={ `container-layout ${window.location.pathname.split('/')[2]}` + (active ? " active" : "") }> */}
                <HeaderResponsive active={ active } />
                <div className="navigation">
                    <ul>
                        <li className="logo">
                            <img src={ !active ? Logo : LogoResponsive } />
                        </li>
                        {
                            config.pages[root]
                            ?.filter(page=>{
                                if(page.screen === "send" && !store.getState().accountState.permissionsAccount?.products?.includes("massShooting")){
                                    return false;
                                }
                                return !config.excludePages[root].includes(page.screen);
                            })
                            .map(page=>(
                                <Link to={ page.url }>
                                    <li
                                        className={ "" + (location?.pathname === page.url ? "active" : "") }
                                    >
                                        <div className="icon">
                                            <FontAwesomeIcon icon={ page.icon } />
                                        </div>
                                        <p>{ page.title }</p>
                                    </li>
                                </Link>
                            ))
                        }
                        {
                            /*<li className="hr">
                            <hr/>
                            </li>
                            <Link to="/admin/configuracao">
                                <li className={ "" + (pageCurrent === "/admin/configuracao" ? "active" : "") } onClick={()=>setPageCurrent('/admin/configuracao')}>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={ faGear } />
                                    </div>
                                    <p>Configuração</p>
                                </li>
                            </Link>*/
                        }
                        <li onClick={logout}>
                            <div className="icon">
                                <FontAwesomeIcon icon={ faRightFromBracket } />
                            </div>
                            <p>Sair</p>
                        </li>
                    </ul>
                </div>
                <div className="main">
                    <header>
                        <FontAwesomeIcon
                            className="toggle"
                            onClick={()=>setActive(!active)}
                            icon={ faBars }
                        />
                    </header>
                    <div className="content">
                        <Outlet />
                    </div>
                </div>
            {/* </div> */}
        </WrapperLayoutAdmin>
    );
}