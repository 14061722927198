import { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { WrapperNotificationModal } from './style';
import { http } from '../../services';
import utils from '../../utils';

function NotificationModal() {
    const dispatch = useDispatch();
    const { modalOpen: show, message, contact, isLoading } = useSelector(state=>state.notificationState);
    const [text, setText] = useState(message);
    const setShow = show => dispatch({
        type: "SET_MODAL_OPEN_NOTIFICATION",
        payload: show
    });
    const { user } = useSelector(state=>state.accountState);

    const sendMessage = ()=>{
        dispatch({
            type: "IS_LOADING_NOTIFICATION",
            payload: true
        });
        http.notification.sendText(
            text,
            user.Account.numberWhatsAppForNotification,
            contact
        )
            .then(()=>{
                setShow(false);
                utils.createNotification({
                    type: 'success',
                    title: `Mensagem enviada com sucesso!`
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: `Falha ao enviar mensagem`,
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: "IS_LOADING_NOTIFICATION",
                payload: false
            }));
    }
    
    useEffect(()=>{
        setText(message);
    }, [message]);

    useEffect(()=>{
        dispatch({
            type: "SET_MESSAGE_NOTIFICATION",
            payload: text
        });
    }, [text]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        if(!show){
            setText("");
            dispatch({
                type: "CLEAR_NOTIFICATION"
            });
        }
    }, [show]);
  
    return (
      <>
        <WrapperNotificationModal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Deseja notificar o cliente?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Mensagem</Form.Label>
                        <Form.Control value={text} onChange={e=>setText(e.target.value)} className="shadow-none" as="textarea" rows={3} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {
                    isLoading ? (
                        <Spinner size="sm" border="animation" />
                    ) : (
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Não
                            </Button>
                            <Button disabled={isLoading} variant="primary" onClick={sendMessage}>
                                Notificar
                            </Button>
                        </>
                    )
                }
            </Modal.Footer>
        </WrapperNotificationModal>
      </>
    );
}

export default NotificationModal;