import WrapperAdminAppointmentCalendar, { WrapperModalConfirmCancelationAppointment, WrapperModalDisplayInformations, WrapperModalDoneAppointment } from "./style";
import TableSchedule from "../../../Components/TableSchedule";
import { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Button, Card, Col, Form, Modal, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { http } from "../../../services";
import utils from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../../Components/Table";
import Filter from "../../../Components/Filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faEye, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "../../../Components/Pagination";

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'date', translate: 'Data', type: 'datetime' },
        { name: 'status', translate: 'Status', type: 'status_scheduling' },
        { name: 'price', translate: 'Valor', type: 'currency' }
    ],
    // include: {
    //     cta: "Incluir",
    //     title: 'Incluir cliente',
    //     ctaFn: ()=>{
    //         http.client.createAdmin(store.getState().formState.form)
    //             .then(result=>{
    //                 store.dispatch({
    //                     type: 'INCLUDE_CLIENT',
    //                     payload: result.data
    //                 });
    //                 utils.createNotification({
    //                     type: 'success',
    //                     title: 'Cliente criado com sucesso'
    //                 });
    //             })
    //             .catch(error=>utils.createNotification({
    //                 type: 'error',
    //                 timing: 15000,
    //                 title: 'Falha ao criar cliente',
    //                 message: error.response.data
    //             }))
    //             .finally(()=>store.dispatch({
    //                 type: 'CLEAR_FORM'
    //             }));
    //     }
    // },
    // update: {
    //     cta: "Editar",
    //     title: 'Editar cliente',
    //     styledCustomContent: css`
    //         .modal-body {
    //             max-height: 69.5vh;
    //             overflow-y: auto;
    //         }
    //     `,
    //     icon: faPen,
    //     ctaFn: ({id})=>{
    //         http.client.update(id, store.getState().formState.form)
    //             .then(result=>{
    //                 store.dispatch({
    //                     type: 'UPDATE_CLIENT',
    //                     payload: result.data
    //                 });
    //                 utils.createNotification({
    //                     type: 'success',
    //                     title: `As informações do cliente ${id} foram alteradas!`
    //                 });
    //             })
    //             .catch(error=>utils.createNotification({
    //                 type: 'error',
    //                 timing: 15000,
    //                 title: `Falha ao editar cliente ${id}`,
    //                 message: error.response.data
    //             }))
    //             .finally(()=>store.dispatch({
    //                 type: 'CLEAR_FORM'
    //             }));
    //     }
    // },
    // remove: {
    //     cta: "Deletar",
    //     title: 'Deletar cliente',
    //     ctaFn: ({ id })=>{
    //         http.client.destroy(id)
    //             .then(()=>{
    //                 store.dispatch({
    //                     type: 'DELETE_CLIENT',
    //                     payload: {
    //                         id
    //                     }
    //                 });
    //                 utils.createNotification({
    //                     type: 'success',
    //                     title: 'Cliente deletado',
    //                     message: `O cliente ${id} foi deletado!`
    //                 });
    //             })
    //             .catch(error=>utils.createNotification({
    //                 type: 'error',
    //                 timing: 15000,
    //                 title: `Falha ao deletar cliente ${id}`,
    //                 message: error.response.data
    //             }));
    //     },
    //     icon: faTrash,
    //     style: {
    //         backgroundColor: 'var(--color-danger)'
    //     }
    // }
}

function ModalDoneAppointment({show, handleClose, appointment, submit, isLoading}){
    return (<WrapperModalDoneAppointment show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Marcar o agendamento como feito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Deseja marcar como feito o agendamento do dia { moment(appointment?.date).format("DD/MM/YYYY [às] HH:mm") }?
        </Modal.Body>
        <Modal.Footer>
            {
                isLoading ? (
                    <Spinner border="animation" />
                ) : (
                    <>
                        <Button variant="secondary" onClick={handleClose}>
                            Voltar
                        </Button>
                        <Button variant="primary" onClick={submit}>
                            Sim
                        </Button>
                    </>
                )
            }
        </Modal.Footer>
    </WrapperModalDoneAppointment>);
}

function ModalConfirmCancelationAppointment({show, submit, handleClose, isLoading, setIsLoading, appointment}){
    return (
        <WrapperModalConfirmCancelationAppointment show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Cancelar agendamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Deseja cancelar o agendamento do dia { moment(appointment?.date).format("DD/MM/YYYY [às] HH:mm") }?
            </Modal.Body>
            <Modal.Footer>
                {
                    isLoading ? (
                        <Spinner border="animation" />
                    ) : (
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Voltar
                            </Button>
                            <Button variant="primary" onClick={submit}>
                                Cancelar
                            </Button>
                        </>
                    )
                }
            </Modal.Footer>
        </WrapperModalConfirmCancelationAppointment>
    );
}

function ModalDisplayInformations({ cell: appointment, ButtonComponent }){

    const [show, setShow] = useState(false);
    const { user } = useSelector(state => state.accountState);
    const { modalOpen } = useSelector(state=>state.notificationState);
    const [showModalDone, setShowModalDone] = useState(false);
    const [showModalCancelation, setShowModalCancelation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    const done = ()=>{
        setIsLoading(true);

        http.scheduling.markAsDone(appointment.id)
            .then(result=>{
                dispatch({
                    type: "UPDATE_SCHEDULING",
                    payload: result.data
                });
                utils.createNotification({
                    type: 'success',
                    title: 'Agendamento marcado como feito'
                });
                setShowModalDone(false);
                return result;
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao marcar agendamento como feito',
                message: error.response.data,
                timing: 15000
            }))
            .finally(()=>setIsLoading(false));
    }

    const cancel = ()=>{
        setIsLoading(true);

        http.scheduling.cancelAdmin(appointment.id)
            .then(result=>{
                dispatch({
                    type: "UPDATE_SCHEDULING",
                    payload: result.data
                });
                dispatch({
                    type: "SET_MESSAGE_NOTIFICATION",
                    payload: `Olá! O seu atendimento para o dia ${moment(appointment.date).format("DD/MM/YYYY [às] HH:mm")} foi cancelado.\nServiços:\n${appointment.ServicesPerSchedulings.map(s=>`-${s.Service.name}\n`)}\n📍 ${user.Account.address}, ${user.Account.number}, ${user.Account.city}`
                });
                dispatch({
                    type: "SET_MODAL_OPEN_NOTIFICATION",
                    payload: true
                });
                dispatch({
                    type: "SET_CONTACT_NOTIFICATION",
                    payload: appointment.Client.contact
                });
                utils.createNotification({
                    type: 'success',
                    title: 'Agendamento cancelado'
                });
                setShow(true);
                setShowModalCancelation(false);
                return result;
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao cancelar agendamento',
                message: error.response.data,
                timing: 15000
            }))
            .finally(()=>setIsLoading(false));
    }

    return (
        <>
            {
                ButtonComponent ? (
                    <ButtonComponent onClick={handleShow} />
                ) : <Button className={appointment.status === "canceled" ? "badge-danger" : (
                    appointment.status === "done" ? "badge-gray" : ""
                )} onClick={handleShow}>{ moment(appointment.date).format(`HH[h]${moment(appointment.date).format("mm") !== "00" ? moment(appointment.date).format("mm") : ""};`) }</Button>
            }
            <ModalDoneAppointment
                appointment={appointment}
                show={showModalDone}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                submit={done}
                handleClose={()=>setShowModalDone(false)}
            />
            <ModalConfirmCancelationAppointment
                appointment={appointment}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                show={showModalCancelation}
                submit={cancel}
                handleClose={()=>{
                    setShowModalCancelation(false);
                    setShow(true);
                }}
            />
            <WrapperModalDisplayInformations show={!modalOpen && !showModalDone && show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Informações do agendamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="date d-flex justify-content-between">
                        <span><b>Data</b></span>
                        <span>{ moment(appointment.date).format(`DD/MM/YYYY HH:mm`) }</span>
                    </div>
                    <div className="date d-flex justify-content-between">
                        <span><b>Id do cliente</b></span>
                        <span><Link to={`/admin/clientes?id=${appointment.clientId}`}>{ appointment.clientId }</Link></span>
                    </div>
                    <div className="date d-flex justify-content-between">
                        <span><b>Profissional</b></span>
                        <span>{ appointment.User.name }</span>
                    </div>
                    <div className="date d-flex justify-content-between">
                        <span><b>Id do profissional</b></span>
                        <span>{ appointment.professionalId }</span>
                    </div>
                    <div className="date d-flex justify-content-between">
                        <span><b>Cliente</b></span>
                        <span>Eduardo Marcelino</span>
                    </div>
                    <div className="date d-flex justify-content-between">
                        <span><b>Status</b></span>
                        <span>{ utils.translateSchedulingStatus(appointment.status) }</span>
                    </div>
                    <div className="services mt-3">
                        <span><b>{ appointment.ServicesPerSchedulings.length > 1 ? "Serviços" : "Serviço" }</b></span>
                        {
                            appointment.ServicesPerSchedulings.map(servicesPerScheduling=><p>-{servicesPerScheduling.Service.name} - {utils.formatPrice(servicesPerScheduling.price)}</p>)
                        }
                    </div>
                    <div className="date d-flex justify-content-between">
                        <span><b>Observação</b></span>
                        <span>{ appointment.observation }</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isLoading ? (
                            <Spinner border="animation" />
                        ) : (
                            <>
                                <Button variant="secondary" onClick={handleClose}>
                                    Fechar
                                </Button>
                                {
                                    !["canceled", "done"].includes(appointment.status) ? (
                                        <>
                                            <Button variant="secondary" onClick={()=>{
                                                setShow(false);
                                                setShowModalCancelation(true);
                                            }}>
                                                Cancelar
                                            </Button>
                                            {/* <Button variant="primary" onClick={handleClose}>
                                                Salvar
                                            </Button> */}
                                            <Button variant="primary" onClick={()=>setShowModalDone(true)}>
                                                Feito
                                            </Button>
                                        </>
                                    ) : null
                                }
                            </>
                        )
                    }
                </Modal.Footer>
            </WrapperModalDisplayInformations>
        </>
    );
}

function FilterContent({toFilter}){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get("id") || '');
    const [dateInitial, setDateInitial] = useState(searchParams.get('dateInitial') || '');
    const [dateFinish, setDateFinish] = useState(searchParams.get('dateFinish') || '');
    const [status, setStatus] = useState(searchParams.get('status') || '');
    const [clientId, setClientId] = useState(searchParams.get('clientId') || '');
    const [professionalId, setProfessionalId] = useState(searchParams.get('professionalId') || '');

    useEffect(()=>{
        setId(searchParams.get("id") || '');
        setDateInitial(searchParams.get("dateInitial") || '');
        setDateFinish(searchParams.get("dateFinish") || '');
        setStatus(searchParams.get("status") || '');
        setClientId(searchParams.get("clientId") || '');
        setProfessionalId(searchParams.get("professionalId") || '');
    }, [searchParams.get('tab')]);

    useEffect(()=>{
        if(!dateInitial) setDateFinish('');
        if(!dateFinish || (dateInitial > dateFinish)) setDateFinish(dateInitial);
    }, [dateInitial]);

    useEffect(()=>{
        if(!dateFinish) setDateInitial('');
        if(!dateInitial || (dateFinish < dateInitial)) setDateInitial(dateFinish);
    }, [dateFinish]);

    return (
        <Row>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="formId">
                    <Form.Label>Id</Form.Label>
                    <Form.Control className="shadow-none" value={id} onChange={e=>setId(e.target.value)} type="text" />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="formDateInitial">
                    <Form.Label>Data inicial</Form.Label>
                    <Form.Control className="shadow-none" value={dateInitial} onChange={e=>setDateInitial(e.target.value)} type="date" />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="formDateFinish">
                    <Form.Label>Data final</Form.Label>
                    <Form.Control className="shadow-none" value={dateFinish} onChange={e=>setDateFinish(e.target.value)} type="date" />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                        className="shadow-none"
                        value={status}
                        onChange={e=>setStatus(e.target.value)}
                    >
                        <option></option>
                        <option value="scheduled">Agendado</option>
                        <option value="canceled">Cancelado</option>
                        <option value="confirmed">Confirmado</option>
                        <option value="done">Realizado</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="clientId">
                    <Form.Label>Id do cliente</Form.Label>
                    <Form.Control className="shadow-none" value={clientId} onChange={e=>setClientId(e.target.value)} type="text" />
                </Form.Group>
            </Col>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="clientId">
                    <Form.Label>Id do profissional</Form.Label>
                    <Form.Control className="shadow-none" value={professionalId} onChange={e=>setProfessionalId(e.target.value)} type="text" />
                </Form.Group>
            </Col>
            <Col>
                {<Form.Group id="filter-btn" className="mb-3" controlId="formBasicEmail">
                    <Form.Label></Form.Label>
                        <Button onClick={()=>toFilter({
                            activePage: 1,
                            tab: searchParams.get("tab"),
                            id,
                            dateInitial,
                            dateFinish,
                            status,
                            clientId,
                            professionalId
                        })} variant="success">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                </Form.Group>}
            </Col>
        </Row>
    );
}

function ButtonComponentView(props){
    return (
        <Button {...props}>
            <FontAwesomeIcon icon={faEye} />
        </Button>
    );
}

function TableActions(props){

    return (
        <div className="table-actions">
            <ModalDisplayInformations cell={props.row} ButtonComponent={ButtonComponentView} />
        </div>
    );
}

export default function AdminAppointmentCalendar(){

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useSelector(state => state.accountState);
    const { schedulings: appointments, isLoading, filters } = useSelector(state => state.schedulingState);
    const setIsLoading = newIsLoading => dispatch({
        type: "IS_LOADING_SCHEDULING",
        payload: newIsLoading
    });
    const [dateInitial, setDateInitial] = useState(searchParams.get("tab") === "list" ? (searchParams.get("dateInitial") || "") : moment().format("YYYY-MM-DD"));
    const [dateFinish, setDateFinish] = useState(searchParams.get("tab") === "list" ? (searchParams.get("dateFinish") || "") : moment().add(6, "days").format("YYYY-MM-DD"));
    const [days, setDays] = useState(_.times(
        moment(dateFinish, "YYYY-MM-DD").diff(dateInitial, "days") + 1,
        index=>({day: moment(dateInitial, "YYYY-MM-DD").add(index, "days")})
    ));

    const incrementMonth = () => {
        let newDateInitial = moment(dateInitial, "YYYY-MM-DD").add(1, "months").format("YYYY-MM-DD");
        let newDateFinish = moment(newDateInitial, "YYYY-MM-DD").add(6, "days").format("YYYY-MM-DD");

        setDays(_.times(
            moment(newDateFinish, "YYYY-MM-DD").diff(newDateInitial, "days") + 1,
            index=>({day: moment(newDateInitial, "YYYY-MM-DD").add(index, "days")})
        ));

        setDateInitial(newDateInitial);
        setDateFinish(newDateFinish);
    }

    const goToday = () => {
        let newDateInitial = moment().format("YYYY-MM-DD");
        let newDateFinish = moment().add(6, "days").format("YYYY-MM-DD");

        setDays(_.times(
            moment(newDateFinish, "YYYY-MM-DD").diff(newDateInitial, "days") + 1,
            index=>({day: moment(newDateInitial, "YYYY-MM-DD").add(index, "days")})
        ));

        setDateInitial(newDateInitial);
        setDateFinish(newDateFinish);
    }

    const goBack = () => {
        setIsLoading(true);
        let newDateInitial = moment(dateInitial, "YYYY-MM-DD").subtract(7, "days").format("YYYY-MM-DD");
        let newDateFinish = moment(dateFinish, "YYYY-MM-DD").subtract(7, "days").format("YYYY-MM-DD");

        setDays(_.times(
            moment(newDateFinish, "YYYY-MM-DD").diff(newDateInitial, "days") + 1,
            index=>({day: moment(newDateInitial, "YYYY-MM-DD").add(index, "days")})
        ));

        setDateInitial(newDateInitial);
        setDateFinish(newDateFinish);

        setIsLoading(false);
    }

    const advance = () => {
        setIsLoading(true);
        let newDateInitial = moment(dateInitial, "YYYY-MM-DD").add(7, "days").format("YYYY-MM-DD");
        let newDateFinish = moment(dateFinish, "YYYY-MM-DD").add(7, "days").format("YYYY-MM-DD");

        setDays(_.times(
            moment(newDateFinish, "YYYY-MM-DD").diff(newDateInitial, "days") + 1,
            index=>({day: moment(newDateInitial, "YYYY-MM-DD").add(index, "days")})
        ));

        setDateInitial(newDateInitial);
        setDateFinish(newDateFinish);

        setIsLoading(false);
    }

    const toFilter = (obj={})=>{
        let formatSearchParams = _.merge({tab: searchParams.get("tab"), limit: (!searchParams.get("tab") || searchParams.get("tab") === "calendar") ? 0 : 10}, obj);
        setSearchParams(Object.entries(formatSearchParams).reduce((acc, entry)=>{
            return entry[1] ? Object.assign(acc, {
                [entry[0]]: entry[1]
            }) : acc;
        }, {}));
    }

    useEffect(()=>{
        if(searchParams.get("clientIdToSchedule")){
            navigate(`/admin/agenda/${searchParams.get("clientIdToSchedule")}`);
        }
    }, []);

    useEffect(()=>{
        if(!searchParams.get("tab") || searchParams.get("tab") === "calendar"){
            setDays(_.times(
                moment(moment().add(6, "days").format("YYYY-MM-DD"), "YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), "days") + 1,
                index=>({day: moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD").add(index, "days")})
            ));

            setDateInitial(moment().format("YYYY-MM-DD"));
            setDateFinish(moment().add(6, "days").format("YYYY-MM-DD"));

            return;
        }

        setDateInitial(searchParams.get("dateInitial") || "");
        setDateFinish(searchParams.get("dateFinish") || "");
    }, [searchParams.get("tab")]);

    useEffect(()=>{
        setIsLoading(true);
        if(!(searchParams.get("tab") !== "list" && !(dateInitial && dateFinish))){
            http.scheduling.findAll({
                id: searchParams.get("id"),
                clientId: searchParams.get("clientId"),
                professionalId: searchParams.get("professionalId"),
                status: searchParams.get("status"),
                dateInitial: searchParams.get("tab") !== "list" ? dateInitial : searchParams.get("dateInitial"),
                dateFinish: searchParams.get("tab") !== "list" ? dateFinish : searchParams.get("dateFinish"),
                activePage: searchParams.get("activePage"),
                limit: searchParams.get("tab") === "list" ? 10 : 0
            })
                .then(result=>{
                    dispatch({
                        type: "LOAD_SCHEDULING",
                        payload: {
                            filters: result.data.filters,
                            schedulings: result.data.rows,
                            count: result.data.count,
                            limit: result.data.limit,
                            activePage: result.data.activePage 
                        }
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Falha ao buscar agendamentos',
                    message: error.response.data,
                    timing: 15000
                }))
                .finally(()=>setIsLoading(false));
        }
    }, [
        searchParams.get("id"),
        searchParams.get("clientId"),
        searchParams.get("dateInitial"),
        searchParams.get("dateFinish"),
        searchParams.get("professionalId"),
        searchParams.get("status"),
        searchParams.get("activePage"),
        searchParams.get("dateInitial"),
        searchParams.get("dateFinish"),
        dateInitial,
        dateFinish
    ]);

    return (
        <WrapperAdminAppointmentCalendar>
            <Link to={`/admin/clientes?after_select_redirect_url=/admin/agenda?scheduleClient={{clientId}}`}>
                <Button className="btn-schedule mb-3">
                    <FontAwesomeIcon icon={faCalendar} />
                    <span>Agendar</span>
                </Button>
            </Link>
            <Tabs
                defaultActiveKey={searchParams.get("tab") || "calendar"}
                className="mb-3"
                onSelect={tab=>setSearchParams({
                    tab
                })}
            >
                <Tab eventKey="calendar" title="Calendário">
                    {
                        !dateInitial || !dateFinish ? (
                            null
                        ) : (
                            <TableSchedule
                                data={
                                    (()=>{

                                        if(!user?.Account) return [];

                                        let schedulings = appointments;
                                        let data = [];
                                        let { timeBegin, timeEnd, nonWorkingDays } = utils.getTimeBeginAndTimeEndOfAccount(user.Account);
                                        
                                        timeBegin = moment(timeBegin, "HH:mm:ss").format("HH:00");
                                        timeEnd = moment(timeEnd, "HH:mm:ss").format("HH:00");
                                        
                                        schedulings.forEach(s => {
                                            const date = moment(s.date);
                                            if(date.format("HH:00") <= timeBegin) timeBegin = date.format("HH:00");
                                            if(date.format("HH:00") >= timeEnd){
                                                if(date.add(1, "hours").format("HH:00") === "00:00"){
                                                    timeEnd = "23:00";
                                                } else {
                                                    timeEnd = date.add(1, "hours").format("HH:00");
                                                }
                                            }
                                        });
                                        for(let h = timeBegin; (h !== "23:00" && h < timeEnd) || (h === "23:00" && h < "23:59"); h = h !== "23:00" ? moment(h, "HH:mm").add(1, "hours").format("HH:mm") : moment(h, "HH:mm").add(59, "minutes").format("HH:mm")){

                                            let obj = {
                                                time: h,
                                                row: []
                                            }

                                            for(let d = dateInitial; d <= dateFinish; d = moment(d, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD")){
                                                obj.row.push({
                                                    day: d,
                                                    disabled: nonWorkingDays.includes(Number(moment(d).format("d"))),
                                                    cells: []
                                                });

                                                schedulings = schedulings.filter(s=>{

                                                    let date = moment(s.date);
                                                    
                                                    if(date.format("YYYY-MM-DD HH:00") === `${d} ${h}`){
                                                        obj.row[obj.row.length - 1].cells.push(s);
                                                        return false;
                                                    }

                                                    return true;
                                                });
                                            }

                                            data.push(obj);
                                        }
                                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!#$", data);
                                        return data;
                                    })()
                                }
                                days={days}
                                isLoading={isLoading}
                                goBack={goBack}
                                goToday={goToday}
                                advance={advance}
                                incrementMonth={incrementMonth}
                                dateInitial={"2024-08-25"}
                                dateFinish={"2024-08-31"}
                                Modal={ModalDisplayInformations}
                            />
                        )
                    }
                </Tab>
                <Tab eventKey="list" title="Pesquisa avançada">
                    <Card>
                        <Card.Body>
                            <Filter
                                toFilter={ toFilter }
                                content={ <FilterContent toFilter={toFilter} /> }
                            />
                            {
                                isLoading ? <div><Spinner animation="border" /></div> :
                                <Table
                                    data={ appointments }
                                    config={ config }
                                    Actions={ TableActions }
                                />
                            }
                            {
                                filters.totalPages > 1 ? (
                                    <Row>
                                        <Col>
                                            <PaginationComponent
                                                filters={ Object.assign({}, filters, {
                                                    activePage: filters.activePage
                                                }) }
                                                toFilter={ toFilter }
                                            />
                                        </Col>
                                    </Row>
                                ) : null
                            }
                        </Card.Body>
                    </Card>
                </Tab>
            </Tabs>
        </WrapperAdminAppointmentCalendar>
    );
}