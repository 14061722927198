const INITIAL_STATE = {
    isLoading: false,
    services: []
}

const serviceReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_SERVICE':
            return {
                ...state, 
                services: payload,
                isLoading: false
            };
        case 'INCLUDE_SERVICE':
            return {
                ...state, 
                services: [...state.services, payload]
            };
        case 'IS_LOADING_SERVICE':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_SERVICE':
            return {
                ...state,
                services: state.services.map(service=>(service.id === payload.id) ? payload : service)
            };
        case 'DELETE_SERVICE':
            return {
                ...state, 
                services: state.services.filter(service=>service.id != payload.id)
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default serviceReducer;