import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import WrapperPagination from './style';

export default function PaginationComponent({filters, toFilter}) {

    const changePage = (e, currentPage)=>{
        toFilter(Object.assign({}, filters, {activePage: currentPage}));
    }

    return (
        <WrapperPagination>
            <Row>
                <Col>
                    <Pagination size="small" page={ Number(filters.activePage || 1) } onChange={(e, currentPage)=>changePage(e, currentPage)} count={ filters.totalPages } />
                </Col>
            </Row>
        </WrapperPagination>
    );
}