import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pages from './Pages';
import store from './store';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import ModalLogin from './Components/ModalLogin';
import ModalEditProfile from './Components/ModalEditProfile';
import 'react-notifications/lib/notifications.css';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <ModalLogin />
        <ModalEditProfile />
        <NotificationContainer />
        <Pages />
      </div>
    </Provider>
  );
}

export default App;
