import styled from 'styled-components';
import WrapperModalContent from '../../../styles/modalContent';

export const WrapperCaptureAuth = styled(WrapperModalContent)`
    .otp-verification {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 16px;

        input {
            font-size: 1.25rem;
            height: 3rem;
            width: 3rem;
            text-align: center;

            &:focus {
                border-color: black;
            }
        }
    }

    button.submit {
        margin-bottom: 0.5rem;
    }

    .await-and-resend {

        display: flex;
        justify-content: center;

        button {
            text-transform: uppercase;
            background-color: unset;
            border-color: #9e9e9e;
            color: #9e9e9e;

            &[disabled] {
                background-color: unset;
            }

            &:active {
                background-color: unset;
                color: #9e9e9e;
                border-color: #9e9e9e;
            }

            &:hover {
                background-color: #9e9e9e;
                color: white;
            }
        }
    }
`;

export default WrapperCaptureAuth;