import { Accordion, Card, Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header";
import WrapperService from "./style";
import { http } from "../../services";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../utils";

export default function Service(){

    const dispatch = useDispatch();
    const { services } = useSelector(state => state.serviceState);

    useEffect(()=>{
        http.service.findAll()
            .then(result=>dispatch({
                type: "LOAD_SERVICE",
                payload: result.data
            }));
    }, []);

    return (
        <WrapperService>
            <Header />
            <Container>
                <Row>
                    <Col>
                        <h1 className="title">Serviços</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="center">
                            {/* <div className="login">
                                <h3>Identifique-se</h3>
                                <p>Entre na sua conta para ter acesso aos seus agendamentos</p>
                                <WrapperButtonSubmit onClick={()=>dispatch({
                                    type: "SET_OPEN_MODAL_LOGIN",
                                    payload: true
                                })}>Entrar</WrapperButtonSubmit>
                            </div> */}
                            <Row className="list-services">
                                {
                                    services.map((service, index) => (
                                        // <Accordion.Item eventKey={ `${index}` }>
                                        //     <Accordion.Header>{ service.name }</Accordion.Header>
                                        //     <Accordion.Body>
                                        //         <p><b>Duração:</b> { service.duration }h</p>
                                        //         <p><b>Descrição:</b> { service.description }</p>
                                        //         {
                                        //             service.showPrice ? <p><b>Valor:</b> { utils.formatPrice(service.price) }</p> : false
                                        //         }
                                        //     </Accordion.Body>
                                        // </Accordion.Item>
                                        <Col md={6} lg={ 4 }>
                                            <Card>
                                                <Card.Header>{ service.name }</Card.Header>
                                                <Card.Body>
                                                    <p><b>Duração:</b> { service.duration }h</p>
                                                    <p><b>Descrição:</b> { service.description }</p>
                                                    {
                                                        service.showPrice ? <p><b>Valor:</b> { utils.formatPrice(service.price) }</p> : false
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </WrapperService>
    );
}