import React from "react";
import WrapperFooter from "./style";
import { useSelector } from "react-redux";

export default function Footer(){

    const { accountClient } = useSelector(state => state.accountState);

    return (
        <WrapperFooter>
            <span>{ accountClient.name } - 2024. <span className="copyright">Todos os direitos reservados</span></span>
            <span>Plataforma fornecida por <a target="_blank" href="https://chatbotparaempreendimentos.com.br"><b>Chatbot para Empreendimentos</b></a></span>
        </WrapperFooter>
    );
}