import { useState, useEffect } from "react";
import store from "../store";
import moment from "moment";

const calculateTime = time => {
    if(!time) return null;
    let diff = moment(time, "YYYY-MM-DD HH:mm:ss").diff(moment());

    return diff > 0 ? moment(diff).format("mm:ss") : "";
}

const useNextSendOTPAllowedFormat = () => {
    const [nextSendOTPAllowedFormat, setNextSendOTPAllowedFormat] = useState(null);
    const [fnInterval, setFnInterval] = useState(null);

    useEffect(() => {
        if(fnInterval) clearInterval(fnInterval);
        setNextSendOTPAllowedFormat(calculateTime(store.getState().accountState.nextSendOTPAllowed));
        setFnInterval(setInterval(()=>{
            setNextSendOTPAllowedFormat(calculateTime(store.getState().accountState.nextSendOTPAllowed));
        }, 1000));
    }, []);

    return [nextSendOTPAllowedFormat];
};

export default useNextSendOTPAllowedFormat;