import React, { useEffect, useState } from "react";
import WrapperModalLogin, { WrapperModalLoginContent } from "./style";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CaptureContact from "./CaptureContact";
import CaptureName from "./CaptureName";
import CaptureAuth from "./CaptureAuth";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import WrapperModalHeaderButton from "../../styles/modalHeaderButton";
import WrapperButtonSubmit from "../../styles/buttonSubmit";
import { http } from "../../services";
import moment from "moment";
import Loading from "../Loading";
import utils from "../../utils";

export default function ModalLogin(){

    const dispatch = useDispatch();
    const [passCurrent, setPassCurrent] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const show = useSelector(state => state.pageState.openModalLogin);
    const { user, type } = useSelector(state => state.accountState);
    const [isValid, setIsValid] = useState(false);
    const [hideButtonSubmit, setHideButtonSubmit] = useState(true);

    const handleClose = ()=>dispatch({
        type: "SET_OPEN_MODAL_LOGIN",
        payload: false
    });

    const nextStep = async ()=>{
        setIsLoading(true);
        if(passCurrent === 0){
            let result = null;
            let messageError = null;
            try {
                result = await http.client.hasRegister(`55${user.contact.replace(/\D/g, "")}`)
                    .finally(()=>setIsLoading(false));
            } catch (error) {
                messageError = error?.response?.data;
            }
            if(messageError){
                return utils.createNotification({
                    type: 'error',
                    title: "Falha no sistema",
                    message: messageError,
                    timing: 15000
                });
            }
            if(!result.data.hasWhatsApp) return utils.createNotification({
                type: 'error',
                title: "Digite um número de WhatsApp válido.",
                timing: 15000
            });
            if(!result.data.hasRegister) return setPassCurrent(1);
            dispatch({
                type: 'SET_USER',
                payload: Object.assign({}, user, {
                    hasPassword: result.data.hasPassword,
                    clientId: result.data.clientId
                })
            });
            http.client.createOTP(result.data.clientId)
                .then(r => {
                    dispatch({
                        type: "SET_NEXT_SEND_OTP_ALLOWED",
                        payload: r.data.otp.nextSendOTPAllowed
                    });
                });

            return setPassCurrent(2);
        }
        http.client.create({
            contact: `55${user.contact.replace(/\D/g, "")}`,
            name: user.name
        })
            .then(()=>setPassCurrent(2))
            .finally(()=>setIsLoading(false));
    }

    useEffect(()=>{
        setPassCurrent(0);
        setIsLoading(false);
        setHideButtonSubmit(true);
    }, [show]);

    useEffect(()=>{
        if(passCurrent === 0 && typeof user?.contact === "string"){
            setIsValid(Boolean(user?.contact.match(/^\(?\d{2}\)?\s?9?\d{4}-?\d{4}$/)));
        }
    }, [user?.contact]);

    useEffect(()=>{
        if(passCurrent === 0) setHideButtonSubmit(true);
    }, [passCurrent]);

    return (
        <WrapperModalLogin>
            <Modal className="modal-login" show={show} onHide={handleClose} centered>
                <WrapperModalLoginContent>
                    <Modal.Header className="buttons-customs">
                        <WrapperModalHeaderButton onClick={()=>{
                            setPassCurrent(passCurrent === 2 ? 0 : passCurrent-1);
                        }} disabled={passCurrent < 1}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </WrapperModalHeaderButton>
                        <WrapperModalHeaderButton onClick={handleClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </WrapperModalHeaderButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    {
                                        ({
                                            0: <CaptureContact />,
                                            1: <CaptureName />,
                                            2: <CaptureAuth handleClose={ handleClose } setIsLoading={ setIsLoading } setHideButtonSubmit={ setHideButtonSubmit } />
                                        })[passCurrent]
                                    }
                                    {
                                        hideButtonSubmit ? <WrapperButtonSubmit disabled={!isValid} onClick={nextStep} className="submit">Confirmar</WrapperButtonSubmit> : false
                                    }
                                </>
                            )
                        }
                    </Modal.Body>
                </WrapperModalLoginContent>
            </Modal>
        </WrapperModalLogin>
    );
}