import moment from "moment";
import WrapperTableSchedule from "./style";
import { Table, Button, ButtonGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import _ from "lodash";

export default function TableSchedule({goBack, advance, goToday, isLoading, days, incrementMonth, Modal, data}){

    const [loading, setLoading] = useState(isLoading === "boolean" ? isLoading : false);

    useEffect(()=>{
        if(typeof isLoading === "boolean") setLoading(isLoading);
    }, [isLoading]);

    return (
        <WrapperTableSchedule>
            {
                loading ? (
                    <Spinner border="animation" />
                ) : (
                    <>
                        <div className="mb-3 control">
                            <ButtonGroup className="goback-advance">
                                <Button onClick={goBack}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </Button>
                                <Button onClick={advance}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                            </ButtonGroup>
                            <span className="month-current"><b>{ moment(days[0].day, "YYYY-MM-DD").format("MMMM [de] YYYY") }</b></span>
                            <ButtonGroup className="Shortcuts">
                                <Button onClick={goToday}>
                                    Hoje
                                </Button>
                                <Button onClick={incrementMonth}>
                                    +1 mês
                                </Button>
                            </ButtonGroup>
                        </div>
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {
                                        days.map(d=>{

                                            const dayWeek = moment(d.day, "YYYY-MM-DD").format("ddd");

                                            return (
                                                <th className="col-2">{ moment(d.day, "YYYY-MM-DD").format(`DD [${dayWeek.charAt(0).toUpperCase()+dayWeek.substring(1)}]`) }</th>
                                            );
                                        })
                                    }
                                    {/* <th>29 Dom</th>
                                    <th>30 Seg</th>
                                    <th>31 Ter</th>
                                    <th>01 Qua</th>
                                    <th>02 Qui</th>
                                    <th>03 Sex</th>
                                    <th>04 Sáb</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map(s=>{
                                        return (
                                            <tr>
                                                <td><b>{ s.time }</b></td>
                                                {
                                                    s.row.map(sp => {
                                                        return (
                                                            <td className={sp.disabled ? "disabled" : ""}>
                                                                {
                                                                    sp.cells.map(c => {
                                                                        return (
                                                                            <Modal
                                                                                cell={ c }
                                                                            />
                                                                        );
                                                                    })
                                                                }
                                                            </td>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </>
                )
            }
        </WrapperTableSchedule>
    );
}