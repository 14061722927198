import React, { useEffect, useState, useRef } from "react";
import WrapperCaptureAuth from "./style";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import WrapperButtonSubmit from "../../../styles/buttonSubmit";
import useNextSendOTPAllowedFormat from "../../../hooks/useNextSendOTPAllowedFormat";
import { http } from "../../../services";
import utils from "../../../utils";

export default function CaptureAuth({ setHideButtonSubmit, setIsLoading, handleClose }){

    const dispatch = useDispatch();
    const [nextSendOTPAllowedFormat] = useNextSendOTPAllowedFormat();
    const { user, nextSendOTPAllowed } = useSelector(state => state.accountState);
    
    const [input1, setInput1] = useState(null);
    const [input2, setInput2] = useState(null);
    const [input3, setInput3] = useState(null);
    const [input4, setInput4] = useState(null);
    const [input5, setInput5] = useState(null);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    useEffect(()=>{
        setHideButtonSubmit(false);
    }, []);

    return (
        <WrapperCaptureAuth>
            <p className="description"><b>Enviado para { user.contact }</b></p>
            <p className="reason">Insira abaixo o código que você receberá por WhatsApp</p>
            <div className="otp-verification">
                <Form.Control ref={ref1} value={input1} onInput={e=>{
                    if(e.target.value === ""){
                        return setInput1("");
                    }

                    if(isNaN(e.target.value)) return;
                    if(input2 === null) setInput2("");
                    setInput1(input1 ? e.target.value.replace(input1, "") : e.target.value);
                    setTimeout(()=>{
                        ref2.current.focus();
                    }, 100);
                }} className="shadow-none" type="text" />
                <Form.Control ref={ref2} value={input2} disabled={input2 === null} onInput={e=>{
                    if(e.target.value === ""){
                        setInput2("");
                        setTimeout(()=>{
                            ref1.current.focus();
                        }, 100);

                        return;
                    }

                    if(isNaN(e.target.value)) return;
                    if(input3 === null) setInput3("");
                    setInput2(e.target.value.replace(input2, ""));
                    setTimeout(()=>{
                        ref3.current.focus();
                    }, 100);
                }} className="shadow-none" type="text" />
                <Form.Control ref={ref3} value={input3} disabled={input3 === null} onInput={e=>{
                    if(e.target.value === ""){
                        setInput3("");
                        setTimeout(()=>{
                            ref2.current.focus();
                        }, 100);
                        return;
                    }

                    if(isNaN(e.target.value)) return;
                    if(input4 === null) setInput4("");
                    setInput3(e.target.value.replace(input3, ""));
                    setTimeout(()=>{
                        ref4.current.focus();
                    }, 100);
                }} className="shadow-none" type="text" />
                <Form.Control ref={ref4} value={input4} disabled={input4 === null} onInput={e=>{
                    if(e.target.value === ""){
                        setInput4("");
                        setTimeout(()=>{
                            ref3.current.focus();
                        }, 100);
                        return;
                    }

                    if(isNaN(e.target.value)) return;
                    if(input5 === null) setInput5("");
                    setInput4(e.target.value.replace(input4, ""));
                    setTimeout(()=>{
                        ref5.current.focus();
                    }, 100);
                }} className="shadow-none" type="text" />
                <Form.Control ref={ref5} value={input5} disabled={input5 === null} onInput={e=>{
                    if(e.target.value === ""){
                        setInput5("");
                        setTimeout(()=>{
                            ref4.current.focus();
                        }, 100);
                        return;
                    }

                    if(isNaN(e.target.value)) return;
                    setInput5(e.target.value.replace(input5, ""));
                }} className="shadow-none" type="text" />
            </div>
            <WrapperButtonSubmit
                onClick={()=>{
                    setIsLoading(true);
                    http.client.authOTP({
                        clientId: user.clientId,
                        code: input1 + input2 + input3 + input4 + input5
                    })
                        .then(result => {
                            utils.createNotification({
                                type: 'success',
                                title: "Login realizado com sucesso!",
                                timing: 15000
                            });
                            handleClose();
                            dispatch({
                                type: "LOGIN",
                                payload: {
                                    type: result.data.type,
                                    token: result.data.token,
                                    refreshToken: result.data.refreshToken,
                                    client: result.data.client
                                }
                            });
                        })
                        .catch(error => utils.createNotification({
                            type: 'error',
                            title: "Falha ao realizar o login",
                            message: error.response?.data || error.message,
                            timing: 15000
                        }))
                        .finally(()=>setIsLoading(false));
                }}
                className="submit"
                disabled={ !(input1 && input2 && input3 && input4 && input5) }
            >Confirmar</WrapperButtonSubmit>
            <p className="reason2 text-grey">*Para receber o código novamente, aguarde alguns instantes e clique em reenviar código</p>
            <div className="await-and-resend">
                {
                    nextSendOTPAllowedFormat ? <Button disabled>Aguarde { nextSendOTPAllowedFormat }</Button> : <Button disabled={nextSendOTPAllowedFormat === null} onClick={()=>{
                        setIsLoading(true);
                        http.client.createOTP(user.clientId)
                            .then(r => {
                                dispatch({
                                    type: "SET_NEXT_SEND_OTP_ALLOWED",
                                    payload: r.data.otp.nextSendOTPAllowed
                                });
                            })
                            .finally(()=>setIsLoading(false));
                    }}>Reenviar código</Button>
                }
            </div>
        </WrapperCaptureAuth>
    );
}