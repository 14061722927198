const INITIAL_STATE = {
    isLoading: false,
    payments: []
}

const paymentReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_PAYMENT':
            return {
                ...state, 
                payments: payload,
                isLoading: false
            };
        case 'INCLUDE_PAYMENT':
            return {
                ...state, 
                payments: [...state.payments, payload]
            };
        case 'IS_LOADING_PAYMENT':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_PAYMENT':
            return {
                ...state,
                payments: state.payments.map(payment=>(payment.id === payload.id) ? payload : payment)
            };
        case 'DELETE_PAYMENT':
            return {
                ...state, 
                payments: state.payments.filter(payment=>payment.id != payload.id)
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default paymentReducer;