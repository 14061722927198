import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const WrapperNotificationModal = styled(Modal)`
    .modal-footer {
        .btn {

            border: 0;

            &:hover {
                opacity: 0.85;
            }

            &:first-child {
                background-color: unset;
                color: var(--color-font);
            }

            &:last-child {
                background-color: var(--color-primary);
            }
        }
    }
`;