import WrapperLogin from "./style";
import WrapperButtonSubmit from "../../styles/buttonSubmit";
import { useDispatch } from "react-redux";

export default function Login({ description }){

    const dispatch = useDispatch();

    return (
        <WrapperLogin>
            <h3>Identifique-se</h3>
            <p>{description}</p>
            <WrapperButtonSubmit onClick={()=>dispatch({
                type: "SET_OPEN_MODAL_LOGIN",
                payload: true
            })}>Entrar</WrapperButtonSubmit>
        </WrapperLogin>
    );
}