import styled from 'styled-components';

export const WrapperAdminClient = styled.div`
    .table-actions {
        button {
            &:hover {
                opacity: 0.85;
                background-color: var(--color-primary);
            }
        }
    }
`;

export default WrapperAdminClient;