import { faCalendar, faGear, faTag, faUser } from "@fortawesome/free-solid-svg-icons";

const env = process.env.NODE_ENV;

const configs = {
    development: {
        host: 'http://localhost:4545',
        client2: 'http://localhost:3000',
        clientChatbotBuilder: 'http://localhost:3002',
        excludePages: {
            admin: ["schedule"]
        }
    },
    production: {
        host: '',
        client2: 'http://localhost:3000',
        clientChatbotBuilder: '',
        excludePages: {
            admin: ["schedule"]
        }
    },
    test: {}
}

export default {
    pages: {
        admin: [
            {
                title: 'Agenda',
                screen: 'admin_appointment_calendar',
                icon: faCalendar,
                url: '/admin/agenda'
            },
            {
                title: 'Agendar',
                screen: 'schedule',
                icon: faCalendar,
                url: '/admin/agenda/:clientId'
            },
            {
                title: 'Clientes',
                screen: 'client',
                icon: faUser,
                url: '/admin/clientes'
            },
            {
                title: 'Serviços',
                screen: 'service',
                icon: faTag,
                url: '/admin/servicos'
            },
            {
                title: 'Usuários',
                screen: 'user',
                icon: faUser,
                url: '/admin/usuarios'
            },
            {
                title: 'Configuração',
                screen: 'setting',
                icon: faGear,
                url: '/admin/configuracao'
            }
        ]
    },
    ...configs[env]
}