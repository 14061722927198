import styled from 'styled-components';

export const WrapperModalEditProfile = styled.div`
`;

export const WrapperModalEditProfileContent = styled.div`
    
    max-height: 100vh;

    .modal-header {
        justify-content: flex-end;
    }

    .modal-header,
    .modal-footer {
        border: 0;
    }

    .modal-body {
        max-height: calc(100vh - 142px);
        overflow-y: auto;
        scrollbar-width: thin;
    }
`;

export default WrapperModalEditProfile;