import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WrapperModal, { WrapperModalContent } from './style';
import styled, { css } from 'styled-components';

function MyVerticallyCenteredModal(props) {
  return (
    <WrapperModalContent
      styledCustom={props.config.styledCustomContent}
      {...props}
      size={ props.config.size ? props.config.size : "md" }
      aria-labelledby="contained-modal-title-vcenter"
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            { props.config.title }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            props.content
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>props.setModalShow(false)}>Voltar</Button>
          {
            props.config.ctaFn ? <Button style={ props.config.style } className='cta' onClick={()=>{
              props.setModalShow(false);
              props.config.ctaFn(props.row);
            }}>{ props.config.cta }</Button> : false
          }
        </Modal.Footer>
    </WrapperModalContent>
  );
}

export default function ModalC({content, config, row}) {

  const [modalShow, setModalShow] = React.useState(false);

  /*useEffect(()=>{
    if(modalShow){
      Object.entries(state).forEach(e=>{
        console.log('qwe', e[1][1]('oi'));
      });
    }
  }, [modalShow]);*/
  
  return (
    <WrapperModal id='modal'>
        {/*<Button style={ config.style } className='cta' variant="primary" onClick={() => setModalShow(true)}>
            { config.cta }
  </Button>*/}
      {
        config.icon ? (
          <FontAwesomeIcon
            onClick={() => setModalShow(true)}
            className='fa-danger-color'
            icon={ config.icon }
            title={ config.title }
          />
        ) : (
          <Button title={ config.title } style={ config.style } className='cta' variant="primary" onClick={() => setModalShow(true)}>
            { config.cta }
          </Button>
        )
      }

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        content={ content }
        config={ config }
        row={ row }
        setModalShow={ setModalShow }
      />
    </WrapperModal>
  );
}

