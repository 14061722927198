import React, { useEffect, useState } from "react";
import WrapperHome, { WrapperChangeOption, WrapperModalInsertObservation, WrapperModalInsertObservationContent, WrapperModalRemoveService, WrapperModalRemoveServiceContent, WrapperModalSelectDaySpecific, WrapperModalSelectDaySpecificContent } from "./style";
import TableSchedule from "../../Components/TableSchedule";
import { Button, Card, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Schedule from "../../Components/Schedule";

export default function Home(){
    return (
        <WrapperHome>
            <Header />
            <Container>
                <Schedule />
            </Container>
            <Footer />
        </WrapperHome>
    );
}