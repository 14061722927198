import { components } from 'react-select';
import WrapperSelectWithIcons from './style';
import utils from '../../utils';
const { Option } = components;

function IconOption(props) {
    const {
      data: { label, Icon },
    } = props;
  
    return (
        <Option {...props}>
            <div className="flex items-center gap-2">
            {Icon}
            <span className='label'>{label}</span>
            </div>
        </Option>
    );
}

export default function SelectWithIcons({options, placeholder, value, onChange}){
    return (
        <WrapperSelectWithIcons
            value={value}
            onChange={onChange}
            options={options.map((o)=>({
                value: o.value,
                label: o.label,
                Icon: utils.getIcon(o.value)
            }))}
            components={{ Option: IconOption }}
            styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderWidth: 0,
                  outline: 0
                }),
            }}
            placeholder={placeholder || "Selecione"}
        />
    );
}