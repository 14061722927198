const INITIAL_STATE = {
    isLoading: false,
    date: null,
    time: null,
    professionalId: null,
    services: [],
    observation: null
}

const cartReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'INCLUDE_SERVICE_CART':
            return {
                ...state, 
                services: [...state.services, payload]
            };
        case 'DELETE_SERVICE_CART':
            return {
                ...state, 
                services: state.services.filter(serviceId=>serviceId !== payload.id)
            };
        case 'SET_DATE_TIME _CART':
            return {
                ...state, 
                date: payload.date,
                time: payload.time
            };
        case 'SET_DATE_CART':
            return {
                ...state, 
                date: payload
            };
        case 'SET_HOUR_CART':
            return {
                ...state, 
                hour: payload
            };
        case 'SET_OBSERVATION_CART':
            return {
                ...state, 
                observation: payload
            };
        case 'SET_PROFESSIONAL_CART':
            return {
                ...state, 
                professionalId: payload
            };
        case 'IS_LOADING_CART':
            return {
                ...state,
                isLoading: payload
            };
        case 'CLEAR_CART':
            return {
                ...state,
                professionalId: null,
                observation: null,
                date: null,
                time: null,
                services: []
            };
        default:
            return state;
    }
}

export default cartReducer;