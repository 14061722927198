import React, { useEffect } from "react";
import WrapperLayoutDefault from "./style";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";
import useIsValidJWT from "../../hooks/useIsValidJWT";
import { http } from "../../services";
import { Button, Form, Modal } from 'react-bootstrap';
import utils from "../../utils";
import NotificationModal from "../NotificationModal";

export default function LayoutDefault({ children }){

    const { type, user } = useSelector(state=>state.accountState);
    const { socket } = useSelector(state=>state.socketState);
    const location = useLocation();
    const dispatch = useDispatch();
    const { username } = useParams();
    const [isValidTokenJWT] = useIsValidJWT();

    useEffect(()=>{
        dispatch({
            type: "SET_USERNAME",
            payload: username ? username : user?.Account?.username
        });
    }, []);

    useEffect(()=>{
        if(!isValidTokenJWT){
            const server = type === "client" ? http.client : http.user;
            server.refresh()
                .catch(err=>{
                    if(err.response.status === 401){
                        utils.createNotification({
                            type: 'error',
                            title: `Erro ao obter token de acesso!`,
                            message: "Você será deslogado!"
                        });
                    }
                });
        }
    }, [isValidTokenJWT]);

    useEffect(()=>{
        dispatch({
            type: "SET_PAGE",
            payload: location?.pathname
        });
        dispatch({
            type: "SET_CONTEXT",
            payload: location?.pathname.split("/")[1]
        });
    }, [location?.pathname]);

    return (
        <WrapperLayoutDefault>
            <NotificationModal />
            { children }
        </WrapperLayoutDefault>
    );
}