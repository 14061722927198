const INITIAL_STATE = {
    isLoading: false,
    breaks: []
}

const intervalReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_INTERVAL':
            return {
                ...state, 
                breaks: payload,
                isLoading: false
            };
        case 'INCLUDE_INTERVAL':
            return {
                ...state, 
                breaks: [...state.breaks, payload]
            };
        case 'IS_LOADING_INTERVAL':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_INTERVAL':
            return {
                ...state,
                breaks: state.breaks.map(b=>(b.id === payload.id) ? payload : b)
            };
        case 'DELETE_INTERVAL':
            return {
                ...state, 
                breaks: state.breaks.filter(b=>b.id != payload.id)
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default intervalReducer;