import styled from 'styled-components';
import WrapperModalContent from '../../../styles/modalContent';

export const WrapperCaptureEmailBirthGender = styled(WrapperModalContent)`
    .input-email {
        margin-bottom: calc(1.5rem - 8px);
    }

    .gender {
        margin-bottom: 1.75rem;
    }
`;

export default WrapperCaptureEmailBirthGender;