import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Container, Form, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import Table from "../../../Components/Table";
import Modal from '../../../Components/Modal';
import WrapperAdminClient from "./style";
import { http } from "../../../services";
import store from "../../../store";
import utils from "../../../utils";
import { css } from "styled-components";
import { faMagnifyingGlass, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "../../../Components/Pagination";
import Filter from "../../../Components/Filter";
import _ from 'lodash';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'name', translate: 'Nome' },
        { name: 'contact', translate: 'Contato' },
        { name: 'createdAt', translate: 'Registro', type: 'datetime' },
        { name: 'active', translate: 'Ativo', type: 'boolean' },
        { name: 'email', translate: 'E-mail' },
        { name: 'dateOfBirth', translate: 'Data de nascimento', type: "date" },
        { name: 'gender', translate: 'Gênero' }
    ],
    include: {
        cta: "Incluir",
        title: 'Incluir cliente',
        ctaFn: ()=>{
            http.client.createAdmin(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_CLIENT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Cliente criado com sucesso'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    timing: 15000,
                    title: 'Falha ao criar cliente',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar cliente',
        styledCustomContent: css`
            .modal-body {
                max-height: 69.5vh;
                overflow-y: auto;
            }
        `,
        icon: faPen,
        ctaFn: ({id})=>{
            http.client.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_CLIENT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do cliente ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    timing: 15000,
                    title: `Falha ao editar cliente ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Deletar",
        title: 'Deletar cliente',
        ctaFn: ({ id })=>{
            http.client.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_CLIENT',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Cliente deletado',
                        message: `O cliente ${id} foi deletado!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    timing: 15000,
                    title: `Falha ao deletar cliente ${id}`,
                    message: error.response.data
                }));
        },
        icon: faTrash,
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function IncludeContent(){

    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [active, setActive] = useState(true);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, contact, email, dateOfBirth: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : "", gender, active
            }
        });
    }, [name, contact, email, dateOfBirth, gender, active]);

    return (
        <Form>
            <Container fluid>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ name }
                                type="text"
                                placeholder="Nome"
                                onChange={e=>setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formContact">
                            <Form.Label>Contato</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ contact }
                                type="text"
                                placeholder="Digite o contato do cliente"
                                onChange={e=>setContact(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ email }
                                type="email"
                                placeholder="Digite o e-mail do cliente"
                                onChange={e=>setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formPrice">
                                    <Form.Label>Data de nascimento</Form.Label>
                                    <Form.Control
                                        value={ dateOfBirth }
                                        className="shadow-none"
                                        type="date"
                                        onChange={e=>setDateOfBirth(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formGender">
                                    <Form.Label>Gênero</Form.Label>
                                    <Form.Select value={gender} onChange={e=>setGender(e.target.value)} className="shadow-none">
                                        <option></option>
                                        <option>Prefiro não informar</option>
                                        <option>Feminino</option>
                                        <option>Masculino</option>
                                        <option>Não binário</option>
                                        <option>Outro</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="formActive">
                            <Form.Check // prettier-ignore
                                checked={active}
                                type={"checkbox"}
                                id={"formActive"}
                                label={"Ativo"}
                                onChange={e=>setActive(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function UpdateContent(props){

    const dispatch = useDispatch();

    const [name, setName] = useState(props.name);
    const [contact, setContact] = useState(props.contact);
    const [email, setEmail] = useState(props.email);
    const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? moment(props.dateOfBirth).format("YYYY-MM-DD") : "");
    const [gender, setGender] = useState(props.gender);
    const [active, setActive] = useState(props.active);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, contact, email, dateOfBirth: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : "", gender, active
            }
        });
    }, [name, contact, email, dateOfBirth, gender, active]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ name }
                                type="text"
                                placeholder="Nome"
                                onChange={e=>setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formContact">
                            <Form.Label>Contato</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ contact }
                                type="text"
                                placeholder="Digite o contato do cliente"
                                onChange={e=>setContact(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                className="shadow-none"
                                value={ email }
                                type="email"
                                placeholder="Digite o e-mail do cliente"
                                onChange={e=>setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formPrice">
                                    <Form.Label>Data de nascimento</Form.Label>
                                    <Form.Control
                                        value={ dateOfBirth }
                                        className="shadow-none"
                                        type="date"
                                        onChange={e=>setDateOfBirth(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formGender">
                                    <Form.Label>Gênero</Form.Label>
                                    <Form.Select value={gender} onChange={e=>setGender(e.target.value)} className="shadow-none">
                                        <option></option>
                                        <option>Prefiro não informar</option>
                                        <option>Feminino</option>
                                        <option>Masculino</option>
                                        <option>Não binário</option>
                                        <option>Outro</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="formActive">
                            <Form.Check // prettier-ignore
                                checked={active}
                                type={"checkbox"}
                                id={"formActive"}
                                label={"Ativo"}
                                onChange={e=>setActive(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja deletar o cliente {id}?</p>
    );
}

function TableActions(props){

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="table-actions">
            {
                searchParams.get("after_select_redirect_url") ? (
                    <Link to={`/admin/agenda?clientIdToSchedule=${props.row.id}`}><Button>Selecionar</Button></Link>
                ) : (
                    <>
                        <Modal
                            config={ config.update }
                            row={ props.row }
                            content={ <UpdateContent
                                { ...props.row }
                            /> }
                        />
                        <Modal
                            config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                            row={ props.row }
                            content={ <RemoveContent
                                { ...props.row }
                            /> }
                        />
                    </>
                )
            }
        </div>
    );
}

function FilterContent(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get("id") || '');
    const [name, setName] = useState(searchParams.get("name") || '');
    const [contact, setContact] = useState(searchParams.get("contact") || '');
    const [dateRegisterInitial, setDateRegisterInitial] = useState(searchParams.get('dateRegisterInitial') || '');
    const [dateRegisterFinish, setDateRegisterFinish] = useState(searchParams.get('dateRegisterFinish') || '');
    const [dateOfBirthInitial, setDateOfBirthInitial] = useState(searchParams.get('dateOfBirthInitial') || '');
    const [dateOfBirthFinish, setDateOfBirthFinish] = useState(searchParams.get('dateOfBirthFinish') || '');
    const [active, setActive] = useState(searchParams.get("isActive") || '');
    const [gender, setGender] = useState(searchParams.get("gender") || '');
    const [email, setEmail] = useState(searchParams.get("email") || '');

    useEffect(()=>{
        if(!dateRegisterInitial) setDateRegisterFinish('');
        if(!dateRegisterFinish || (dateRegisterInitial > dateRegisterFinish)) setDateRegisterFinish(dateRegisterInitial);
    }, [dateRegisterInitial]);

    useEffect(()=>{
        if(!dateRegisterFinish) setDateRegisterInitial('');
        if(!dateRegisterInitial || (dateRegisterFinish < dateRegisterInitial)) setDateRegisterInitial(dateRegisterFinish);
    }, [dateRegisterFinish]);

    useEffect(()=>{
        if(!dateOfBirthInitial) setDateOfBirthFinish('');
        if(!dateOfBirthFinish || (dateOfBirthInitial > dateOfBirthFinish)) setDateOfBirthFinish(dateOfBirthInitial);
    }, [dateOfBirthInitial]);

    useEffect(()=>{
        if(!dateOfBirthFinish) setDateOfBirthInitial('');
        if(!dateOfBirthInitial || (dateOfBirthFinish < dateOfBirthInitial)) setDateOfBirthInitial(dateOfBirthFinish);
    }, [dateOfBirthFinish]);

    const toFilter = (obj={})=>{
        let formatSearchParams = _.merge({}, obj);
        if(searchParams.get("after_select_redirect_url")) formatSearchParams.after_select_redirect_url = searchParams.get("after_select_redirect_url");
        setSearchParams(formatSearchParams);
        dispatch({
            type: 'IS_LOADING_CLIENT',
            payload: true
        });
        http.client.findAll(obj)
            .then(result=>{
                dispatch({
                    type: 'LOAD_CLIENT',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao filtrar clientes',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_CLIENT',
                payload: false
            }));
    }

    return (
        <Row>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="formId">
                    <Form.Label>Id</Form.Label>
                    <Form.Control className="shadow-none" value={id} onChange={e=>setId(e.target.value)} type="text" />
                </Form.Group>
            </Col>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control className="shadow-none" value={name} onChange={e=>setName(e.target.value)} type="text" />
                </Form.Group>
            </Col>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="formContact">
                    <Form.Label>Contato</Form.Label>
                    <Form.Control className="shadow-none" value={contact} onChange={e=>setContact(e.target.value)} type="text" />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="formDateRegisterInitial">
                    <Form.Label>Data de registro inicial</Form.Label>
                    <Form.Control className="shadow-none" value={dateRegisterInitial} onChange={e=>setDateRegisterInitial(e.target.value)} type="date" />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="formDateRegisterFinish">
                    <Form.Label>Data de registro final</Form.Label>
                    <Form.Control className="shadow-none" value={dateRegisterFinish} onChange={e=>setDateRegisterFinish(e.target.value)} type="date" />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="active">
                    <Form.Label>Ativo</Form.Label>
                    <Form.Select
                        className="shadow-none"
                        value={active}
                        onChange={e=>setActive(e.target.value)}
                    >
                        <option value={ "" }></option>
                        <option value={ "1" }>Sim</option>
                        <option value={ "0" }>Não</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="gender">
                    <Form.Label>Gênero</Form.Label>
                    <Form.Select
                        className="shadow-none"
                        value={gender}
                        onChange={e=>setGender(e.target.value)}
                    >
                        <option></option>
                        <option>Prefiro não informar</option>
                        <option>Feminino</option>
                        <option>Masculino</option>
                        <option>Não binário</option>
                        <option>Outro</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="formDateOfBirthInitial">
                    <Form.Label>Data de nascimento i</Form.Label>
                    <Form.Control className="shadow-none" value={dateOfBirthInitial} onChange={e=>setDateOfBirthInitial(e.target.value)} type="date" />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="formDateOfBirthFinish">
                    <Form.Label>Data de nascimento f</Form.Label>
                    <Form.Control className="shadow-none" value={dateOfBirthFinish} onChange={e=>setDateOfBirthFinish(e.target.value)} type="date" />
                </Form.Group>
            </Col>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control className="shadow-none" value={email} onChange={e=>setEmail(e.target.value)} type="text" />
                </Form.Group>
            </Col>
            <Col>
                {<Form.Group id="filter-btn" className="mb-3" controlId="formBasicEmail">
                    <Form.Label></Form.Label>
                        <Button onClick={()=>toFilter({
                            activePage: 1,
                            isActive: active,
                            dateRegisterInitial,
                            dateRegisterFinish,
                            name,
                            email,
                            dateOfBirthInitial,
                            dateOfBirthFinish,
                            gender,
                            contact,
                            id
                        })} variant="success">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                </Form.Group>}
            </Col>
        </Row>
    );
}

export default function AdminClient(){

    const { clients, isLoading, filters } = useSelector(state=>state.clientState);
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id") || '';
    const name = searchParams.get("name") || '';
    const contact = searchParams.get("contact") || '';
    const dateRegisterInitial = searchParams.get('dateRegisterInitial') || '';
    const dateRegisterFinish = searchParams.get('dateRegisterFinish') || '';
    const dateOfBirthInitial = searchParams.get('dateOfBirthInitial') || '';
    const dateOfBirthFinish = searchParams.get('dateOfBirthFinish') || '';
    const active = searchParams.get("isActive") || '';
    const gender = searchParams.get("gender") || '';
    const email = searchParams.get("email") || '';

    useEffect(()=>{
        toFilter({
            id, name, contact, dateRegisterInitial, dateRegisterFinish, dateOfBirthInitial,
            dateOfBirthFinish, active, gender, email
        });

        if(searchParams.get("after_select_redirect_url")){
            utils.createNotification({
                type: 'warning',
                title: 'Selecione um cliente'
            });
        }
    }, []);

    function toFilter(obj){
        let newObj = Object.assign({
            activePage: 1,
            orderByField: filters.orderByField,
            orderByDirection: filters.orderByDirection
        }, obj);
        if(searchParams.get("after_select_redirect_url")) newObj.after_select_redirect_url = searchParams.get("after_select_redirect_url");
        dispatch({
            type: 'IS_LOADING_CLIENT',
            payload: true
        });

        http.client.findAll(newObj)
            .then(result=>{
                console.log(result.data)
                dispatch({
                    type: "LOAD_CLIENT",
                    payload: result.data
                });
            })
            .finally(()=>dispatch({
                type: "IS_LOADING_CLIENT",
                payload: false
            }));
    }

    return (
        <WrapperAdminClient>
            <Card>
                <Card.Body>
                    <Filter 
                        content={ <FilterContent /> }
                    />
                    <Modal
                        config={ config.include }
                        content={ <IncludeContent
                        /> }
                    />
                    {
                        isLoading ? <div><Spinner animation="border" /></div> :
                        <Table
                            data={ clients }
                            config={ config }
                            Actions={ TableActions }
                        />
                    }
                    {
                        filters.totalPages > 1 ? (
                            <Row>
                                <Col>
                                    <PaginationComponent
                                        filters={ Object.assign({}, filters, {
                                            activePage: filters.activePage
                                        }) }
                                        toFilter={ toFilter }
                                    />
                                </Col>
                            </Row>
                        ) : null
                    }
                </Card.Body>
            </Card>
        </WrapperAdminClient>
    );
}