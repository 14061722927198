import styled from 'styled-components';

export const WrapperTableSchedule = styled.div`

    .control {

        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            &:hover {
                background-color: var(--color-primary);
                opacity: 0.85;
            }
        }
            
        .month-current:first-letter {
            text-transform: uppercase;
        }
    }

    table {

        thead th {
            white-space: nowrap;
        }

        tbody td {
            height: 80px;

            &.disabled {
                background-color: #F5F5F5;
            }

            button {
                width: 100%;
                padding: 0;
                font-size: 12px;
                background-color: #E9C46A;
                border: none;
                &:hover,
                &:active {
                    background-color: #E9C46A !important;
                    opacity: 0.85;
                }

                &.badge-danger {
                    background-color: var(--color-danger);

                    &:hover,
                    &:active {
                        background-color: var(--color-danger) !important;
                    }
                }

                &.badge-gray {
                    background-color: #d6d6d6;

                    &:hover,
                    &:active {
                        background-color: #d6d6d6 !important;
                    }
                }
            }
        }
    }
`;

export default WrapperTableSchedule;