import { useState, useEffect } from "react";
import { WrapperSchedule, WrapperChangeOption, WrapperModalInsertObservation, WrapperModalInsertObservationContent, WrapperModalRemoveService, WrapperModalRemoveServiceContent, WrapperModalSelectDaySpecific, WrapperModalSelectDaySpecificContent } from "./style";
import TableSchedule from "../../Components/TableSchedule";
import { Button, Card, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { http } from "../../services";
import utils from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft, faCalendar, faClock, faClose, faTrash, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import "dayjs/locale/pt-br";
import 'moment/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';
import moment from "moment";
import Login from "../../Components/Login";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";

function ModalSelectDaySpecific({ disableAuthOfClient, isLoading, getSuggestedDates, value, setValue, serviceSelected }){

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const professionals = useSelector(state=>state.userState.users);
    const { user, accountClient } = useSelector(state=>state.accountState);
    const { professionalId } = useSelector(state=>state.cartState);
    const changeValue = newValue => {
        setValue(newValue);
        setShow(false);
        getSuggestedDates(moment(newValue.$d).format("YYYY-MM-DD"));
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <WrapperModalSelectDaySpecific>
            {
                professionals.length === 1 ? (
                    <ChangeOptions text={serviceSelected?.name} onClick={()=>dispatch({
                        type: "DELETE_SERVICE_CART",
                        payload: {
                            id: serviceSelected.id
                        }
                    })} />
                ) : (
                    <ChangeOptions text={professionals.find(professional=>professional.id === professionalId)?.displayNameProfessional} onClick={()=>dispatch({
                        type: "SET_PROFESSIONAL_CART",
                        payload: null
                    })} />
                )
            }
            <Button variant="primary" onClick={handleShow} disabled={isLoading}>
                <span className="subtitle">Data</span>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span className="description">
                        {
                            value ? moment(value.$d).format("dddd, DD [de] MMMM [de] YYYY") : "Selecione um dia específico"
                        }
                    </span>
                    <FontAwesomeIcon icon={faCalendar} />
                </div>
            </Button>
            <WrapperModalSelectDaySpecificContent show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pt-br"
                        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <DateCalendar minDate={disableAuthOfClient ? undefined : dayjs(moment().add((accountClient || user.Account).advanceForScheduling, "minutes").format("YYYY-MM-DD HH:mm:ss"))} maxDate={disableAuthOfClient ? undefined : dayjs(moment().add((accountClient || user.Account).periodLimitForScheduling, "minutes").format("YYYY-MM-DD HH:mm:ss"))} value={value} onChange={(newValue) => changeValue(newValue)} />
                    </LocalizationProvider>
                </Modal.Body>
            </WrapperModalSelectDaySpecificContent>
        </WrapperModalSelectDaySpecific>        
    );
}

function ChangeOptions({ onClick, text }){
    return (
        <WrapperChangeOption>
            <span className="service-name">{text}</span>
            <Button onClick={onClick}>
                <span>Alterar</span>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} />
            </Button>
        </WrapperChangeOption>
    );
}

function Professionals({serviceSelected}){

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { users: professionals } = useSelector(state=>state.userState);

    useEffect(()=>{
        http.user.findProfessionalsPublic()
            .then(result => dispatch({
                type: "LOAD_USER",
                payload: result.data
            }))
            .finally(()=>setIsLoading(false));
    }, []);

    useEffect(()=>{
        if(professionals.length === 1) dispatch({
            type: "SET_PROFESSIONAL_CART",
            payload: professionals[0].id
        });
    }, [professionals]);

    return (
        <Row className="select-professional">
            <ChangeOptions text={serviceSelected?.name} onClick={()=>dispatch({
                type: "DELETE_SERVICE_CART",
                payload: {
                    id: serviceSelected.id
                }
            })} />
            <h2 className="title">Selecione o profissional</h2>
            {
                isLoading ? <Spinner animation="border" /> : (
                    <div className="professionals">
                        <Row>
                            {
                                professionals.map(professional => (
                                    <Col md={4}>
                                        <Card className="professional mb-4">
                                            <Card.Body>
                                                <div className="center">
                                                    <FontAwesomeIcon icon={faUserTie} />
                                                </div>
                                                <h6 className="title">{professional.displayNameProfessional}</h6>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button onClick={()=>dispatch({
                                                    type: "SET_PROFESSIONAL_CART",
                                                    payload: professional.id
                                                })}>Selecionar</Button>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                )
            }
        </Row>
    );
}

function Times({disableAuthOfClient, serviceSelected}){

    const { professionalId, services } = useSelector(state => state.cartState);
    const { user } = useSelector(state => state.accountState);
    const [suggestedDates, setSuggestedDates] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [daySelected, setDaySelected] = useState();
    const dispatch = useDispatch();
    const params = useParams();
    
    const getSuggestedDates = (daySelected) => {
        setIsLoading(true);
        http.scheduling.suggestedDates({clientId: disableAuthOfClient ? params.clientId : (user?.id || ""), daySelected, professionalId, serviceId: services[0]})
            .then(result=>setSuggestedDates(result.data))
            .catch(err=>utils.createNotification({
                type: 'error',
                title: `Falha ao buscar datas sugeridas`,
                message: err.response.data
            }))
            .finally(()=>setIsLoading(false));
    }

    useEffect(()=>{
        getSuggestedDates();
    }, []);

    return (
        <Row className="select-time">
            <ModalSelectDaySpecific disableAuthOfClient={disableAuthOfClient} serviceSelected={serviceSelected} value={daySelected} setValue={setDaySelected} isLoading={isLoading} getSuggestedDates={getSuggestedDates} />
            {
                isLoading ? <Spinner animation="border" /> : (
                    <>
                        <h2 className="title">{ daySelected ? "Horários disponíveis" : "Datas sugeridas" }</h2>
                        {
                            Object.entries(suggestedDates).map(([day, times]) => (
                                <div className="day">
                                    <p><b>{ (()=>{
                                        let dayName = moment(day, "YYYY-MM-DD").locale('pt-br').format('dddd');

                                        return `${dayName.charAt(0).toUpperCase()}${dayName.substring(1)}`;
                                    })() }</b>, { (()=>{
                                        return moment(day, "YYYY-MM-DD").locale('pt-br').format('DD [de] MMMM [de] YYYY');
                                    })() }</p>
                                    <div className="times">
                                        {
                                            times.map(t => (
                                                <Button className="time" onClick={()=>dispatch({
                                                    type: "SET_DATE_TIME _CART",
                                                    payload: {
                                                        date: day,
                                                        time: t
                                                    }
                                                })}>
                                                    <FontAwesomeIcon icon={faClock} />
                                                    <span>{moment(t, "HH:mm:ss").format("HH:mm")}</span>
                                                </Button>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </>
                )
            }
        </Row>
    );
}

function ModalInsertObservation(){

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { observation: observationCurrent } = useSelector(state => state.cartState);
    const [observation, setObservation] = useState(observationCurrent || "");

    const confirm = ()=>{
        setShow(false);
        dispatch({
            type: "SET_OBSERVATION_CART",
            payload: observation
        });
    }

    const remove = ()=>{
        setShow(false);
        setObservation("");
        dispatch({
            type: "SET_OBSERVATION_CART",
            payload: null
        });
    }

    return (
        <WrapperModalInsertObservation>
            {/* <Button onClick={handleShow}>
                <FontAwesomeIcon icon={faTrash} />
            </Button> */}
            <a onClick={handleShow}>{observationCurrent ? "Alterar" : "Adicionar"} observação</a>
            <span className="observation">{observationCurrent}</span>

            <WrapperModalInsertObservationContent show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>
                        <span>Observação</span>
                        <Button onClick={handleClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Insira uma observação</Form.Label>
                        <Form.Control value={observation} onChange={e=>setObservation(e.target.value)} className="shadow-none" as="textarea" rows={3} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        observationCurrent ? (
                            <Button className="close" onClick={remove}>
                                Remover
                            </Button>
                        ) : null
                    }
                    <Button className="confirm" onClick={confirm}>
                        {
                            observationCurrent ? "Editar" : "Confirmar"
                        }
                    </Button>
                </Modal.Footer>
            </WrapperModalInsertObservationContent>
        </WrapperModalInsertObservation>
    );
}

function ModalRemoveService({ serviceSelected }){

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { date, time } = useSelector(state => state.cartState);

    return (
        <WrapperModalRemoveService>
            <Button onClick={handleShow}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>

            <WrapperModalRemoveServiceContent show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>
                        <span>Remover</span>
                        <Button onClick={handleClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Tem certeza?</h6>
                    <p>Deseja remover <b>{serviceSelected?.name}</b> às {moment(time, "HH:mm:ss").format("HH:mm")} de {moment(time, "HH:mm:ss").format("dddd")}, {moment(date, "YYYY-MM-DD").format("DD [de] MMMM")} do pedido?</p>
                </Modal.Body>
                <Modal.Footer>
                <Button className="close" onClick={handleClose}>
                    Não
                </Button>
                <Button className="confirm" onClick={()=>{
                    handleClose();
                    dispatch({
                        type: "CLEAR_CART"
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Pedido removido'
                    });
                }}>
                    Sim
                </Button>
                </Modal.Footer>
            </WrapperModalRemoveServiceContent>
        </WrapperModalRemoveService>
    );
}

function ServicesSelected({ payments, disableAuthOfClient, professionalId, observation, date, time, serviceSelected, setMaintainDisplayServicesSelecteds }){

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { token, type, username, user } = useSelector(state => state.accountState);
    const [isLoading, setIsLoading] = useState(false);
    const confirmScheduling = ()=>{
        setIsLoading(true);

        (disableAuthOfClient ? http.scheduling.createAdmin : http.scheduling.create)({
            schedulings: [
                {
                    observation,
                    clientId: params?.clientId,
                    professionalId,
                    date: `${date} ${time}`,
                    services: [
                        serviceSelected.id
                    ]
                }
            ]
        })
            .then(result=>{
                utils.createNotification({
                    type: 'success',
                    title: 'Agendamento realizado com sucesso!'
                });
                navigate(disableAuthOfClient ? `/admin/agenda?tab=list&id=${result.data[0].id}` : `/${username}/agendamentos`);
                dispatch({
                    type: "CLEAR_CART"
                });
                if(disableAuthOfClient){
                    dispatch({
                        type: "SET_MESSAGE_NOTIFICATION",
                        payload: `Olá! Agendamos o seu atendimento para o dia ${moment(result.data[0].date).format("DD/MM/YYYY [às] HH:mm")}.\nServiços:\n${result.data[0].services.map(s=>`-${s.name}\n`)}\n📍 ${user.Account.address}, ${user.Account.number}, ${user.Account.city}`
                    });
                    dispatch({
                        type: "SET_MODAL_OPEN_NOTIFICATION",
                        payload: true
                    });
                    dispatch({
                        type: "SET_CONTACT_NOTIFICATION",
                        payload: result.data[0].Client.contact
                    });
                }
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Falha ao realizar agendamento',
                message: error.response.data
            }))
            .finally(()=>setIsLoading(false));
    }

    useEffect(()=>{
        setMaintainDisplayServicesSelecteds(true);
    }, []);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center pt-4">
            {
                date && time ? (
                    <div className="services-selected">
                        <div className="service">
                            <div className="column date">
                                <span className="month"><b>{moment(date, "YYYY-MM-DD").format("MMMM")}</b></span>
                                <span className="day"><b>{moment(date, "YYYY-MM-DD").format("DD")}</b></span>
                                <span className="time">{moment(time, "HH:mm:ss").format("HH:mm")}</span>
                            </div>
                            <span className="separator"></span>
                            <div className="column description">
                                <span className="name">{serviceSelected?.name}</span>
                                <span className="price">{
                                    serviceSelected?.showPrice ? (
                                        utils.formatPrice(serviceSelected?.price)
                                    ) : "CONSULTAR NO LOCAL"
                                }</span>
                            </div>
                            <ModalRemoveService serviceSelected={serviceSelected} />
                        </div>
                    </div>
                ) : (
                    <div className="message-order-empty">
                        <span>Seu pedido está vazio</span>
                        <button onClick={()=>setMaintainDisplayServicesSelecteds(false)}>
                            Faça um agendamento
                        </button>
                    </div>
                )
            }
            {
                !disableAuthOfClient && !(token && type === "client") ? <Login description="Entre na sua conta para fazer o agendamento" /> : (
                    serviceSelected ? (
                        <div className="confirm-finish-order">
                            <div className="information">
                                <div className="payments">
                                    <span><b>Formas de pagamento</b></span>
                                    <hr />
                                    <div className="icons-and-description">
                                        <div className="icons">
                                            {
                                                payments.map(payment => utils.getIcon(payment.icon, payment.name))
                                            }
                                        </div>
                                        <span>Pagar no dia / Presencial</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="total">
                                    <span><b>Total</b></span>
                                    <span><b>{ serviceSelected?.showPrice ? utils.formatPrice(serviceSelected.price) : "Consulte no local" }</b></span>
                                </div>
                                <hr />
                                <ModalInsertObservation />
                                <hr />
                            </div>
                            {
                                isLoading ? (
                                    <div className="d-flex justify-content-center"><Spinner animation="border" /></div>
                                ) : <Button onClick={confirmScheduling}>Concluir agendamento</Button>
                            }
                        </div>
                    ) : null
                )
            }
        </div>
    );
}

export default function Schedule({ disableAuthOfClient }){

    const dispatch = useDispatch();
    const { accountClient } = useSelector(state => state.accountState);
    const { services: servicesSelecteds, professionalId, date, time, observation } = useSelector(state => state.cartState);
    const { services } = useSelector(state => state.serviceState);
    const [isLoading, setIsLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [serviceSelected, setServiceSelected] = useState((()=>{
        let serviceId = servicesSelecteds.length ? servicesSelecteds[0] : null;
        return serviceId ? services.find(s=>s.id === serviceId) : serviceId;
    })());
    const [maintainDisplayServicesSelecteds, setMaintainDisplayServicesSelecteds] = useState(false);

    useEffect(()=>{
        http.service.findPublic()
            .then(result=>dispatch({
                type: "LOAD_SERVICE",
                payload: result.data
            }));

        if(accountClient){
            setPayments(accountClient.payments);
            setIsLoading(false);
        } else {
            http.payment.findPublic()
                .then(result=>setPayments(result.data))
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Falha ao buscar pagamentos',
                    message: error.response.data,
                    timing: 15000
                }))
                .finally(()=>setIsLoading(false));
        }
    }, []);

    useEffect(()=>{
        let serviceId = servicesSelecteds.length ? servicesSelecteds[0] : null;
        setServiceSelected(serviceId ? services.find(s=>s.id === serviceId) : serviceId);
    }, [servicesSelecteds]);

    return (
        <WrapperSchedule>
            {
                isLoading ? (
                    <Spinner border="animation" />
                ) : (
                    servicesSelecteds.length || maintainDisplayServicesSelecteds ? (
                        professionalId || maintainDisplayServicesSelecteds ? (
                            (date && time) || maintainDisplayServicesSelecteds ? (
                                <ServicesSelected payments={payments} disableAuthOfClient={disableAuthOfClient} professionalId={professionalId} observation={observation} setMaintainDisplayServicesSelecteds={setMaintainDisplayServicesSelecteds} date={date} time={time} serviceSelected={serviceSelected} />
                            ) : <Times disableAuthOfClient={disableAuthOfClient} serviceSelected={serviceSelected} />
                        ) : <Professionals serviceSelected={serviceSelected} />
                    ) : (
                        <Row className="list-services">
                            {
                                services.map((service, index) => (
                                    <Col md={6} lg={ 4 }>
                                        <Card>
                                            <Card.Header>{ service.name }</Card.Header>
                                            <Card.Body>
                                                <p><b>Duração:</b> { utils.formatDuration(service.duration * 60000) }</p>
                                                {
                                                    service.description ? <p><b>Descrição:</b> { service.description }</p> : null
                                                }
                                                {
                                                    service.showPrice ? <p><b>Valor:</b> { utils.formatPrice(service.price) }</p> : false
                                                }
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button onClick={()=>dispatch({
                                                    type: "INCLUDE_SERVICE_CART",
                                                    payload: service.id
                                                })}>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                    <span>Agendar</span>
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    )
                )
            }
        </WrapperSchedule>
    );
}