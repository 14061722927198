import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const WrapperButtonSubmit = styled(Button)`
    font-size: 0.9375rem;
    padding: 10px 22px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #E9C46A;
    border: 0;

    &:hover,
    &:active {
        background-color: #E9C46A !important;
        opacity: 0.85;
    }
`;

export default WrapperButtonSubmit;