import React, { useState } from "react";
import WrapperLogin from "./style";
import logo from './../../images/logo-black.png';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Image
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { http } from '../../services';
import utils from '../../utils';

function FormLogin(){

    const dispatch = useDispatch();
    const { isLogged, user, type } = useSelector(state=>state.accountState);
    const location = useLocation();

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const authentication = ()=>{
        setBtnDisabled(true);

        const server = http.user;

        server.auth({email, password})
            .then(result=>{
                dispatch({
                    type: "LOGOUT"
                });
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        type: result.data.type,
                        user: result.data.user,
                        token: result.data.token,
                        refreshToken: result.data.refreshToken
                    }
                });
            })
            .catch(error=>{
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao fazer login',
                    message: error.response.data
                });
            })
            .finally(()=>{
                setBtnDisabled(false);
            });
    }

    return (
        <Form>
            {
                isLogged && type === "user" ? (
                    user.type_of_account === 'support' ? (
                        <Navigate replace to={ "/suporte/contatos" } />
                    ) : (
                        user.type_of_account === 'admin' ?
                        <Navigate replace to="/admin/agenda" /> :
                        (
                            user.type_of_account === 'operator' ?
                            <Navigate replace to="/operador" /> :
                            <Navigate replace to="/admin/agenda" />
                        )
                    )
                )
                : false
            }
            <a href={ /*config.client2*/"/" } >
                <Image draggable={false} src={logo} />
            </a>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                    type="email"
                    placeholder="E-mail"
                    onChange={e=>setEmail(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                    className="mb-2"
                    type="password"
                    placeholder="Senha"
                    onChange={e=>setPassword(e.target.value)}
                />
            </Form.Group>
            <Button
                className='mb-2'
                variant="primary"
                disabled={btnDisabled}
                onClick={()=>authentication()}>
                Entrar
            </Button>
        </Form>
    );
}

export default function Login(){
    return (
        <WrapperLogin>
            <Container>
                <Row>
                    <Col>
                        <FormLogin />
                    </Col>
                </Row>
            </Container>
        </WrapperLogin>
    );
}